import { FC } from "react";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import s from './UserInfo.module.css'
import { useLanguage } from "../../../features/localisation/useLanguage";

interface UserBalanceProps {
    cents: number
    currency: string
}

export const UserBalance: FC<UserBalanceProps> = ({cents, currency}) => {
    const { languagePack: { pack: {userInfo: lang} }} = useLanguage()

    return <div className={s.balanceContainer}>
        <div>
            <div className={s.balanceLabel}>{lang.balanceLabel}</div>
            <div className={s.balanceDisplay}>
                <div className={s.balanceDigits}>{convertCentsToCurrencyString(cents)}</div>
                <div className={s.balanceCurrency}>{currency}</div>
            </div>
        </div>
    </div>
}