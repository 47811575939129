import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { CreateUser } from "./CreateUser";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface CreateUserModalProps {
    close: () => void
}

export const CreateUserModal: FC<CreateUserModalProps> = ({close = () => {}}) => {
    const { languagePack: {pack: {createUser : lang}}} = useLanguage()
    const [ opened, setOpened ] = useState(true)
    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={ 'xs' }
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogTitle>{lang.title}</DialogTitle>
        <DialogContent>
            <CreateUser onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}