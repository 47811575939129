import { Bonus, BonusLoader, iUseBonus, ShowBonusesResponse } from "./bonusModels";
import { useCallback } from "react";
import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import axios, { AxiosError } from "axios";
import Config from "../../config";
import { convertDateToUnixSeconds } from "../common_funcs";
import { ApiResponse } from "../apiResponseModels";
import { useUser } from "../user/useUser";
import { setBonuses, setBonusLoader, updateBonus as updateBonusState, deleteBonus as deleteBonusState } from "./BonusSlice";

export const useBonus = (): iUseBonus => {
    const dispatch = useAppDispatch()
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const state = useAppSelector(state => state.bonus)
    const { token } = useUser()

    const createBonus = useCallback((
        promocode: string,
        condition_deposit: number,
        wager: number,
        days_valid: number,
        from_time?: Date,
        to_time?: Date,
        condition_additional?: string,
        bonus_amount?: number,
        bonus_percentage?: number,
        bonus_freespins?: number,
        bonus_max?: number,
        onSuccess = () => {
        }
    ) => {
        if (token) {
            dispatch(setBonusLoader({
                loader: BonusLoader.isBonusCreatingLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'CreateBonus')
            data.append('token', token)
            data.append('promocode', promocode)
            data.append('condition_deposit', condition_deposit.toString())
            data.append('wager', wager.toString())
            data.append('days_valid', days_valid.toString())

            if (from_time !== undefined) {
                data.append('fromtime', convertDateToUnixSeconds(from_time).toString())
            }
            if (to_time !== undefined) {
                data.append('totime', convertDateToUnixSeconds(to_time).toString())
            }
            if (condition_additional !== undefined) {
                data.append('condition_additional', condition_additional)
            }
            if (bonus_amount !== undefined) {
                data.append('bonus_amount', bonus_amount.toString())
            }
            if (bonus_percentage !== undefined) {
                data.append('bonus_percentage', bonus_percentage.toString())
            }
            if (bonus_freespins !== undefined) {
                data.append('bonus_freespins', bonus_freespins.toString())
            }
            if (bonus_max !== undefined) {
                data.append('bonus_max', bonus_max.toString())
            }

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setBonusLoader({
                        loader: BonusLoader.isBonusCreatingLoading,
                        isLoading: false
                    }))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const getBonuses = useCallback(() => {
        if (token) {
            dispatch(setBonusLoader({
                loader: BonusLoader.isBonusesLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowBonuses')
            data.append('token', token)

            // axios.get<ShowBonusesResponse>('https://admin.unisoft.codes/debug_requests/bonuses.json?v=2')
            axios.post<ShowBonusesResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, bonuses } = response.data
                    if (success && bonuses) {
                        bonuses.forEach(b => {
                            if (b.condition_totime_unix) {
                                b.condition_totime = new Date(b.condition_totime_unix * 1000)
                            }
                            if (b.condition_fromtime_unix) {
                                b.condition_fromtime = new Date(b.condition_fromtime_unix * 1000)
                            }
                        })
                        dispatch(setBonuses(bonuses))
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setBonuses([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setBonusLoader({
                        loader: BonusLoader.isBonusesLoading,
                        isLoading: false
                    }))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const updateBonus = useCallback((bonus: Bonus, onSuccess = () => {}) => {
        if (token) {
            dispatch(setBonusLoader({
                loader: BonusLoader.isBonusDeletingLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateBonus')
            data.append('token', token)
            data.append('id', bonus.id.toString())
            data.append('promocode', bonus.promo_code)
            data.append('condition_deposit', bonus.condition_deposit.toString())
            data.append('wager', bonus.wager.toString())
            data.append('days_valid', bonus.days_valid.toString())

            if (bonus.condition_fromtime !== undefined) {
                data.append('fromtime', convertDateToUnixSeconds(bonus.condition_fromtime).toString())
            }
            if (bonus.condition_totime !== undefined) {
                data.append('totime', convertDateToUnixSeconds(bonus.condition_totime).toString())
            }
            if (bonus.condition_additional !== undefined) {
                data.append('condition_additional', bonus.condition_additional)
            }
            if (bonus.bonus_amount !== undefined) {
                data.append('bonus_amount', bonus.bonus_amount.toString())
            }
            if (bonus.bonus_percentage !== undefined) {
                data.append('bonus_percentage', bonus.bonus_percentage.toString())
            }
            if (bonus.bonus_freespins !== undefined) {
                data.append('bonus_freespins', bonus.bonus_freespins.toString())
            }
            if (bonus.bonus_max !== undefined) {
                data.append('bonus_max', bonus.bonus_max.toString())
            }


            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(updateBonusState(bonus))
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setBonusLoader({
                        loader: BonusLoader.isBonusCreatingLoading,
                        isLoading: false
                    }))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const deleteBonus = useCallback((id: number, onSuccess = () => {}, onFinish = () => {}) => {
        if (token) {
            dispatch(setBonusLoader({
                loader: BonusLoader.isBonusDeletingLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'DeleteBonus')
            data.append('token', token)
            data.append('id', id.toString())


            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(deleteBonusState(id))
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setBonusLoader({
                        loader: BonusLoader.isBonusDeletingLoading,
                        isLoading: false
                    }))
                    onFinish()
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    return { ...state, createBonus, getBonuses, updateBonus, deleteBonus }
}