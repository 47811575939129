import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { DepositMoney } from "./DepositMoney";
import { User } from "../../../features/user/user";

interface DepositMoneyDialogProps {
    close: () => void
    childUser: User
}

export const DepositMoneyDialog: FC<DepositMoneyDialogProps> = ({close = () => {}, childUser}) => {
    const [ opened, setOpened ] = useState(true)
    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <DepositMoney onDone={pendingClose} childUser={childUser}/>
        </DialogContent>
    </Dialog>
}