import { FC, useEffect, useState } from "react";
import { User } from "../../../features/user/user";
import s from './WithdrawMoney.module.css'
import { MoneyInput } from "../../../components/commons/money_input/MoneyInput";
import { Button, CircularProgress } from "@mui/material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useSnackbar, VariantType } from "notistack";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../../features/common_funcs";
import { chooseName } from "../../../features/user/userFuncs";
import { ConfirmationDialog } from "../../../components/commons/ConfirmationDialog";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";

interface WithdrawMoneyProps {
    onDone: () => void
    childUser: User
}

export const WithdrawMoney: FC<WithdrawMoneyProps> = ({onDone = () => {}, childUser}) => {
    const { languagePack: {pack: {withdrawMoneyFromUser: lang}}} = useLanguage()
    const { isWithdrawFromUserLoading, withdrawMoneyFromUser } = useManageUsers()
    const [value, setValue] = useState<string>('');
    const [ hasError, setHasError ] = useState<boolean>(false)
    const [ confirmation, setConfirmation ] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const [ maxAmount, setMaxAmount ] = useState<string>('')

    useEffect(() => {
            const balance = convertCentsToCurrencyString(childUser.user_balance)
            if (maxAmount !== balance) {
                setMaxAmount(balance)
            }
    }, [maxAmount, childUser])

    const handleSendMoney = () => {
        withdrawMoneyFromUser(convertStringCurrencyToCents(value), childUser.user_id, () => {
            const money = `${value} ${childUser.user_currency}`
            const message = lang.moneySuccessfulWithdrawedMessage.replace('%username%', chooseName(childUser)).replace('%money%', money)
            sendSnackBar('success', message)
            onDone()
        })
    }

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    return <>
        <div className={s.container}>
            <div>
                <MoneyInput
                label={lang.withdrawLabel}
                value={value}
                maxAmount={maxAmount}
                onValueChanged={setValue}
                currency={childUser.user_currency}
                hasError={(has) => setHasError(has)}
            />
            </div>
            <Button classes={{ contained: s.button, disabled: s.buttonDisabled }}
                    disabled={hasError || value === ''}
                    endIcon={isWithdrawFromUserLoading ? <CircularProgress size={16}/> : <MonetizationOnOutlinedIcon/>}
                    variant={'contained'}
                    onClick={() => setConfirmation(true)}
            >{lang.withdrawBtn}</Button>
        </div>

        {confirmation && <ConfirmationDialog close={() => setConfirmation(false)} message={lang.confirmWithdrawMessage.replace('%username%', chooseName(childUser))} onConfirm={() => {
            handleSendMoney()
        }}/>}
    </>
}