import { FC, useState } from "react";
import { MoneyTransfer } from "../../../features/money_transfers/MoneyTransfersModels";
import { Dialog, DialogContent } from "@mui/material";
import { ApproveTransaction } from "./ApproveTransaction";

interface ApproveTransactionDialogProps {
    transaction: MoneyTransfer
    close: () => void
}

export const ApproveTransactionDialog: FC<ApproveTransactionDialogProps> = ({transaction, close = () => {}}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <ApproveTransaction transaction={transaction} onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}