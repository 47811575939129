import { FC, useEffect, useState } from "react";
import { User, UserRole } from "../../../features/user/user";
import { ContainedIconButton } from "../../../components/commons/controls/ContainedIconButton";
import { UserInfoTitle } from "./UserInfoTitle";
import { chooseName } from "../../../features/user/userFuncs";
import s from './UserInfo.module.css'
import { UserBalance } from "./UserBalance";
import { UserRegistration } from "./UserRegistration";
import { UserRoleChip } from "../../../components/manage_users/UserRoleChip";
import Grid from '@mui/material/Unstable_Grid2';
import { InfoWithLabel } from "../../../components/commons/info_with_label/InfoWithLabel";
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import CasinoIcon from '@mui/icons-material/Casino';
import { BanUser } from "./BanUser";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { Link } from "react-router-dom";
import { isUserUnderYourRole } from "../../../features/common_funcs/user_funcs";
import { useUser } from "../../../features/user/useUser";
import { DepositMoneyDialog } from "../deposit_money/DepositMoneyDialog";
import { WithdrawMoneyDialog } from "../withdraw_money/WithdrawMoneyDialog";


interface UserInfoProps {
    user: User
    expandMargin?: boolean
}

export const UserInfo: FC<UserInfoProps> = ({ user, expandMargin = false }) => {
    const firstLastName = chooseName(user)
    const { languagePack: { pack: lang } } = useLanguage()
    const { user: currentUser, userRole } = useUser()
    const [ isUserYourChild, setUserYourChild ] = useState<boolean>(false)
    const [ showDepositMoneyDialog, setShowDepositMoneyDialog ] = useState<boolean>(false)
    const [ showWithdrawMoneyDialog, setShowWithdrawMoneyDialog ] = useState<boolean>(false)
    
    useEffect(() => {
        if (currentUser) {
            const userIsYourChild = isUserUnderYourRole(currentUser, user)
            if (userIsYourChild !== isUserYourChild) {
                setUserYourChild(userIsYourChild)
            }
        }        
    }, [currentUser, isUserYourChild, user])

    return <><div>
        <UserInfoTitle expandOverMargin={expandMargin} username={user.user_name}
                       firstLastName={firstLastName === user.user_name ? null : firstLastName}/>
        <div className={s.twoSection}>
            <div className={s.flex1}>
                <div className={s.firstSection}>
                    <UserRegistration date={user.registration_time ?? '--.--.-- --:--:--'}/>
                    <div className={s.roleContainer}>
                        <div className={s.roleLabel}>{lang.userInfo.roleLabel}:</div>
                        <UserRoleChip userRole={user.user_role}/>
                    </div>
                </div>
            </div>
            <div className={s.flex1}>
                <UserBalance cents={user.user_balance} currency={user.user_currency ?? '--'}/>
            </div>

        </div>
        <Grid container spacing={2} padding={'16px'} rowGap={3}>
            <Grid xs={12} md={6}>
                <InfoWithLabel label={lang.userInfo.firstNameLabel}>{user.user_firstname}</InfoWithLabel>
            </Grid>
            <Grid xs={12} md={6}>
                <InfoWithLabel label={lang.userInfo.lastnameLabel}>{user.user_lastname}</InfoWithLabel>
            </Grid>
            <Grid xs={12} md={6}>
                <InfoWithLabel label={lang.userInfo.phoneLabel}>{user.user_phone}</InfoWithLabel>
            </Grid>
            <Grid xs={12} md={6}>
                <InfoWithLabel label={lang.userInfo.emailLabel}>{user.user_email}</InfoWithLabel>
            </Grid>
        </Grid>

        <Grid container spacing={2} padding={'16px'}>
            <Grid xs={12} md={6}>
                <div className={s.buttonBlock}>
                    <ContainedIconButton disabled={(!isUserYourChild && userRole !== UserRole.PLATFORM) || user.banned} onClick={() => {setShowDepositMoneyDialog(true)}} color="primary" aria-label="deposit money dialog">
                        <AddCardOutlinedIcon/>
                    </ContainedIconButton>
                    <div>{lang.userInfo.depositMoneyBtn}</div>
                </div>
            </Grid>
                <Grid xs={12} md={6}>
                    <div className={s.buttonBlock}>
                        <ContainedIconButton disabled={!isUserYourChild || user.user_role !== 'player'} onClick={() => {setShowWithdrawMoneyDialog(true)}} color="primary" aria-label="withdraw money">
                            <PriceCheckOutlinedIcon/>
                        </ContainedIconButton>
                        <div>{lang.userInfo.withdrawMoneyBtn}</div>
                    </div>
                </Grid>
            <Grid xs={12} md={6}>
                <Link className={s.buttonBlockLink} to={'/money_transfers?user_name=' + user.user_name}>
                    <div className={s.buttonBlock}>
                        <ContainedIconButton color="primary" aria-label="money transfers">
                            <RequestQuoteOutlinedIcon/>
                        </ContainedIconButton>
                        <div>{lang.userInfo.moneyTransfersBtn}</div>
                    </div>
                </Link>
            </Grid>
            <Grid xs={12} md={6}>
                <Link className={s.buttonBlockLink} to={'/game_history?user_name=' + user.user_name}>
                    <div className={s.buttonBlock}>
                        <ContainedIconButton color="primary" aria-label="agame history">
                            <CasinoIcon/>
                        </ContainedIconButton>
                        <div>{lang.userInfo.gamesHistoryBtn}</div>
                    </div>
                </Link>
            </Grid>
        </Grid>

        <div className={s.banUserBlock}>
            <BanUser user={user}/>
        </div>

    </div>
        {showDepositMoneyDialog && <DepositMoneyDialog close={() => setShowDepositMoneyDialog(false)} childUser={user}/>}

        {showWithdrawMoneyDialog && <WithdrawMoneyDialog close={() => setShowWithdrawMoneyDialog(false)} childUser={user}/>}
    </>
}