import { FC, useEffect, useState } from "react";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { useUser } from "../../features/user/useUser";
import { useFinancesAccess } from "../../features/finances/useFinancesAccess";
import { Box, Button, Card } from "@mui/material";
import { CoinPackList } from "../../components/finances/CoinPackList";
import { CoinsPackConstructorDialog } from "../../components/finances/CoinsPackConstructorDialog";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useAppDispatch } from "../../store";
import { clearFinances } from "../../features/finances/FinancesSlice";


export const FinancesPage: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { coinsList: lang } } } = useLanguage()
    const { token } = useUser()
    const { hasAccess } = useFinancesAccess()

    const [ showCoinPackCreator, setShowCoinPackCreator ] = useState<boolean>(false)
    
    useEffect(() => {
        return () => {
            dispatch(clearFinances())
        }
    }, [dispatch])

    return token ? <>
        {hasAccess && <Card style={{ margin: 10, padding: 10, height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'}>
                <Button
                    variant={'contained'}
                    onClick={() => setShowCoinPackCreator(true)}
                >
                    {lang.createCoinPackBTN}
                </Button>

                <CoinPackList height={'calc(100vh - 170px)'}/>
            </Box>
        </Card>}

        {showCoinPackCreator && <CoinsPackConstructorDialog close={() => setShowCoinPackCreator(false)}/>}
    </> : <MustBeLogged/>
}