import { FC } from "react";
import s from './UserInfo.module.css'
import clsx from "clsx";

interface UserInfoTitleProps {
    username: string
    firstLastName?: string|null
    expandOverMargin?: boolean
}

export const UserInfoTitle: FC<UserInfoTitleProps> = ({username, firstLastName = null, expandOverMargin = false}) => {
    return <div className={clsx(s.titleContainer, expandOverMargin && s.titleContainerNegativeMargin)}>
        <div className={s.firstLastName}>
            {firstLastName ?? username}
        </div>
        {firstLastName && <div className={s.username}>
            {username}
            </div>}
    </div>
}