import React, { FC, useEffect, useState } from "react";
import { Alert, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useMoneyTransfers } from "../../../features/money_transfers/useMoneyTransfers";
import { clearMoneyTransfers } from "../../../features/money_transfers/moneyTransfersSlice";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import {
    MoneyTransfer,
    MoneyTransferFilter,
    MoneyTransfersSort
} from "../../../features/money_transfers/MoneyTransfersModels";
import { useAppDispatch } from "../../../store";
import { ApproveTransactionDialog } from "../../payment_systems/approve_transaction/ApproveTransactionDialog";
import { useMoneyTransfersWatcher } from "../../../features/money_transfers/useMoneyTransfersWatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { SortedCellMoneyTransfers } from "./SortedCellMoneyTransfers";

interface MoneyTransfersTableProps {
    height: string
    independentQueryParams?: boolean // if false, component read query parameters from browser
}

export const MoneyTransfersTable: FC<MoneyTransfersTableProps> = ({height, independentQueryParams = false}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useMoneyTransfersWatcher(independentQueryParams)

    const { languagePack: {pack: {moneyTransfersTable: lang}}} = useLanguage()
    const { moneyTransfers, isMoneyTransfersLoading, filter, filter: {orderBy}, getMoneyTransfers, currentPage, pageSize } = useMoneyTransfers()
    const [ openApproveTransaction, setApproveTransaction ] = useState<MoneyTransfer|null>(null)

    useEffect(() => {
        return () => {
            dispatch(clearMoneyTransfers())
        }
    }, [ dispatch ])

    const handleClickSorting = (sort: MoneyTransfersSort) => {
        if (!independentQueryParams) {
            searchParams.set('orderBy', sort);

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        } else {
            const newFilter: MoneyTransferFilter = {
                ...filter,
                orderBy: sort
            }
            getMoneyTransfers(currentPage, pageSize, newFilter)
        }
    }

    return <>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="manage users table">
                <TableHead>
                    <TableRow >
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.Username}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.usernameHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.Amount}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.amountTableHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.Method}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.methodTableHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.Status}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.statusTableHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.Type}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.depositWithdrawTableHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                        <TableCell>
                            <SortedCellMoneyTransfers
                                orderName={MoneyTransfersSort.DateTime}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.dateTimeTableHeader}
                            </SortedCellMoneyTransfers>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {moneyTransfers && moneyTransfers.map((transfer, index) => (
                        <TableRow
                            key={transfer.transfer_id + '-transfer-' + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {transfer.user_name}
                            </TableCell>
                            <TableCell>{convertCentsToCurrencyString(transfer.transfer_amount)}</TableCell>
                            <TableCell>{transfer.method_name}</TableCell>
                            <TableCell style={{
                                color: getColor(transfer.transfer_status),
                                cursor: transfer.transfer_status.toLowerCase() === 'pending' ? 'pointer' : 'initial'
                            }} onClick={() => {
                                if (transfer.transfer_status.toLowerCase() === 'pending') {
                                    setApproveTransaction(transfer)
                                }
                            }}>
                                {transfer.transfer_status}
                            </TableCell>
                            <TableCell>{transfer.transfer_type}</TableCell>
                            <TableCell>{transfer.respond_time ?? transfer.request_time}</TableCell>
                        </TableRow>
                    ))}
                    { moneyTransfers === null && isMoneyTransfersLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        { moneyTransfers?.length === 0 && <Alert variant="filled" severity="warning">
            {lang.moneyTransfersNotFoundMessage}
        </Alert>}

        { openApproveTransaction && <ApproveTransactionDialog transaction={openApproveTransaction} close={() => setApproveTransaction(null)}/>}
        </>
}

const getColor = (status: string): string => {
    switch (status) {
        case 'pending':
            return '#fd8700';
        case 'approved':
            return '#2ebb00';
        case 'declined':
        case 'canceled':
            return '#e10029';
        default:
            return 'inherite';
    }
}