import { ChangeEvent, FC, useCallback, useState } from "react";
import {
    Box,
    Button, CircularProgress,
    FilledInput,
    FormControl, FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel, Skeleton
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { moreThanOneDot, normalizeNumbersInput } from "../../../components/commons/money_input/MoneyInput";
import { useSnackbar, VariantType } from "notistack";
import { usePaymentSystems } from "../../../features/payment_system/usePaymentSystems";
import { useLanguage } from "../../../features/localisation/useLanguage";

// TODO localize component

enum ValueError {
    MoreThanOneDot
}

export const DepositRateEditor: FC = () => {
    const { languagePack: {pack: {paymentSystems: lang, common: langCommon}}} = useLanguage()
    const { enqueueSnackbar } = useSnackbar();
    const [ localValue, setLocalValue ] = useState<string|null>(null)
    const [ error, setError ] = useState<ValueError|null>(null)
    const { isSetDepositRateLoading, setDepositRate, depositRate } = usePaymentSystems()

    const getError = useCallback((value: string) : ValueError|null => {
        if (moreThanOneDot(value)) {
            return ValueError.MoreThanOneDot
        }
        return null
    }, [])

    const inputHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const newValue = normalizeNumbersInput((localValue ?? (depositRate ?? 1.0)).toString(), event.target.value)
            if (newValue !== localValue) {
                setLocalValue(newValue)
                const err = getError(newValue)
                if (err !== error) {
                    setError(err)
                }
            }
    }

    const getHelperText = useCallback((): string | undefined => {
        let message: string|undefined
        switch (error) {
            case ValueError.MoreThanOneDot:
                message = "You made an extra point."
                break
            default:
                message = undefined
        }
        return message
    }, [error])

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const handleClickSave = () => {
        if (localValue !== null) {
            setDepositRate(parseFloat(localValue), () => {
                setLocalValue(null)
                sendSnackBar('success', lang.depositRateSavedMessage)
            })
        }
    }

    return <Box width={'100%'} display={'flex'} columnGap={'10px'} alignItems={'center'}>
        {depositRate !== null ? <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
            <InputLabel htmlFor="deposit-rate-editor">{lang.depositRateLabel}</InputLabel>
            <FilledInput
                error={error !== null}
                fullWidth
                value={(localValue ?? (depositRate ?? 1.0)).toString()}
                onChange={inputHandler}
                id="deposit-rate-editor"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                setLocalValue(null)
                                setError(null)
                            }}
                            edge="end"
                        >
                            {localValue !== null && <HighlightOffIcon/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error={error !== null} id="deposit-rate-editor">{getHelperText()}</FormHelperText>
        </FormControl> : <Skeleton width={'100%'} height={'50px'} animation={'wave'}/>}
        {localValue !== null && <Button onClick={handleClickSave} disabled={error !== null} variant={'contained'}>{isSetDepositRateLoading ? <CircularProgress/> : langCommon.saveBTN}</Button>}
    </Box>
}