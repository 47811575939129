import React, { FC } from "react";
import { Alert, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { UserRoleChip } from "../../../components/manage_users/UserRoleChip";
import { UserInfoModal } from "../user_info/UserInfoModal";
import s from './ManageUsersTable.module.css'
import clsx from "clsx";
import { useUsersWatcher } from "../../../features/manage_users/useUsersWatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { ManageUsersFilter, ManageUsersSort } from "../../../features/manage_users/manageUsersModels";
import { SortedCellManageUsers } from "./SortedCellManageUsers";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface ManageUsersTableProps {
    height: string
    independentQueryParams?: boolean // if false, component read query parameters from browser
}

export const ManageUsersTable: FC<ManageUsersTableProps> = ({height, independentQueryParams = false}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useUsersWatcher(independentQueryParams)

    const { languagePack: {pack: {manageUsers: lang}}} = useLanguage()
    const { usersList, isUsersListLoading, showUser, setShowUser, filter: {orderBy}, filter, currentPage, pageSize, loadManageUsersList } = useManageUsers()


    const handleClickSorting = (sort: ManageUsersSort) => {
        if (!independentQueryParams) {
            searchParams.set('orderBy', sort);

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        } else {
            const newFilter: ManageUsersFilter = {
                ...filter,
                orderBy: sort
            }
            loadManageUsersList(currentPage, pageSize, newFilter)
        }
    }

    return <><TableContainer sx={{ maxHeight: height }}>
        <Table stickyHeader aria-label="manage users table">
            <TableHead>
                <TableRow >
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Active}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.activeTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Username}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.usernameHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Firstname}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.firstNameTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Lastname}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.lastnameTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Balance}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.balanceTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Currency}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.currencyTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Role}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.roleTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Email}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.emailTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                    <TableCell>
                        <SortedCellManageUsers
                            orderName={ManageUsersSort.Phone}
                            currentOrderBy={orderBy}
                            onClick={handleClickSorting}>
                            {lang.phoneTableHeader}
                        </SortedCellManageUsers>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { usersList !== null && usersList.map(user => <TableRow
                    className={s.userRow}
                    onClick={() => setShowUser(user)}
                    key={`user_id-${user.user_id}`}>
                    <TableCell><div className={clsx(s.banDot, user.banned !== undefined && (user.banned ? s.banned : s.active))}></div></TableCell>
                    <TableCell>{user.user_name}</TableCell>
                    <TableCell>{user.user_firstname}</TableCell>
                    <TableCell>{user.user_lastname}</TableCell>
                    <TableCell>{convertCentsToCurrencyString(user.user_balance)}</TableCell>
                    <TableCell>{user.user_currency}</TableCell>
                    <TableCell><UserRoleChip userRole={user.user_role}/></TableCell>
                    <TableCell>{user.user_email}</TableCell>
                    <TableCell>{user.user_phone}</TableCell>
                </TableRow>)}
                { usersList === null && isUsersListLoading && <TableRow>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                    <TableCell><Skeleton animation="wave"/></TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    </TableContainer>
        { usersList?.length === 0 && <Alert variant="filled" severity="warning">
            { lang.usersNotFoundMessage }
        </Alert>}
        {showUser && <UserInfoModal close={() => {
            setShowUser(null)
        }} user={showUser}/>}
    </>
}