import React, { FC } from "react";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { Card, Stack } from "@mui/material";
import { RoyaltyStatisticsTable } from "./royalty_statistics_table/RoyaltyStatisticsTable";
import { TimeFilterPanel } from "../../components/commons/time_filter/TimeFilterPanel";
import { useRoyalty } from "../../features/royalty/useRoyalty";
import { useRoyaltyStatisticsAccess } from "../../features/royalty/useRoyaltyStatisticsAccess";



export const RoyaltyStatisticsPage: FC = () => {
    const { token } = useUser()
    const { hasAccess } = useRoyaltyStatisticsAccess()
    const { filter } = useRoyalty()

    return token ? <>
        {hasAccess && <Card style={{ margin: 10, padding: 10 }}>
            <Stack direction={'column'}>
                <TimeFilterPanel fromTime={filter.fromTime} toTime={filter.toTime} pullParamsToAddressBar/>
                <RoyaltyStatisticsTable height={'calc(100vh - 166px)'}/>
            </Stack>
        </Card>}
    </> : <MustBeLogged/>
}