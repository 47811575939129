import { Divider, Typography } from "@mui/material";
import { FC } from "react";

interface SideMenuDividerProps {
    children?: string
}

export const SideMenuDivider: FC<SideMenuDividerProps> = ({children}) => <Divider textAlign="left">{children && <Typography
    fontFamily={'Poppins'} variant={'caption'} color={'var(--dark-color)'}>
    {children}
</Typography>}</Divider>