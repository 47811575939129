import { FC, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { AddPaymentForm } from "../add_payment/AddPaymentForm";
import {
    PaymentAccount,
    PaymentAccountField,
    PaymentMethodTemplate
} from "../../../features/payment_system/PaymentSystemModels";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { usePaymentSystems } from "../../../features/payment_system/usePaymentSystems";
import { useSnackbar, VariantType } from "notistack";

interface EditPaymentProps {
    onDone?: () => void
    paymentAccount: PaymentAccount
}

export const EditPayment: FC<EditPaymentProps> = ({onDone = () => {}, paymentAccount}) => {
    const { languagePack: {pack: {paymentSystems: lang, common: langCommon}}} = useLanguage()
    const [ paymentTemplate, setPaymentTemplate ] = useState<PaymentMethodTemplate|null>(null)
    const { isPaymentAccountAddLoading, editUserPaymentAccount } = usePaymentSystems()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (paymentTemplate === null) {
            const template: PaymentMethodTemplate = {
                account_structure: {
                    fields: paymentAccount.params.fields,
                    payment_method: "" },
                currencies: "",
                id: 0,
                method_name: "",
                pic_url: "",
                proof: { helper: "", label: "", placeholder: "" }
            }
            setPaymentTemplate(template)
        }
    }, [ paymentAccount.params.fields, paymentTemplate ])

    const updateFieldsHandler = (fields: PaymentAccountField[]) => {
        if (paymentTemplate) {
            const method: PaymentMethodTemplate = {
                ...paymentTemplate,
                account_structure: {
                    ...paymentTemplate.account_structure,
                    fields: fields
                }
            }
            setPaymentTemplate(method)
        }
    }

    const editPaymentMethodHandler = () => {
        if (paymentTemplate) {
            editUserPaymentAccount(paymentAccount.method_name, paymentAccount.method_id, paymentTemplate.account_structure.fields, () => {
                sendSnackBar('success', lang.paymentAccountSuccessfullyUpdated)
                onDone()
            })
        }
    }

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };
    
    return <Stack rowGap={'20px'}>
        {paymentTemplate && <AddPaymentForm paymentMethod={paymentTemplate} onFieldsChanged={updateFieldsHandler}/>}

        <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
                disabled={!Boolean(paymentTemplate) || isPaymentAccountAddLoading}
                variant={'contained'}
                onClick={editPaymentMethodHandler}
                endIcon={isPaymentAccountAddLoading && <CircularProgress size={16} sx={{color: "inherit"}}/>}
            >
                {langCommon.editBTN}
            </Button>
        </Box>
    </Stack>
}