import { ChangeEvent, FC } from "react";
import { PaymentAccountField, PaymentMethodTemplate } from "../../../features/payment_system/PaymentSystemModels";
import { Stack, TextField } from "@mui/material";

interface AddPaymentFormProps {
    paymentMethod: PaymentMethodTemplate
    onFieldsChanged: (fields: PaymentAccountField[]) => void
}

export const AddPaymentForm: FC<AddPaymentFormProps> = ({paymentMethod, onFieldsChanged}) => {
    const fields = paymentMethod.account_structure.fields

    const fieldChangeHandler = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const updatedFields: PaymentAccountField[] = [
            ...fields.slice(0, index),
            {
                ...fields[index],
                value: event.target.value
            },
            ...fields.slice(index + 1)
        ]

        onFieldsChanged(updatedFields)
    }

    return <Stack rowGap={'10px'}>
        {fields.map((field, index) => <TextField
            label={field.label}
            value={field.value}
            helperText={field.helper}
            placeholder={field.placeholder}
            key={`text-field-payment-${field.label}-${index}`}
            onChange={fieldChangeHandler(index)}
        />)}
    </Stack>
}