import { iUseMoneyTransfers, MoneyTransferFilter, ShowMoneyTransfersResponse } from "./MoneyTransfersModels";
import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import Config from "../../config";
import {
    setCurrentMoneyTransfersPage, setMoneyTransfers, setMoneyTransfersFilter,
    setMoneyTransfersLoading,
    setMoneyTransfersPageSize, setTotalMoneyTransfersRecords
} from "./moneyTransfersSlice";
import { convertUnixToCompactLocaleDate } from "../common_funcs";

export const useMoneyTransfers = (): iUseMoneyTransfers => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()

    const state = useAppSelector(state => state.moneyTransfers)

    const getMoneyTransfers = useCallback((page: number, itemsPerPage: number = 30, filter: MoneyTransferFilter | null = null) => {
        let filterForSending = state.filter;
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime ||
                filter.fromTime !== state.filter.fromTime ||
                filter.username !== state.filter.username ||
                filter.orderBy !== state.filter.orderBy
            ) {
                dispatch(setMoneyTransfersFilter(filter))
                filterForSending = filter
            }
        }
        let itemsPerPageToSend = state.pageSize
        if (itemsPerPage !== state.pageSize) {
            dispatch(setMoneyTransfersPageSize(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = state.currentPage
        if (page !== state.currentPage) {
            dispatch(setCurrentMoneyTransfersPage(page))
            pageToSend = page
        }
        if (token) {
            dispatch(setMoneyTransfersLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowMoneyTransfers')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }
            if (filterForSending.orderBy !== null) {
                data.append('orderby', filterForSending.orderBy)
            }

            axios.post<ShowMoneyTransfersResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: moneyTransfersList, total_transfers } = response.data
                    if (success) {
                        if (moneyTransfersList) {
                            moneyTransfersList.forEach(transfer => {
                                transfer.request_time = convertUnixToCompactLocaleDate(transfer.request_time_unix)
                                transfer.respond_time = transfer.respond_time_unix !== null ? convertUnixToCompactLocaleDate(transfer.respond_time_unix) : null
                            })
                            dispatch(setMoneyTransfers(moneyTransfersList))
                        }
                    }
                    if (total_transfers) {
                        dispatch(setTotalMoneyTransfersRecords(total_transfers))
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setMoneyTransfers([]))
                            if (total_transfers && total_transfers > 0) {
                                setLocalizedError(error)
                            }
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setMoneyTransfers([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setMoneyTransfersLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, state.currentPage, state.filter, state.pageSize, token])


    return {
        ...state,
        getMoneyTransfers
    }
}