import React, { FC, useEffect, useState } from "react";
import {
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { useFinances } from "../../features/finances/useFinances";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { MoneyPack } from "../../features/finances/FinancesModels";
import { CoinsPackConstructorDialog } from "./CoinsPackConstructorDialog";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ConfirmationDialog } from "../commons/ConfirmationDialog";
import { useLanguage } from "../../features/localisation/useLanguage";


interface CoinPackListProps {
    height: string
}

export const CoinPackList: FC<CoinPackListProps> = ({height}) => {
    const { languagePack: { pack: { coinsList: lang } } } = useLanguage()
    const { coinPacks, isCoinPacksLoading, getCoinPacks, deleteCoinPack, isDeletingCoinPackLoading } = useFinances()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const [coinsEditing, setCoinsEditing] = useState<MoneyPack | null>(null)
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<number | null>(null)

    useEffect(() => {
        if (initLoad && coinPacks === null && !isCoinPacksLoading) {
            getCoinPacks()
            setInitLoad(false)
        }
    }, [coinPacks, getCoinPacks, initLoad, isCoinPacksLoading])

    const handleIconButtonClick = (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setShowConfirmDelete(true)
        setDeleteId(id)
    }

    return <>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="coin pack table">
                <TableHead>
                    <TableRow>
                        <TableCell>{lang.idHeader}</TableCell>
                        <TableCell>{lang.coinsHeader}</TableCell>
                        <TableCell>{lang.priceHeader}</TableCell>
                        <TableCell>{lang.freeCoinsHeader}</TableCell>
                        <TableCell width={'100px'}>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coinPacks && coinPacks.map((coinPack) => (
                        <TableRow
                            onClick={() => !isDeletingCoinPackLoading && setCoinsEditing(coinPack)}
                            key={coinPack.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className={'cursor-pointer'}
                        >
                            <TableCell>{coinPack.id}</TableCell>
                            <TableCell>{convertCentsToCurrencyString(coinPack.coins)}</TableCell>
                            <TableCell>{convertCentsToCurrencyString(coinPack.price)}</TableCell>
                            <TableCell>{convertCentsToCurrencyString(coinPack.freecoins)}</TableCell>
                            <TableCell>
                                 <IconButton disabled={coinPack.id === deleteId && isDeletingCoinPackLoading} onClick={handleIconButtonClick(coinPack.id)}>
                                     {coinPack.id === deleteId ? <CircularProgress color={'error'} size={'20px'}/> : <DeleteForeverOutlinedIcon/>}
                                </IconButton>
                                </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        {coinsEditing && <CoinsPackConstructorDialog close={() => setCoinsEditing(null)} coinPackEditing={coinsEditing}/>}

        {showConfirmDelete && <ConfirmationDialog onConfirm={() => {
            setShowConfirmDelete(false)
            if (deleteId) {
                deleteCoinPack(deleteId, undefined, () => setDeleteId(null))
            }
        }} close={() => {
            setShowConfirmDelete(false)
            setDeleteId(null)
        }} message={lang.deleteCoinsPackConfirmationMessage}/>}
    </>
}