import {
    AddPaymentAccountsResponse,
    GetPaymentMethodsResponse,
    iUsePaymentSystems,
    PaymentAccountField, Proof, SetDepositRateResponse,
    ShowPaymentAccountsResponse
} from "./PaymentSystemModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import { useLanguage } from "../localisation/useLanguage";
import {
    addUserPaymentAccountToList,
    setApprovePaymentLoading,
    setApprovePayoutLoading,
    setPaymentAccountAddLoading,
    setPaymentAccountEditLoading,
    setPaymentAccountsLoading,
    setPaymentMethodsLoading,
    setSetDepositRateLoading,
    setUserPaymentAccounts,
    setUserPaymentMethods,
    updateUserPaymentAccount,
    setDepositRate as setStateDepositRate,
    setDepositPaymentAccountsLoading, setDepositPaymentAccounts, setParentDepositRate, setPaymentRequestLoading
} from "./paymentSystemsSlice";
import axios from "axios";
import Config from "../../config";
import { ApiResponse } from "../apiResponseModels";
import { updateMoneyTransferStatusByID } from "../money_transfers/moneyTransfersSlice";

export const usePaymentSystems = (): iUsePaymentSystems => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()

    const paymentSystemsState = useAppSelector(state => state.paymentSystems)

    const getUserPaymentAccounts = useCallback(() => {
        if (token) {
            dispatch(setPaymentAccountsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowMyPaymentAccounts')
            data.append('token', token)

            axios.post<ShowPaymentAccountsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: userPaymentAccounts, deposit_rate } = response.data
                    if (success) {
                        if (userPaymentAccounts) {
                            dispatch(setUserPaymentAccounts(userPaymentAccounts))
                        }
                    }
                    if (deposit_rate !== undefined) {
                        dispatch(setStateDepositRate(deposit_rate))
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUserPaymentAccounts([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPaymentAccountsLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const getPaymentMethods = useCallback(() => {
        if (token) {
            dispatch(setPaymentMethodsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetPaymentMethods')
            data.append('token', token)

            axios.post<GetPaymentMethodsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, payments } = response.data
                    if (success) {
                        if (payments) {
                            dispatch(setUserPaymentMethods(payments))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUserPaymentMethods([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPaymentMethodsLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const addUserPaymentAccount = useCallback((paymentMethodName: string, paymentMethodID: number, paymentAccountFields: PaymentAccountField[], onSuccess = () => {}) => {
        if (token) {
            dispatch(setPaymentAccountAddLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'AddPaymentAccount')
            data.append('token', token)

            const params = JSON.stringify({
                payment_method: paymentMethodName,
                fields: paymentAccountFields
            }, null, 0)

            data.append('method_id', paymentMethodID.toString())
            data.append('params', params)

            axios.post<AddPaymentAccountsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: paymentAccount } = response.data
                    if (success) {
                        if (paymentAccount) {
                            dispatch(addUserPaymentAccountToList(paymentAccount))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPaymentAccountAddLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const editUserPaymentAccount = useCallback((paymentMethodName: string, paymentMethodID: number, paymentAccountFields: PaymentAccountField[], onSuccess = () => {
    }) => {
        if (token) {
            dispatch(setPaymentAccountEditLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdatePaymentAccount')
            data.append('token', token)

            const params = JSON.stringify({
                payment_method: paymentMethodName,
                fields: paymentAccountFields
            }, null, 0)

            data.append('method_id', paymentMethodID.toString())
            data.append('params', params)

            axios.post<AddPaymentAccountsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: paymentAccount } = response.data
                    if (success) {
                        if (paymentAccount) {
                            dispatch(updateUserPaymentAccount(paymentAccount))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPaymentAccountEditLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const approvePayout = useCallback((transferID: number, approve: boolean, onSuccess = () => {}) => {
        if (token) {
            dispatch(setApprovePayoutLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ApprovePayout')
            data.append('token', token)

            data.append('transfer_id', transferID.toString())
            data.append('approve', approve.toString())

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success) {
                        if (data) {
                            dispatch(updateMoneyTransferStatusByID({transferID, transfer_status: data.transfer_status}))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setApprovePayoutLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const approvePayment = useCallback((transferID: number, approve: boolean, onSuccess = () => {}) => {
        if (token) {
            dispatch(setApprovePaymentLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ApprovePayment')
            data.append('token', token)

            data.append('transfer_id', transferID.toString())
            data.append('approve', approve.toString())

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success) {
                        if (data) {
                            dispatch(updateMoneyTransferStatusByID({transferID, transfer_status: data.transfer_status}))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setApprovePaymentLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const setDepositRate = useCallback((newDepositRate: number, onSuccess = () => {}) => {
        if (token) {
            dispatch(setSetDepositRateLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'SetDepositRate')
            data.append('token', token)

            data.append('rate', newDepositRate.toString())

            axios.post<SetDepositRateResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success) {
                        if (data) {
                            dispatch(setStateDepositRate(data.deposit_rate))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setSetDepositRateLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])


    const getDepositAccounts = useCallback(() => {
        if (token) {
            dispatch(setDepositPaymentAccountsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowDepositAccounts')
            data.append('token', token)

            axios.post<ShowPaymentAccountsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: parentPaymentAccounts, deposit_rate } = response.data
                    if (success) {
                        if (parentPaymentAccounts) {
                            dispatch(setDepositPaymentAccounts(parentPaymentAccounts))
                        }
                    }
                    if (deposit_rate !== undefined) {
                        dispatch(setParentDepositRate(deposit_rate))
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setDepositPaymentAccounts([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setDepositPaymentAccountsLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const requestPayment = useCallback((method_id: number, amount: number, proof: Proof, onSuccess = () => {}) => {
        if (token) {
            const params = JSON.stringify(proof, null, 0)

            dispatch(setPaymentRequestLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'RequestPayment')
            data.append('token', token)

            data.append('method_id', method_id.toString())
            data.append('amount', amount.toString())
            data.append('params', params)

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .finally(() => {
                    dispatch(setPaymentRequestLoading(false))
                })
        }
    }, [dispatch, setLocalizedError, token])

    return {
        ...paymentSystemsState,
        getUserPaymentAccounts,
        getPaymentMethods,
        addUserPaymentAccount,
        editUserPaymentAccount,
        approvePayout,
        approvePayment,
        setDepositRate,
        getDepositAccounts,
        requestPayment
    }
}