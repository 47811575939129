import { FC, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useLanguage } from "../../../features/localisation/useLanguage";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation, useNavigate } from "react-router-dom";
import { ContainedIconButton } from "../controls/ContainedIconButton";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

interface UsernameFilterProps {
    username: string|null
    onUsernameChanged?: (username: string|null) => void
    pullParamsToAddressBar?: boolean
}

export const UsernameFilter: FC<UsernameFilterProps> = ({username, onUsernameChanged = ()=>{}, pullParamsToAddressBar = false}) => {
    const {languagePack: {pack: {usernameFilter: lang}}} = useLanguage()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [ value, setValue ] = useState<string>('')

    const [ editMode, setEditMode ] = useState<boolean>(false)

    const changeAddressBarParams = (username: string|null) => {
        if(pullParamsToAddressBar) {
            if (username !== null && username.trim() !== '') {
                searchParams.set('username', username);
            } else searchParams.delete('username')

            searchParams.delete('page')

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        }
    }

    const handleSetUsername = (username: string|null) => {
        onUsernameChanged(username)
        changeAddressBarParams(username)
    }

    return <Box display={'flex'} columnGap={'4px'} alignItems={'center'} padding={'8px'}>
        {!editMode ? <AddUsernameButton holdData={Boolean(username)} label={username ?? lang.usernameFilterLabel} onClick={() => {
            setValue(username ?? '')
            setEditMode(true)
        }}/> : <>
            <TextField
                size={'small'}
                sx={{width: '150px'}}
                placeholder={lang.usernameFilterLabel}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
            <ContainedIconButton color="secondary" onClick={() => setEditMode(false)}>
                <BlockIcon fontSize={'small'}/>
            </ContainedIconButton>
            <ContainedIconButton color="primary" onClick={() => {
                setEditMode(false)
                handleSetUsername(value)
            }}>
                <CheckIcon fontSize={'small'}/>
            </ContainedIconButton>
        </>}

        {username !== null && !editMode && <IconButton color="warning" onClick={() => handleSetUsername(null)}>
            <HighlightOffIcon fontSize={'small'}/>
        </IconButton>}
    </Box>
}


interface AddUsernameButtonProps {
    label: string
    onClick?: () => void
    holdData: boolean
}

const AddUsernameButton:FC<AddUsernameButtonProps> = ({label, holdData, onClick = () => {}}) => {
    return <Button color={holdData ? 'secondary' : 'primary'} size={'small'} variant={'outlined'} onClick={onClick}>
        <Box display={'flex'} alignItems={'center'} columnGap={'4px'}>
            {label}
            <PersonSearchIcon/>
        </Box>
    </Button>
}