import { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { RoyaltyStatisticsWidget } from "../royalty_statistics/widget/RoyaltyStatisticsWidget";

export const DashboardPage: FC = () => {
    return <Grid container spacing={2}>
        <Grid xs={12}>
            <RoyaltyStatisticsWidget/>
        </Grid>
    </Grid>
}