import { FinancesLoader, FinancesState, MoneyPack } from "./FinancesModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";

const initialState: FinancesState = {
    coinPacks: null,
    isCoinPacksLoading: false,
    isCreatingCoinPackLoading: false,
    isDeletingCoinPackLoading: false,
    isUpdatingCoinPackLoading: false
}

export const FinancesSlice = createSlice({
    name: 'finances',
    initialState,
    reducers: {
        clearFinances: (state) => {
            state.coinPacks = null
            state.isCoinPacksLoading = false
            state.isCreatingCoinPackLoading = false
            state.isDeletingCoinPackLoading = false
            state.isUpdatingCoinPackLoading = false
        },
        setCoinPacks: (state, action: PayloadAction<MoneyPack[]>) => {
            state.coinPacks = action.payload
        },
        setFinancesLoader: (state, action: PayloadAction<LoaderAction<FinancesLoader>>) => {
            switch (action.payload.loader) {
                case FinancesLoader.isCoinPacksLoading:
                    state.isCoinPacksLoading = action.payload.isLoading
                    break
                case FinancesLoader.isCreatingCoinPackLoading:
                    state.isCreatingCoinPackLoading = action.payload.isLoading
                    break
                case FinancesLoader.isDeletingCoinPackLoading:
                    state.isDeletingCoinPackLoading = action.payload.isLoading
                    break
                case FinancesLoader.isUpdatingCoinPackLoading:
                    state.isUpdatingCoinPackLoading = action.payload.isLoading
                    break
            }
        },
        updateCoinPack: (state, action: PayloadAction<MoneyPack>) => {
            if (state.coinPacks) {
                state.coinPacks = state.coinPacks.map(coinPack => {
                    if (coinPack.id === action.payload.id) {
                        return action.payload
                    }
                    return coinPack
                })
            }
        },
        addCoinPackToEndOfList: (state, action: PayloadAction<MoneyPack>) => {
            if (state.coinPacks) {
                state.coinPacks = [
                    ...state.coinPacks,
                    action.payload
                ]
            }
        },
        removeCoinPackFromList: (state, action: PayloadAction<number>) => {
            if (state.coinPacks) {
                state.coinPacks = state.coinPacks.filter(coinPack => coinPack.id !== action.payload)
            }
        }
    }
})

export const { clearFinances, setCoinPacks, setFinancesLoader, updateCoinPack, addCoinPackToEndOfList, removeCoinPackFromList } = FinancesSlice.actions