import { useLocation } from "react-router-dom";
import { useRoyalty } from "./useRoyalty";
import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";
import { RoyaltyFilter, stringToRoyaltySort } from "./RoyaltyModels";
import { useRoyaltyStatisticsAccess } from "./useRoyaltyStatisticsAccess";

export const useRoyaltyStatisticsWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { hasAccess } = useRoyaltyStatisticsAccess()
    const { royaltyStatistics, getRoyaltyStatistics, isRoyaltyStatisticsLoading, filter } = useRoyalty()

    useEffect(() => {
        if (!isRoyaltyStatisticsLoading && !disabled && hasAccess) {
            const searchParams = new URLSearchParams(location.search)
            
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qOrderBy = searchParams.get('orderBy')

            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const royaltyNotLoadedYet = royaltyStatistics === null
            
            if (fromTimeHasChanges || toTimeHasChanges || royaltyNotLoadedYet || orderByHasChanges) {
                console.group('Royalty statistics changes')
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                console.log('orderByHasChanges', orderByHasChanges)
                console.log('royaltyNotLoadedYet', royaltyNotLoadedYet)
                console.groupEnd()

                const filterNew: RoyaltyFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    orderBy: qOrderBy !== null ? stringToRoyaltySort(qOrderBy) : null
                }
                
                getRoyaltyStatistics(filterNew)
            }
        }
    }, [disabled, filter, getRoyaltyStatistics, hasAccess, isRoyaltyStatisticsLoading, location.search, royaltyStatistics])
}