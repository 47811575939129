import { FC, useState } from "react";
import { PaymentAccount } from "../../../features/payment_system/PaymentSystemModels";
import { Dialog, DialogContent } from "@mui/material";
import { EditPayment } from "./EditPayment";

interface EditPaymentDialogProps {
    close: () => void
    paymentAccount: PaymentAccount
}

export const EditPaymentDialog: FC<EditPaymentDialogProps> = ({close = () => {}, paymentAccount}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <EditPayment onDone={pendingClose} paymentAccount={paymentAccount}/>
        </DialogContent>
    </Dialog>
}