import { FC, useEffect } from "react";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import { Box, CircularProgress } from "@mui/material";
import CurrencySwitcher from "../../manage_users/CurrencySwitcher";
import { useUser } from "../../../features/user/useUser";
import { useAppDispatch } from "../../../store";
import { setDisplayCurrency, setExchangeRate } from "../../../features/user/userSlice";

export const GlobalCurrencySwitcher: FC = () => {
    const dispatch = useAppDispatch()
    const { currencies, isCurrenciesLoading, loadCurrencies } = useManageUsers()
    const { displayCurrency } = useUser()

    useEffect(() => {
        if (!isCurrenciesLoading && currencies === null) {
            loadCurrencies()
        }
    })

    const selectHandler = (currency: string, exchangeRate: number|undefined) => {
        dispatch(setDisplayCurrency(currency))
        if (exchangeRate) {
            dispatch(setExchangeRate(exchangeRate))
        }
    }

    return <>
        {isCurrenciesLoading || currencies === null ? <CircularProgress/> : currencies.length > 0 ? <Box width={'90px'}><CurrencySwitcher onCurrencySelected={selectHandler} selectedCurrency={displayCurrency}/></Box> : <></>}
    </>
}