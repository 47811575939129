import React, { FC } from "react";
import { UserNode } from "./UserNode";
import { UserInfoModal } from "../user_info/UserInfoModal";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";

export const ManageUsersTree: FC = () => {
    const { showUser, setShowUser } = useManageUsers()
    return <div>
        <UserNode isRoot/>

        {showUser && <UserInfoModal close={() => {
            setShowUser(null)
        }} user={showUser}/>}
    </div>
}