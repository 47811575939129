import { FC } from "react";
import { useProviderAccess } from "../../features/providers/useProviderAccess";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { Card } from "@mui/material";
import { ProvidersTable } from "../../components/providers/ProvidersTable";

export const ProvidersPage: FC = () => {
    const { hasAccess } = useProviderAccess()
    const { token } = useUser()

    return token ? <>
        { hasAccess && <Card style={{ margin: 10, padding: 10, height: '100%' }}>
        <ProvidersTable height={'calc(100vh - 170px)'}/>
        </Card>}
    </> : <MustBeLogged/>;
}