import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RequestPayment } from "./RequestPayment";
import { PaymentAccount } from "../../../features/payment_system/PaymentSystemModels";

interface RequestPaymentDialogProps {
    close: () => void
    parentPaymentAccount: PaymentAccount
}

export const RequestPaymentDialog: FC<RequestPaymentDialogProps> = ({parentPaymentAccount, close = () => {}}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <RequestPayment onDone={pendingClose} parentPaymentAccount={parentPaymentAccount}/>
        </DialogContent>
    </Dialog>
}