import React, { FC, useEffect } from "react";
import { Alert, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { useGameHistory } from "../../../features/game_history/useGameHistory";
import { clearGameHistory } from "../../../features/game_history/gameHistorySlice";
import { useGameHistoryWatcher } from "../../../features/game_history/useGameHistoryWatcher";
import { GameHistoryFilter, GameHistorySort } from "../../../features/game_history/GameHistoryModels";
import { useLocation, useNavigate } from "react-router-dom";
import { SortedCellGameHistory } from "./SortedCellGameHistory";

interface GameHistoryTableProps {
    height: string
    independentQueryParams?: boolean // if false, component read query parameters from browser
}

export const GameHistoryTable: FC<GameHistoryTableProps> = ({height, independentQueryParams = false}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useGameHistoryWatcher(independentQueryParams)

    const { languagePack: {pack: {gamesHistory: lang}}} = useLanguage()
    const { gameHistory, isGameHistoryLoading, filter, filter: {orderBy}, currentPage, pageSize, getGameHistory } = useGameHistory()

    useEffect(() => {
        return () => {
            dispatch(clearGameHistory())
        }
    }, [ dispatch ])

    const handleClickSorting = (sort: GameHistorySort) => {
        if (!independentQueryParams) {
            searchParams.set('orderBy', sort);

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        } else {
            const newFilter: GameHistoryFilter = {
                ...filter,
                orderBy: sort
            }
            getGameHistory(currentPage, pageSize, newFilter)
        }
    }

    return <>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="manage users table">
                <TableHead>
                    <TableRow >
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.Username}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.userNameTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.Provider}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.providerTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.Game}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.gameTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.BetAmount}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.betAmountTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.WinAmount}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.winAmountTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                        <TableCell>
                            <SortedCellGameHistory
                                orderName={GameHistorySort.DateTime}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.dateTimeTableHeader}
                            </SortedCellGameHistory>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gameHistory && gameHistory.map((game, index) => (
                        <TableRow
                            key={game.name + '-gt-' + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {game.user_name}
                            </TableCell>
                            <TableCell>{game.sectionid}</TableCell>
                            <TableCell>{game.name}</TableCell>
                            <TableCell>{game.withdraw_sum ? convertCentsToCurrencyString(game.withdraw_sum) : '--'}</TableCell>
                            <TableCell>{game.deposit_sum ? convertCentsToCurrencyString(game.deposit_sum) : '--'}</TableCell>
                            <TableCell>{game.time}</TableCell>
                        </TableRow>
                    ))}
                    { gameHistory === null && isGameHistoryLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        { gameHistory?.length === 0 && <Alert variant="filled" severity="warning">
            {lang.gamesHistoryNotFoundMessage}
        </Alert>}
    </>
}