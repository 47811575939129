import React, { FC, useEffect } from "react";
import { useUser } from "../../../features/user/useUser";
import { useRoyaltyStatisticsAccess } from "../../../features/royalty/useRoyaltyStatisticsAccess";
import { useRoyalty } from "../../../features/royalty/useRoyalty";
import { Box, Card, Stack, Typography } from "@mui/material";
import { TimeFilterPanel } from "../../../components/commons/time_filter/TimeFilterPanel";
import { RoyaltyStatisticsTable } from "../royalty_statistics_table/RoyaltyStatisticsTable";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const RoyaltyStatisticsWidget: FC = () => {
    const { token } = useUser()
    const { languagePack: {pack: {royaltyStatistics: lang}}} = useLanguage()
    const { hasAccess } = useRoyaltyStatisticsAccess()
    const { filter, getRoyaltyStatistics, royaltyStatistics, isRoyaltyStatisticsLoading } = useRoyalty()

    const handleTimeChange = (fromTime: number | null, toTime: number | null) => {
        getRoyaltyStatistics({ ...filter, fromTime, toTime })
    }

    useEffect(() => {
        if (royaltyStatistics === null && !isRoyaltyStatisticsLoading && hasAccess) {
            getRoyaltyStatistics()
        }
    }, [getRoyaltyStatistics, hasAccess, isRoyaltyStatisticsLoading, royaltyStatistics])

    return token && hasAccess ? <Card style={{ margin: 10, padding: 10 }}>
        <Stack direction={'column'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <TimeFilterPanel
                    fromTime={filter.fromTime}
                    toTime={filter.toTime}
                    pullParamsToAddressBar={false}
                    onTimeChanged={handleTimeChange}
                />
                <Typography variant={'h2'} sx={{fontSize: '24px', fontWeight: 600}}>
                    {lang.royaltyStatisticsTitle}
                </Typography>
            </Box>

            <RoyaltyStatisticsTable independentQueryParams={true} height={'calc(100vh - 180px)'}/>
        </Stack>
    </Card> : <></>
}