import { FC } from "react";
import { useUser } from "../../../features/user/useUser";
import { LoginForm } from "../../commons/LoginForm";
import { Box, Typography } from "@mui/material";
import { chooseName } from "../../../features/user/userFuncs";
import { Link } from "react-router-dom";
import { AccountAvatar } from "../../commons/account_avatar/AccountAvatar";
import { Balance } from "../../commons/balance/Balance";
import LanguageSelector from "../../commons/lang/LanguageSelector";
import { GlobalCurrencySwitcher } from "../../commons/balance/GlobalCurrencySwitcher";

export const Header: FC = () => {
    const { token, user } = useUser()

    return <Box
        component={'header'}
        minHeight={'64px'}
        display={'flex'}
        alignItems={'center'}
        columnGap={'22px'}
        justifyContent={'flex-end'}
        width={'100%'}
        padding={'10px'}
    >
        {token === null ? <LoginForm/> :
            <>
                {user && <>
                    <GlobalCurrencySwitcher/>
                    <Balance/>
                </>}
                <Typography fontFamily={'Poppins'} fontSize={'20px'} fontWeight={'600'}>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/profile/'}>
                        <Box display={'flex'} columnGap={'10px'} alignItems={'center'}>
                            {chooseName(user)}
                            {user && <AccountAvatar user={user}/>}
                        </Box>
                    </Link>
                </Typography>
            </>
        }
        <LanguageSelector/>
    </Box>
}