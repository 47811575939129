import { FC, ReactNode } from "react";
import s from './SideMenu.module.css'
import clsx from "clsx";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface SideMenuItemProps {
    active?: boolean
    title: string
    link?: string
    icon?: ReactNode
    onClick?: () => void
}

export const SideMenuItem: FC<SideMenuItemProps> = ({active= false, title, link, icon, onClick= () => {}}) => {
    return <div className={clsx(s.menuItemContainer, active ? s.menuItemContainerActive : '')}>
        {icon && icon}
        {link ? <Link className={s.linkStyle} to={link}><Typography onClick={onClick} fontWeight={500}
                                                             fontFamily={'Poppins'}>{title}</Typography></Link> :
            <Typography style={{cursor: "pointer"}} fontWeight={500} fontFamily={'Poppins'} onClick={onClick}>{title}</Typography>}
    </div>
}