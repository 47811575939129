import React, { FC, useEffect } from "react";
import { useRoyalty } from "../../../features/royalty/useRoyalty";
import { clearRoyaltyStatistics } from "../../../features/royalty/royaltySlice";
import { useAppDispatch } from "../../../store";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { Alert, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { useRoyaltyStatisticsWatcher } from "../../../features/royalty/useRoyaltyStatisticsWatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { RoyaltyFilter, RoyaltySort } from "../../../features/royalty/RoyaltyModels";
import { SortedCellRoyalty } from "./SortedCellRoyalty";

interface RoyaltyStatisticsTableProps {
    height: string
    independentQueryParams?: boolean // if false, component read query parameters from browser
}

export const RoyaltyStatisticsTable: FC<RoyaltyStatisticsTableProps> = ({height, independentQueryParams = false}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useRoyaltyStatisticsWatcher(independentQueryParams)

    const { languagePack: {pack: {royaltyStatistics: lang}}} = useLanguage()
    const { royaltyStatistics, isRoyaltyStatisticsLoading, filter: {orderBy}, filter, getRoyaltyStatistics } = useRoyalty()

    useEffect(() => {
        return () => {
            dispatch(clearRoyaltyStatistics())
        }
    }, [dispatch])

    const handleClickSorting = (sort: RoyaltySort) => {
        if (!independentQueryParams) {
            searchParams.set('orderBy', sort);

            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        } else {
            const newFilter: RoyaltyFilter = {
                ...filter,
                orderBy: sort
            }
            getRoyaltyStatistics(newFilter)
        }
    }

    return <>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="manage users table">
                <TableHead>
                    <TableRow >
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.Provider}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.providerTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.Section}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.sectionTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.Currency}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.currencyTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.UserLoss}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.userLossesTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.UserWins}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.userWinsTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                        <TableCell>
                            <SortedCellRoyalty
                                orderName={RoyaltySort.GGR}
                                currentOrderBy={orderBy}
                                onClick={handleClickSorting}>
                                {lang.ggrTableHeader}
                            </SortedCellRoyalty>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {royaltyStatistics && royaltyStatistics.map((royaltyItem) => <TableRow key={`royalty-statistics-uid-${royaltyItem.uniqueLocalID}`}>
                        <TableCell>{royaltyItem.provider}</TableCell>
                        <TableCell>{royaltyItem.section}</TableCell>
                        <TableCell>{royaltyItem.currency}</TableCell>
                        <TableCell>{convertCentsToCurrencyString(royaltyItem.user_losses)}</TableCell>
                        <TableCell>{convertCentsToCurrencyString(royaltyItem.user_wins)}</TableCell>
                        <TableCell>{convertCentsToCurrencyString(royaltyItem.ggr)}</TableCell>
                    </TableRow>)}
                    { royaltyStatistics === null && isRoyaltyStatisticsLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>

        { royaltyStatistics?.length === 0 && <Alert variant="filled" severity="warning">
            {lang.royaltyStatisticsNotFoundMessage}
        </Alert>}
    </>
}