import { FC, useState } from "react";
import s from './SideMenu.module.css'
import { Typography } from "@mui/material";
import logo from '../../../../assets/logo_preview_128.png'
import { SideMenuItem } from "./SideMenuItem";
import { useLocation } from "react-router-dom";
import { SideMenuDivider } from "./SideMenuDivider";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CasinoIcon from '@mui/icons-material/Casino';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PaymentIcon from '@mui/icons-material/Payment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TuneIcon from '@mui/icons-material/Tune';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ConfirmationDialog } from "../../commons/ConfirmationDialog";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useUser } from "../../../features/user/useUser";
import { SecurityLogAccess } from "../../../pages/security_logs/SecurityLogPage";
import { BuyBalanceAccess } from "../../../pages/buy_balance/BuyBalancePage";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { RoyaltyStatisticsAccess } from "../../../features/royalty/RoyaltyModels";
import { BonusAccess } from "../../../features/bonus/bonusModels";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { FinancesAccess } from "../../../features/finances/FinancesModels";
import { ProviderAccess } from "../../../features/providers/ProviderModels";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

interface SideMenuProps {
    close?: () => void
}

export const SideMenu: FC<SideMenuProps> = ({close = () => {}}) => {
    const [ showLogout, setShowLogout ] = useState<boolean>(false)
    const { token, userRole, logOut } = useUser()
    const { languagePack: { pack: lang } } = useLanguage()
    const location = useLocation();

    // const isActivePath = (path: string): boolean => {
    //     return location.pathname === path
    // }

    const isActivePath = (paths: string | string[]): boolean => {
        const normalizedPaths = Array.isArray(paths) ? paths : [paths];
        return normalizedPaths.includes(location.pathname);
    };

    return <div className={s.container}>
        <div className={s.head}>
            <img alt={'Unisoft logo'} src={logo} height={24}/>
            <Typography variant={'h1'} fontFamily={'Poppins'} fontWeight={600} textTransform={'uppercase'} fontSize={24} color={'var(--dark-color)'}>Unisoft</Typography>
        </div>


        {token && <div className={s.menuItemsSection}>
            <SideMenuItem onClick={close} icon={<DashboardIcon/>} title={lang.sideMenu.dashboard} link={"/"}
                          active={isActivePath(["/", "/dashboard/"])}/>
            <SideMenuItem onClick={close} icon={<PeopleAltIcon/>} title={lang.sideMenu.users} link={"/users/"} active={isActivePath(["/users/"])}/>
            <SideMenuItem onClick={close} icon={<PointOfSaleIcon/>} title={lang.sideMenu.moneyTransfers} link={"/money_transfers/"}
                          active={isActivePath("/money_transfers/")}/>
            <SideMenuItem onClick={close} icon={<CasinoIcon/>} title={lang.sideMenu.gameHistory} link={"/game_history/"}
                          active={isActivePath("/game_history/")}/>
            <SideMenuItem onClick={close} icon={<PaymentIcon/>} title={lang.sideMenu.paymentSystems} link={"/payment_systems/"}
                          active={isActivePath("/payment_systems/")}/>

            {
                userRole !== null && <>
                    {BuyBalanceAccess.includes(userRole) &&
                        <SideMenuItem
                            onClick={close}
                            icon={<RequestQuoteIcon/>}
                            title={lang.sideMenu.buyBalance}
                            link={"/buy_balance/"}
                            active={isActivePath("/buy_balance/")}
                        />
                    }
                    {SecurityLogAccess.includes(userRole) &&
                        <SideMenuItem
                            onClick={close}
                            icon={<RequestQuoteIcon/>}
                            title={lang.sideMenu.securityLog}
                            link={"/security_logs/"}
                            active={isActivePath("/security_logs/")}
                        />
                    }
                    {BonusAccess.includes(userRole) &&
                        <SideMenuItem
                            onClick={close}
                            icon={<CardGiftcardIcon/>}
                            title={lang.sideMenu.bonus}
                            link={"/bonus/"}
                            active={isActivePath("/bonus/")}
                        />
                    }
                    {FinancesAccess.includes(userRole) && <SideMenuItem
                        onClick={close}
                        icon={<AccountBalanceIcon/>}
                        title={lang.sideMenu.finances}
                        link={"/finances/"}
                        active={isActivePath("/finances/")}
                    />}
                    {ProviderAccess.includes(userRole) && <SideMenuItem
                        onClick={close}
                        icon={<SportsEsportsIcon/>}
                        title={lang.sideMenu.providers}
                        link={"/providers/"}
                        active={isActivePath("/providers/")}
                    />}
                </>
            }

            {/*<SideMenuDivider>{lang.sideMenu.limitsDivider}</SideMenuDivider>*/}
            {/*<SideMenuItem onClick={close} icon={<MoneyOffIcon/>} title={lang.sideMenu.limitsGeneral} link={"/limits/general/"}*/}
            {/*              active={isActivePath("/limits/general/")}/>*/}
            {/*<SideMenuItem onClick={close} title={lang.sideMenu.limitsOutcome} link={"/limits/outcome/"} active={isActivePath("/limits/outcome/")}/>*/}
            {/*<SideMenuDivider/>*/}
            {/*<SideMenuItem onClick={close} icon={<MonetizationOnIcon/>} title={lang.sideMenu.affiliateSystem} link={"/affiliate_system/"}*/}
            {/*              active={isActivePath("/affiliate_system/")}/>*/}
            {/*<SideMenuItem onClick={close} icon={<AssignmentIcon/>} title={lang.sideMenu.crmSettings} link={"/crm_settings/"}*/}
            {/*              active={isActivePath("/crm_settings/")}/>*/}
            {/*<SideMenuItem onClick={close} icon={<EventAvailableIcon/>} title={lang.sideMenu.triggerEvents} link={"/trigger_events/"}*/}
            {/*              active={isActivePath("/trigger_events/")}/>*/}
            {/*<SideMenuItem onClick={close} icon={<TuneIcon/>} title={lang.sideMenu.websiteSettings} link={"/website_settings/"}*/}
            {/*              active={isActivePath("/website_settings/")}/>*/}

            <SideMenuDivider/>
            <SideMenuItem onClick={() => {
                setShowLogout(true)
            }} icon={<ExitToAppIcon/>} title={lang.sideMenu.logOutBtn}/>
        </div>}


        {showLogout && <ConfirmationDialog close={() => {
            setShowLogout(false)
        }} message={lang.common.logOutConfirmationMessage} onConfirm={() => {
            logOut()
        }}/>}
    </div>
}