import React, { FC } from "react";
import { useUser } from "../../features/user/useUser";
import { Box, Card, Stack } from "@mui/material";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { UserRole } from "../../features/user/user";
import { SecurityLogTable } from "./securityLogTable/SecurityLogTable";
import { Paginator } from "../../components/commons/paginator/Paginator";
import { PageSizeSwitcher } from "../../components/commons/pageSizeSwitcher/PageSizeSwitcher";
import { useSecurityLog } from "../../features/security_log/useSecurityLog";
import { TimeFilterPanel } from "../../components/commons/time_filter/TimeFilterPanel";
import { UsernameFilter } from "../../components/commons/username_filter/UsernameFilter";

export const SecurityLogAccess = [
    UserRole.PLATFORM,
    UserRole.OWNER
]

export const SecurityLogPage: FC = () => {
    const { token, userRole } = useUser()
    const hasAccess = userRole !== null && SecurityLogAccess.includes(userRole)
    const { totalPages, currentPage, pageSize, filter} = useSecurityLog()

    return token ? <>
        {hasAccess && <Card style={{ margin: 10, padding: 10 }}>
            <Stack direction={'column'}>
                <Box display={'flex'} alignItems={'center'}>
                    <TimeFilterPanel fromTime={filter.fromTime} toTime={filter.toTime} pullParamsToAddressBar/>
                    <UsernameFilter username={filter.username} pullParamsToAddressBar/>
                </Box>
                <SecurityLogTable height={'calc(100vh - 234px)'}/>
                <Box padding={'12px'} display={'flex'} justifyContent={'flex-end'}>
                    <Paginator totalPages={totalPages} currentPage={currentPage}/>
                    <Box flexGrow={1}/>
                    <PageSizeSwitcher pageSize={pageSize}/>
                </Box>
            </Stack>

        </Card>}
    </> : <MustBeLogged/>
}