import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";
import { SecurityLogFilter, stringToSecurityLogSort } from "./SecurityLogModels";
import { useSecurityLog } from "./useSecurityLog";
import { useLocation } from "react-router-dom";

export const useSecurityLogWatcher = () => {
    const location = useLocation()
    const { securityLog, isSecurityLogLoading, getSecurityLog, pageSize, currentPage, filter } = useSecurityLog()

    useEffect(() => {
        if (!isSecurityLogLoading) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const qEvent = searchParams.get('event')
            const qOrderBy = searchParams.get('orderBy')

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const usernameHasChanges = qUsername !== filter.username
            const eventHasChanges = qEvent !== filter.event
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const logNotLoadedYet = securityLog === null

            if (pageHasChanges || pageSizeHasChanges || fromTimeHasChanges || toTimeHasChanges || usernameHasChanges || eventHasChanges || orderByHasChanges || logNotLoadedYet) {
                console.group('Changes')
                console.log('pageHasChanges', pageHasChanges)
                console.log('pageSizeHasChanges', pageSizeHasChanges)
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                console.log('usernameHasChanges', usernameHasChanges)
                console.log('eventHasChanges', eventHasChanges)
                console.log('orderByHasChanges', orderByHasChanges)
                console.log('logNotLoadedYet', logNotLoadedYet)
                console.groupEnd()

                const filterNew: SecurityLogFilter = {
                    event: qEvent,
                    fromTime: qFromTime,
                    toTime: qToTime,
                    username: qUsername,
                    orderBy: qOrderBy !== null ? stringToSecurityLogSort(qOrderBy) : null
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize

                getSecurityLog(pageNew, itemsPerPageNew, filterNew)
            }
        }
    }, [currentPage, filter, getSecurityLog, isSecurityLogLoading, location.search, pageSize, securityLog])
}