import { FC, useEffect, useState } from "react";
import { PaymentAccount, PaymentMethodTemplate, Proof } from "../../../features/payment_system/PaymentSystemModels";
import { usePaymentSystems } from "../../../features/payment_system/usePaymentSystems";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { MoneyInput } from "../../../components/commons/money_input/MoneyInput";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../../features/common_funcs";
import { AddPayment } from "../../payment_systems/add_payment/AddPayment";
import { InfoWithLabel } from "../../../components/commons/info_with_label/InfoWithLabel";
import { useSnackbar, VariantType } from "notistack";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface RequestPaymentProps {
    onDone?: () => void
    parentPaymentAccount: PaymentAccount
}

export const RequestPayment: FC<RequestPaymentProps> = ({parentPaymentAccount, onDone = () => {}}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { languagePack: {pack: {requestPayment: lang}}} = useLanguage()

    const {
        parentDepositRate,
        userPaymentAccounts,
        getUserPaymentAccounts,
        isPaymentAccountsLoading,
        unfilteredPaymentMethods,
        isPaymentMethodsLoading,
        getPaymentMethods,
        requestPayment,
        isPaymentRequestLoading
    } = usePaymentSystems()

    const [ initLoad, setInitLoad ] = useState(true);

    const [ paymentAmount, setPaymentAmount ] = useState<string>('')
    const [ hasPaymentError, setHasPaymentError ] = useState<boolean>(false)

    const [ paymentWithRate, setPaymentWithRate ] = useState<string>('')
    const [ hasDepositError, setHasDepositError ] = useState<boolean>(false)

    const [ secondStep, setSecondStep ] = useState<boolean>(false)

    const [ userPaymentAccount, setUserPaymentAccount ] = useState<PaymentAccount | null>(null);
    const [ paymentMethodTemplate, setPaymentMethodTemplate ] = useState<PaymentMethodTemplate | null>(null);

    const [ proof, setProof ] = useState<string>('')

    const handleAmountChange = (value: string) => {
        setPaymentAmount(value)
        if (parentDepositRate !== null) {
            const amountCents = convertStringCurrencyToCents(value)
            setPaymentWithRate(convertCentsToCurrencyString(Math.ceil(amountCents / parentDepositRate)))
        }
    }

    const handleDepositChange = (value: string) => {
        setPaymentWithRate(value)
        if (parentDepositRate !== null) {
            const depositCents = convertStringCurrencyToCents(value)
            setPaymentAmount(convertCentsToCurrencyString(depositCents * parentDepositRate))
        }
    }

    useEffect(() => {
        if (initLoad) {
            // If the component has just been mounted
            if (userPaymentAccounts === null && !isPaymentAccountsLoading) {
                // If user payment accounts are not loaded and not currently loading
                // Request user payment accounts
                getUserPaymentAccounts();
            }
            if (unfilteredPaymentMethods === null && !isPaymentMethodsLoading) {
                // If payment method templates are empty and not currently loading
                // Request payment method templates
                getPaymentMethods();
            }
            setInitLoad(false);
        }
    }, [getPaymentMethods, getUserPaymentAccounts, initLoad, isPaymentAccountsLoading, isPaymentMethodsLoading, unfilteredPaymentMethods, userPaymentAccounts]);

    useEffect(() => {
        if (userPaymentAccounts !== null && unfilteredPaymentMethods !== null) {
            // If user payment accounts and payment method templates are loaded
            if (userPaymentAccount === null) {
                // If there is no user payment account yet (e.g., it has not been found
                // or created yet), then search for it
                const paymentAccount = userPaymentAccounts.find(acc => acc.method_id === parentPaymentAccount.method_id);
                if (paymentAccount) {
                    // If the method is found, save it to the state
                    setUserPaymentAccount(paymentAccount);
                }
            }
            if (paymentMethodTemplate === null) {
                const template = unfilteredPaymentMethods.find(t => t.id === parentPaymentAccount.method_id);
                if (template) {
                    setPaymentMethodTemplate(template);
                }
            }
        }
    }, [parentPaymentAccount, paymentMethodTemplate, unfilteredPaymentMethods, userPaymentAccount, userPaymentAccounts]);

    const paymentRequestClickHandler = () => {
        if (paymentMethodTemplate) {
            const proofObj: Proof = {
                ...paymentMethodTemplate.proof,
                value: proof
            }
            requestPayment(
                parentPaymentAccount.method_id,
                convertStringCurrencyToCents(paymentAmount),
                proofObj,
                () => {
                    sendSnackBar('success', 'Your request has been sent. Soon the moderator will check your payment and you will receive money into your account.')
                    onDone()
                }
            )
        }
    }

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    return <Stack rowGap={'20px'}>
        {/* If user payment account is not available, but the payment method template is loaded,
        show the option to add a payment */}
        {userPaymentAccount === null && paymentMethodTemplate !== null && <AddPayment paymentMethodTemplate={paymentMethodTemplate} />}

        {userPaymentAccount !== null && <>
            <MoneyInput
                disabled={secondStep}
                label={lang.paymentAmountLabel}
                value={paymentAmount}
                // maxAmount={maxAmount}
                onValueChanged={handleAmountChange}
                // currency={childUser.user_currency}
                hasError={(has) => setHasPaymentError(has)}
            />
            <MoneyInput
                disabled={secondStep}
                helperText={parentDepositRate !== null ? lang.depositRateHelper.replace('%rate%', parentDepositRate.toString()) : undefined}
                label={lang.youllReceiveOnBalanceLabel}
                value={paymentWithRate}
                // maxAmount={maxAmount}
                onValueChanged={handleDepositChange}
                // currency={childUser.user_currency}
                hasError={(has) => setHasDepositError(has)}
            />
            <Box display={'flex'} justifyContent={secondStep ? 'flex-start' : 'flex-end'}>
                <Button
                    variant={'contained'}
                    color={secondStep ? 'secondary' : 'primary'}
                    disabled={hasDepositError || hasPaymentError}
                    onClick={() => setSecondStep(!secondStep)}
                >
                    {secondStep ? lang.backBtn : lang.nextBtn}
                </Button>
            </Box>

            {secondStep && <>
                <Typography>
                    {lang.transferMoneyToThisAccountMessage}
                </Typography>
                <Typography>
                    Payment method: {parentPaymentAccount.method_name}
                </Typography>
                {parentPaymentAccount.params.fields.map(field => <InfoWithLabel key={`rp-label-${field.label}`}
                    label={field.label}>{field.value}</InfoWithLabel>)}

                <Typography>
                    {lang.enterYourProofMessage}
                </Typography>
                {paymentMethodTemplate && <TextField
                    onChange={e => setProof(e.target.value)}
                    value={proof}
                    label={paymentMethodTemplate.proof.label}
                    helperText={paymentMethodTemplate.proof.helper}
                    placeholder={paymentMethodTemplate.proof.placeholder}
                />}

                <Button onClick={paymentRequestClickHandler} variant={'contained'} disabled={proof.trim() === '' || isPaymentRequestLoading}>
                    {lang.iTransferMoneyBtn}
                </Button>
            </>}
        </>}
    </Stack>
}