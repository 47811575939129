import { GameHistoryState } from "./gameHistorySlice";
import { ApiResponse } from "../apiResponseModels";

export interface GameHistory {
    user_name: string;
    name: string; // Game name
    sectionid: string; // Provider name
    type: string;
    withdraw_sum: number | null; // amount debited from the user (bet)
    deposit_sum: number | null; // amount won by the user (winnings)
    time: string;
    time_unix: number;
}

// Filter model for loading game history
export interface GameHistoryFilter {
    fromTime: number|null
    toTime: number|null
    username: string|null
    orderBy: GameHistorySort|null
}

// Interface for the return values of the useGameHistory hook
export interface iUseGameHistory extends GameHistoryState {
    getGameHistory: (page: number, itemsPerPage?: number, filter?: GameHistoryFilter|null) => void
}

// Interface for the server response of getting the list of game history
export interface ShowGameHistoryResponse extends ApiResponse {
    data?: GameHistory[]
    total_transactions?: number
}

export enum GameHistorySort {
    Username = 'user_name',
    Provider = 'sectionid',
    Game = 'game_name',
    BetAmount = 'withdraw_sum',
    WinAmount = 'deposit_sum',
    DateTime = 'time'
}

export const stringToGameHistorySort = (stringGameHistorySort: string): GameHistorySort => {
    switch (stringGameHistorySort) {
        case 'user_name':
            return GameHistorySort.Username
        case 'sectionid':
            return GameHistorySort.Provider
        case 'game_name':
            return GameHistorySort.Game
        case 'withdraw_sum':
            return GameHistorySort.BetAmount
        case 'deposit_sum':
            return GameHistorySort.WinAmount
        case 'time':
        default:
            return GameHistorySort.DateTime
    }
}