import { FC, Fragment } from "react";
import { MoneyTransfer } from "../../../features/money_transfers/MoneyTransfersModels";
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { InfoWithLabel } from "../../../components/commons/info_with_label/InfoWithLabel";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { usePaymentSystems } from "../../../features/payment_system/usePaymentSystems";

interface ApproveTransactionProps {
    transaction: MoneyTransfer
    onDone?: () => void
}

export const ApproveTransaction: FC<ApproveTransactionProps> = ({transaction, onDone= () => {}}) => {
    const { languagePack: { pack: { approveTransaction: lang } } } = useLanguage()
    const { approvePayout, isApprovePayoutLoading, approvePayment, isApprovePaymentLoading } = usePaymentSystems()
    const depositManualMode = transaction.transfer_type === 'deposit_manual'

    const handleClickApprove = (approve: boolean) => () => {
        if (depositManualMode) {
            approvePayment(transaction.transfer_id, approve, onDone)
        } else {
            approvePayout(transaction.transfer_id, approve, onDone)
        }

    }

    return <Stack gap={'24px'}>
        <Typography variant={'h5'}>
            {
                depositManualMode ? lang.userRequestPaymentTitle
                        .replace('%username%', transaction.user_name)
                        .replace('%payment_system%', transaction.method_name)
                        .split('%money%')
                        .map((part, index) => <Fragment key={`title-${index}`}>
                            {part}{index === 0 &&
                            <span style={{color: '#860dcc', fontWeight: '600'}}>
                            {convertCentsToCurrencyString(transaction.transfer_amount) + (transaction.transfer_currency ? ` ${transaction.transfer_currency}` : '')}
                        </span>}
                        </Fragment>)
                    :
                lang.userRequestPayoutTitle
                    .replace('%username%', transaction.user_name)
                    .replace('%payment_system%', transaction.method_name)
                    .split('%money%')
                    .map((part, index) => <Fragment key={`title-${index}`}>
                        {part}{index === 0 &&
                        <span style={{color: '#4ac501', fontWeight: '600'}}>
                            {convertCentsToCurrencyString(transaction.transfer_amount) + (transaction.transfer_currency ? ` ${transaction.transfer_currency}` : '')}
                        </span>}
                    </Fragment>)
            }
        </Typography>
        { transaction.user_account && <Stack>
            {transaction.user_account.fields.map(field => <InfoWithLabel key={`transaction-field-label-${field.label}`}
                label={field.label}>{field.value}</InfoWithLabel>)}
        </Stack> }
        {depositManualMode && <>
            <Typography variant={'h6'}>
                User payment proof:
            </Typography>
            {transaction.proof && <InfoWithLabel label={transaction.proof.helper}>{transaction.proof.value}</InfoWithLabel>}
        </>}
        <Typography variant={'h6'}>
            {lang.confirmPayoutMessage}
        </Typography>
        <Box display={'flex'} justifyContent={'flex-end'} gap={'10px'}>
            <Button disabled={(isApprovePaymentLoading || isApprovePayoutLoading)} onClick={handleClickApprove(false)} color={'error'} variant={'contained'}>{lang.declineBTN}</Button>
            <Button disabled={(isApprovePaymentLoading || isApprovePayoutLoading)} onClick={handleClickApprove(true)} variant={'contained'}>{lang.approveBTN}</Button>
        </Box>
        {(isApprovePaymentLoading || isApprovePayoutLoading) && <LinearProgress sx={{width: '100%'}}/>}
    </Stack>
}