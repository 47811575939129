import { SvgIcon, SvgIconProps } from "@mui/material";

export const TunisiaDinarIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <g>
                <circle cx="15" cy="16" r="1"/>
                <path
                    d="M20.31 17H18a1 1 0 0 1 0-2h2.31a.65.65 0 0 0 .56-.37 1 1 0 0 0 0-1l-2.79-4.07a1 1 0 1 1 1.66-1.12l2.8 4.12a3 3 0 0 1 .08 3A2.65 2.65 0 0 1 20.31 17zM10 17H6a4 4 0 0 1-4-4v-3a1 1 0 0 1 2 0v3a2 2 0 0 0 2 2h4a1 1 0 0 0 1-1v-4a1 1 0 0 1 2 0v4a3 3 0 0 1-3 3z"/>
                <circle cx="6" cy="8" r="1"/>
                <circle
                    cx="9" cy="8" r="1"/>
            </g>
        </SvgIcon>
    );
}