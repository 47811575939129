import {
    BanUserResponse,
    CreateUserResponse,
    DepositMoneyResponse,
    GetAvailableCurrenciesResponse,
    GetNextAvailableUserLevelsResponse,
    iUseManageUsers,
    ManageUsersFilter,
    ShowUsersListResponse,
    ShowUsersMode,
    ShowUsersTreeResponse,
    UsersTreeNode,
    WithdrawMoneyResponse
} from "./manageUsersModels";
import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import {
    addToEndOfUsersTreeList,
    addUserBanSending,
    changeManageUsersFilter,
    changeShowUser,
    changeUserBanState,
    clearUsersList,
    clearUsersTreeList,
    removeBanUserSending,
    setCurrencies,
    setCurrentManageUsersPage,
    setIsCurrenciesLoading,
    setIsDepositToUserSending,
    setIsNextAvailableUserLevelsLoading,
    setIsUserCreating,
    setIsUsersListLoading,
    setIsWithdrawFromUserLoading,
    setManageUsersPageSize,
    setMode, setNextAvailableUserLevels,
    setTotalManageUsersRecords,
    setUserNodeIdLoading,
    setUsersList,
    updateManageUserBalance
} from "./manageUsersSlice";
import Config from "../../config";
import axios, { AxiosError } from "axios";
import { convertUnixToCompactLocaleDate } from "../common_funcs";
import { User } from "../user/user";
import { updateUserBalance } from "../user/userSlice";

export const useManageUsers = (): iUseManageUsers => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => state.manageUsers)
    const { pageSize, filter, currentPage, mode : userListMode } = state

    const loadManageUsersList = useCallback((page: number, itemsPerPage: number = 30, filter: ManageUsersFilter | null = null) => {
        let filterForSending = state.filter;
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime ||
                filter.fromTime !== state.filter.fromTime 
                // || filter.username !== state.filter.username
                || filter.orderBy !== state.filter.orderBy
            ) {
                dispatch(changeManageUsersFilter(filter))
                filterForSending = filter
            }
        }
        let itemsPerPageToSend = state.pageSize
        if (itemsPerPage !== state.pageSize) {
            dispatch(setManageUsersPageSize(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = state.currentPage
        if (page !== state.currentPage) {
            dispatch(setCurrentManageUsersPage(page))
            pageToSend = page
        }
        if (token) {
            dispatch(setIsUsersListLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowUsers')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.orderBy !== null) {
                data.append('orderby', filterForSending.orderBy)
            }

            axios.post<ShowUsersListResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: manageUsersList, total_users } = response.data
                    if (success) {
                        if (manageUsersList) {
                            manageUsersList.forEach(user => {
                                user.registration_time = convertUnixToCompactLocaleDate(user.registration_time_unix)
                            })
                            dispatch(setUsersList(manageUsersList))
                        }
                    }
                    if (total_users) {
                        dispatch(setTotalManageUsersRecords(total_users))
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUsersList([]))
                            if (total_users && total_users > 0) {
                                setLocalizedError(error)
                            }
                        }
                        else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setUsersList([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setIsUsersListLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, state.currentPage, state.filter, state.pageSize, token ])

    const loadUserTreeNode = useCallback((parentID: number | null, onSuccess: (hasChildren: boolean) => void = () => {
    }) => {
        if (token) {
            dispatch(setUserNodeIdLoading(parentID))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowUsers')
            data.append('token', token)

            if (parentID) {
                data.append('parentid', parentID.toString())
            }

            data.append('viewtype', ShowUsersMode.Tree)

            axios.post<ShowUsersTreeResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: userTreeNodes } = response.data
                    if (success) {
                        if (userTreeNodes) {
                            userTreeNodes.forEach(user => {
                                user.registration_time = convertUnixToCompactLocaleDate(user.registration_time_unix)
                            })
                            if (parentID !== null) {
                                const updatedUserTreeNodes = userTreeNodes.map((node: UsersTreeNode) => {
                                    return { ...node, parentId: parentID }
                                })
                                dispatch(addToEndOfUsersTreeList(updatedUserTreeNodes))
                            } else {
                                dispatch(addToEndOfUsersTreeList(userTreeNodes))
                            }
                        }
                        onSuccess(true)
                    }
                    if (error) {
                        if (error.code === 2) {
                            onSuccess(false)
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setUserNodeIdLoading(null))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const switchUserListMode = useCallback((mode: ShowUsersMode) => {
        dispatch(setMode(mode))
        if (mode === ShowUsersMode.Tree) {
            dispatch(clearUsersList())
        } else {
            dispatch(clearUsersTreeList())
        }
    }, [ dispatch ])

    const setShowUser = useCallback((user: User | null) => {
        dispatch(changeShowUser(user))
    }, [ dispatch ])

    const banUser = useCallback((user_id: number, banned: boolean) => {
        if (token) {
            dispatch(addUserBanSending({ user_id, banned }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'BanUser')
            data.append('token', token)
            data.append('userid', user_id.toString())
            data.append('ban', banned.toString())

            axios.post<BanUserResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, ban } = response.data
                    if (success) {
                        if (ban !== undefined) {
                            dispatch(changeUserBanState({ user_id, banned: ban }))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(removeBanUserSending({ user_id, banned }))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const createUser = useCallback((user: User, password: string, onSuccess = () => {
    }) => {
        if (token) {
            dispatch(setIsUserCreating(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'CreateUser')
            data.append('token', token)
            data.append('username', user.user_name)
            data.append('useremail', user.user_email ?? '')
            data.append('userphone', user.user_phone ?? '')
            data.append('currency', user.user_currency ?? '')
            data.append('userrole', user.user_role)
            data.append('password', password)

            axios.post<CreateUserResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, user: newUser } = response.data
                    if (success) {
                        if (userListMode === ShowUsersMode.Tree) {
                            newUser.registration_time = convertUnixToCompactLocaleDate(newUser.registration_time_unix)
                            dispatch(addToEndOfUsersTreeList([ newUser ]))
                        } else {
                            loadManageUsersList(currentPage, pageSize, filter)
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setIsUserCreating(false))
                })
        }
    }, [ currentPage, dispatch, filter, handleNetworkErrors, loadManageUsersList, pageSize, setLocalizedError, token, userListMode ])

    const loadCurrencies = useCallback(() => {
        if (token) {
            dispatch(setIsCurrenciesLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetAvailableCurrencies')
            data.append('token', token)

            axios.post<GetAvailableCurrenciesResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, currencies } = response.data
                    if (success) {
                        if (currencies) {
                            dispatch(setCurrencies(currencies))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setCurrencies([]))
                        }
                        else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setCurrencies([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setIsCurrenciesLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const sendDepositMoneyToUser = useCallback((amount: number, childID: number, currency: string, onSuccess = () => {
    }) => {
        if (token) {
            dispatch(setIsDepositToUserSending(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'DepositMoney')
            data.append('token', token)
            data.append('childid', childID.toString())
            data.append('amount', amount.toString())
            data.append('currency', currency)

            axios.post<DepositMoneyResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, child_balance, sender_balance } = response.data
                    if (success) {
                        if (child_balance !== undefined) {
                            dispatch(updateManageUserBalance({ userID: childID, balance: child_balance }))
                        }
                        if (sender_balance !== undefined) {
                            dispatch(updateUserBalance(sender_balance))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setIsDepositToUserSending(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const withdrawMoneyFromUser = useCallback((amount: number, childID: number, onSuccess = () => {
    }) => {
        if (token) {
            dispatch(setIsWithdrawFromUserLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'WithdrawMoney')
            data.append('token', token)
            data.append('childid', childID.toString())
            data.append('amount', amount.toString())

            axios.post<WithdrawMoneyResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, child_balance } = response.data
                    if (success) {
                        if (child_balance !== undefined) {
                            dispatch(updateManageUserBalance({ userID: childID, balance: child_balance }))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setIsWithdrawFromUserLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const getNextAvailableUserLevels = useCallback(() => {
        if (token) {
            dispatch(setIsNextAvailableUserLevelsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetNextAvailableUserLevels')
            data.append('token', token)

            axios.post<GetNextAvailableUserLevelsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, levels } = response.data
                    if (success) {
                        if (levels) {
                            dispatch(setNextAvailableUserLevels(levels))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setNextAvailableUserLevels([]))
                        }
                        else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setNextAvailableUserLevels([]))
                    handleNetworkErrors(error)
                })
                .finally(
                    () => {
                        dispatch(setIsNextAvailableUserLevelsLoading(false))
                    }
                )
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    return {
        ...state,
        loadManageUsersList,
        switchUserListMode,
        loadUserTreeNode,
        setShowUser,
        banUser,
        createUser,
        sendDepositMoneyToUser,
        withdrawMoneyFromUser,
        loadCurrencies,
        getNextAvailableUserLevels
    }
}