import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { MoneyPack } from "../../features/finances/FinancesModels";
import { CoinsPackConstructor } from "./CoinsPackContructor";

interface CoinsPackConstructorDialogProps {
    close: () => void
    coinPackEditing?: MoneyPack
}

export const CoinsPackConstructorDialog: FC<CoinsPackConstructorDialogProps> = ({close = () => {}, coinPackEditing}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'sm'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <CoinsPackConstructor onDone={pendingClose} coinPackEditing={coinPackEditing}/>
        </DialogContent>
    </Dialog>
}