import React, { FC, useState } from "react";
import { Dialog, DialogContent, Hidden, IconButton } from "@mui/material";
import { User } from "../../../features/user/user";
import { UserInfo } from "./UserInfo";
import s from './UserInfo.module.css'
import CancelIcon from "@mui/icons-material/Cancel";

interface UserInfoModalProps {
    close: () => void
    user: User
}

export const UserInfoModal: FC<UserInfoModalProps> = ({close = () => {}, user}) => {
        const [ opened, setOpened ] = useState(true)

        const pendingClose = () => {
            setOpened(false);
            setTimeout(() => close(), 500)
        }

        return <>
            <Hidden mdUp>
                <Dialog
                    fullWidth
                    fullScreen
                    open={opened}
                    onClose={pendingClose}
                >
                    <div style={{ position: "fixed", right: 0, zIndex: 9999 }}>
                        <IconButton
                            color="primary"
                            aria-label="close"
                            onClick={pendingClose}
                            style={{ borderRadius: "50%" }}
                        >
                            <CancelIcon/>
                        </IconButton>
                    </div>
                    <DialogContent className={s.dialogContent}>
                        <UserInfo user={user}/>
                    </DialogContent>
                </Dialog>
            </Hidden>
            <Hidden mdDown>
                <Dialog
                    classes={{ paper: s.dialog }}
                    maxWidth={'md'}
                    fullWidth
                    open={opened}
                    onClose={pendingClose}
                >
                    <DialogContent>
                        <UserInfo user={user} expandMargin/>
                    </DialogContent>
                </Dialog>
            </Hidden>
        </>
    }