import { LangPack } from "./langModel";
import { Lang } from "./useLanguageInterface";
import { enLang } from "./packs/enLang";
import { ruLang } from "./packs/ruLang";
import { deLang } from "./packs/deLang";

export const getLangPackByName = (lang: Lang): LangPack => {
    switch (lang) {
        case Lang.RU:
            return ruLang
        case Lang.DE:
            return deLang
        // case Lang.FR:
        //     return frLang
        // case Lang.ES:
        //     return esLang
        // case Lang.PT:
        //     return ptLang
        case Lang.EN:
        default:
            return enLang
    }
}