import React, { useEffect, useState } from 'react';
import { BaseLayout } from "./app/components/layout/BaseLayout";
import { useLanguage } from "./app/features/localisation/useLanguage";
import { Route, Routes } from "react-router-dom";
import { DashboardPage } from "./app/pages/dashboard/DashboardPage";
import { UsersPage } from "./app/pages/users/UsersPage";
import { ProfilePage } from "./app/pages/profile/ProfilePage";
import { useUser } from "./app/features/user/useUser";
import { MoneyTransfersPage } from "./app/pages/money_transfers/MoneyTransfersPage";
import { GameHistoryPage } from "./app/pages/games_history/GameHistoryPage";
import { PaymentSystemsPage } from "./app/pages/payment_systems/PaymentSystemsPage";
import { BuyBalanceAccess, BuyBalancePage } from "./app/pages/buy_balance/BuyBalancePage";
import { SecurityLogAccess, SecurityLogPage } from "./app/pages/security_logs/SecurityLogPage";
import { RoyaltyStatisticsPage } from "./app/pages/royalty_statistics/RoyaltyStatisticsPage";
import { RoyaltyStatisticsAccess } from "./app/features/royalty/RoyaltyModels";
import { BonusAccess } from "./app/features/bonus/bonusModels";
import { BonusPage } from "./app/pages/bonus/BonusPage";
import { FinancesAccess } from "./app/features/finances/FinancesModels";
import { FinancesPage } from "./app/pages/finances/FinancesPage";
import { ProvidersPage } from "./app/pages/providers/ProvidersPage";
import { ProviderAccess } from "./app/features/providers/ProviderModels";

function App() {
    const { userRole } = useUser()
    const { switchLanguageFromStore } = useLanguage()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const { refreshUserInfo } = useUser()

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            refreshUserInfo()
            switchLanguageFromStore()
        }
    }, [initLoad, refreshUserInfo, switchLanguageFromStore])

    return (
        <BaseLayout>
            <Routes>
                <Route path="/" element={<DashboardPage/>}/>
                <Route path="/dashboard" element={<DashboardPage/>}/>
                <Route path="/users" element={<UsersPage/>}/>
                <Route path="/money_transfers" element={<MoneyTransfersPage/>}/>
                <Route path="/game_history" element={<GameHistoryPage/>}/>
                <Route path="/payment_systems" element={<PaymentSystemsPage/>}/>
                {
                    userRole !== null && <>
                        {BuyBalanceAccess.includes(userRole) &&
                            <Route path="/buy_balance" element={<BuyBalancePage/>}/>
                        }
                        {SecurityLogAccess.includes(userRole) &&
                            <Route path="/security_logs" element={<SecurityLogPage/>}/>
                        }
                        {RoyaltyStatisticsAccess.includes(userRole) &&
                            <Route path="/royalty_statistics" element={<RoyaltyStatisticsPage/>}/>
                        }
                        {BonusAccess.includes(userRole) &&
                            <Route path="/bonus" element={<BonusPage/>}/>
                        }
                        {FinancesAccess.includes(userRole) &&
                            <Route path="/finances" element={<FinancesPage/>}/>
                        }
                        {ProviderAccess.includes(userRole) &&
                            <Route path="/providers" element={<ProvidersPage/>}/>}
                    </>
                }
                <Route path="/profile" element={<ProfilePage/>}/>
            </Routes>
        </BaseLayout>
    );
}

export default App;
