import { iUseSecurityLog, SecurityLogFilter, ShowSecurityLogResponse } from "./SecurityLogModels";
import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import Config from "../../config";
import {
    setCurrentSecurityLogPage,
    setSecurityLog,
    setSecurityLogFilter,
    setSecurityLogLoading, setSecurityLogPageSize, setTotalSecurityLogRecords
} from "./securityLogSlice";
import { convertUnixToCompactLocaleDate } from "../common_funcs";

export const useSecurityLog = (): iUseSecurityLog => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()

    const securityLogState = useAppSelector(state => state.securityLog)

    const getSecurityLog = useCallback((page: number, itemsPerPage: number = 30, filter: SecurityLogFilter | null = null) => {
        let filterForSending = securityLogState.filter;
        if (filter) {
            if (
                filter.event !== securityLogState.filter.event ||
                filter.toTime !== securityLogState.filter.toTime ||
                filter.fromTime !== securityLogState.filter.fromTime ||
                filter.username !== securityLogState.filter.username ||
                filter.orderBy !== securityLogState.filter.orderBy
            ) {
                dispatch(setSecurityLogFilter(filter))
                filterForSending = filter
            }
        }
        let itemsPerPageToSend = securityLogState.pageSize
        if (itemsPerPage !== securityLogState.pageSize) {
            dispatch(setSecurityLogPageSize(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = securityLogState.currentPage
        if (page !== securityLogState.currentPage) {
            dispatch(setCurrentSecurityLogPage(page))
            pageToSend = page
        }
        if (token) {
            dispatch(setSecurityLogLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowLogs')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }
            if (filterForSending.event !== null) {
                data.append('event', filterForSending.event)
            }
            if (filterForSending.orderBy !== null) {
                data.append('orderby', filterForSending.orderBy)
            }

            axios.post<ShowSecurityLogResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: securityLogList, total_logs } = response.data
                    if (total_logs !== undefined) {
                        dispatch(setTotalSecurityLogRecords(total_logs))
                    }
                    if (success) {
                        dispatch(setCurrentSecurityLogPage(page))
                        if (securityLogList) {
                            securityLogList.forEach(log => {
                                log.timestamp = convertUnixToCompactLocaleDate(log.timestamp_unix)
                                log.request_json = log.request_json !== null ? JSON.stringify(log.request_json) : null
                                log.response_json = log.response_json !== null ? JSON.stringify(log.response_json) : null
                            })
                            dispatch(setSecurityLog(securityLogList))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setSecurityLog([]))
                            if (total_logs && total_logs > 0) {
                                setLocalizedError(error)
                            }
                        }
                        else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setSecurityLog([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setSecurityLogLoading(false))
                })
        }
    }, [dispatch, handleNetworkErrors, securityLogState, setLocalizedError, token])

    return {
        ...securityLogState,
        getSecurityLog
    }
}