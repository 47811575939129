import { FC, useEffect, useState } from "react";
import { Box, Button, Card } from "@mui/material";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { BonusConstructorDialog } from "../../components/bonus/BonusConstructorDialog";
import { useLanguage } from "../../features/localisation/useLanguage";
import { BonusList } from "../../components/bonus/BonusList";
import { useBonusAccess } from "../../features/bonus/useBonusAccess";
import { useAppDispatch } from "../../store";
import { clearBonusSlice } from "../../features/bonus/BonusSlice";

export const BonusPage: FC = () => {
    const dispatch = useAppDispatch()
    const { token } = useUser()
    const { hasAccess } = useBonusAccess()
    const { languagePack: { pack: { bonusConstructor: lang } } } = useLanguage()

    const [ showBonusCreator, setShowBonusCreator ] = useState<boolean>(false)
    
    useEffect(() => {
        return () => {
            dispatch(clearBonusSlice())
        }
    }, [dispatch])

    return token ? <>
        {hasAccess && <Card style={{ margin: 10, padding: 10, height: '100%' }}>
            <Box display={'flex'} flexDirection={'column'}>
                <Button variant={'contained'} onClick={() => setShowBonusCreator(true)}>{lang.createBonusBTN}</Button>

                <BonusList height={'calc(100vh - 170px)'}/>
            </Box>
        </Card>}

        {showBonusCreator && <BonusConstructorDialog close={() => setShowBonusCreator(false)}/>}
    </> : <MustBeLogged/>
}