import { FC, InputHTMLAttributes, Ref } from "react";
import { TextField } from "@mui/material";
import { convertDateToStringDate } from "../../../features/common_funcs";

interface CustomInputProps {
    value: string | number | Date | readonly (string | number | Date)[] | undefined;
    // defaultValue: string | number | Date | readonly (string | number | Date)[] | undefined;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    inputRef: Ref<HTMLInputElement>;
    helper?: string
    placeholder?: string
}

export const CustomInput: FC<CustomInputProps> = ({ helper, placeholder, value, inputRef, ...props }) => {

    return <TextField
        fullWidth
        helperText={helper}
        placeholder={placeholder}
        value={value instanceof Date ? convertDateToStringDate(value) : ''}
        inputProps={props.inputProps}
        inputRef={inputRef}
        size={'small'}
    />;
};

// function formatDate(date: Date): string {
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear().toString();
//     return `${day}-${month}-${year}`;
// }