import { RoyaltyState } from "./royaltySlice";
import { ApiResponse } from "../apiResponseModels";
import { UserRole } from "../user/user";

export interface RoyaltyObj {
    uniqueLocalID: string // Generated by UID after loading from server
    provider: string
    section: string
    currency: string
    user_losses: number
    user_wins: number
    ggr: number
}

export interface RoyaltyFilter {
    fromTime: number|null
    toTime: number|null
    orderBy: RoyaltySort|null
}

export interface iUseRoyalty extends RoyaltyState {
    getRoyaltyStatistics: (filter?: RoyaltyFilter|null) => void
}

export interface ShowRoyaltyStatisticsResponse extends ApiResponse {
    data?: RoyaltyObj[]
}

export interface iUseRoyaltyStatisticsAccess {
    hasAccess: boolean
}

export const RoyaltyStatisticsAccess = [
    UserRole.PLATFORM,
    UserRole.OWNER
]

export enum RoyaltySort {
    Provider = 'provider',
    Section = 'section',
    Currency = 'currency',
    UserLoss = 'user_losses',
    UserWins = 'user_wins',
    GGR = 'ggr'
}

export const stringToRoyaltySort = (stringRoyaltySort: string): RoyaltySort => {
    switch (stringRoyaltySort) {
        case 'provider':
            return RoyaltySort.Provider
        case 'section':
            return RoyaltySort.Section
        case 'currency':
            return RoyaltySort.Currency
        case 'user_losses':
            return RoyaltySort.UserLoss
        case 'user_wins':
            return RoyaltySort.UserWins
        case 'ggr':
        default:
            return RoyaltySort.GGR
    }
}