import { FC, useEffect, useState } from "react";
import { useManageUsers } from "../../features/manage_users/useManageUsers";
import { FormControl, InputLabel, Select, Skeleton } from "@mui/material";

interface UserRolesSelectorProps {
    value?: string
    onSelect?: (role: string) => void
    required?: boolean
}

export const UserRolesSelector: FC<UserRolesSelectorProps> = ({ value = '', onSelect = () => {}, required = false}) => {
    const { isNextAvailableUserLevelsLoading, getNextAvailableUserLevels, nextAvailableUserLevels } = useManageUsers()
    const [initLoad, setInitLoad] = useState<boolean>(true)

    useEffect(() => {
        if(initLoad && !isNextAvailableUserLevelsLoading && nextAvailableUserLevels === null) {
            getNextAvailableUserLevels()
            setInitLoad(false)
        }
    }, [getNextAvailableUserLevels, initLoad, isNextAvailableUserLevelsLoading, nextAvailableUserLevels])

    return isNextAvailableUserLevelsLoading ? <Skeleton animation={'wave'} height={'40px'}/> :
        <FormControl size={'small'} fullWidth required={required}>
            <InputLabel id="role-selector-label">User role</InputLabel>
            <Select
                required={required}
                labelId="role-selector-label"
                value={value}
                onChange={e => onSelect(e.target.value)}
                defaultValue={''}
                label={'User role'}
            >
                {nextAvailableUserLevels?.map((role) => <option key={role} value={role}>{role}</option>)}
            </Select>
        </FormControl>
}