import { iUseProviders, ProviderLoader, ProviderType, ShowProvidersResponse } from "./ProviderModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLanguage } from "../localisation/useLanguage";
import { useUser } from "../user/useUser";
import { useCallback } from "react";
import { setProviders, setProvidersLoading, updateProviderContractorID } from "./ProviderSlice";
import axios from "axios";
import Config from "../../config";
import { ApiResponse } from "../apiResponseModels";

export const useProviders = (): iUseProviders => {
    const dispatch = useAppDispatch();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();
    const state = useAppSelector(state => state.providers);
    const { token } = useUser()

    const getProviders = useCallback((providerType: ProviderType) => {
        if (token) {
            dispatch(setProvidersLoading({
                loader: ProviderLoader.isProvidersLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetProviders')
            data.append('token', token)
            data.append('type', providerType)

            axios.post<ShowProvidersResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, providers } = response.data
                    if (success) {
                        if (providers) {
                            dispatch(setProviders(providers))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setProviders([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(error => {
                    dispatch(setProviders([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setProvidersLoading({
                        loader: ProviderLoader.isProvidersLoading,
                        isLoading: false
                    }))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const updateProviderContractor = useCallback((providerID: number, contractorID: number, onSuccess = () => {}) => {
        if (token) {
            dispatch(setProvidersLoading({
                loader: ProviderLoader.isProviderContractorUpdateLoading,
                isLoading: true
            }))

            const data = new FormData();
            data.append('action', 'SetContractor')
            data.append('token', token)
            data.append('provider_id', providerID.toString())
            data.append('contractor_id', contractorID.toString())
            
            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(updateProviderContractorID({
                            providerID,
                            contractorID
                        }))
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(error => {
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setProvidersLoading({
                        loader: ProviderLoader.isProviderContractorUpdateLoading,
                        isLoading: false
                    }))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    return { ...state, getProviders, updateProviderContractor}
}