import { FC } from "react";
import { MoneyPack } from "../../features/finances/FinancesModels";
import { useLogic } from "./useLogic";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { MoneyInput } from "../commons/money_input/MoneyInput";
import { useLanguage } from "../../features/localisation/useLanguage";

interface CoinsPackConstructorProps {
    onDone?: () => void
    coinPackEditing?: MoneyPack
}

export const CoinsPackConstructor: FC<CoinsPackConstructorProps> = ({ onDone, coinPackEditing}) => {
    const logic = useLogic(coinPackEditing)
    const { languagePack: {pack: {common: langCommon, coinsPackCreator: lang}}} = useLanguage()

    return <Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'14px'}
    >
        <Typography variant={'h2'} fontSize={'35px'}>
            {lang.coinsPackCreatorTitle}
        </Typography>
        <Stack rowGap={'14px'}>
            <MoneyInput
                required
                label={lang.coinsLabel}
                value={logic.coins}
                onValueChanged={logic.setCoins}
                error={logic.coinsEmpty}
                helperText={lang.coinsHelper}
            />

            <MoneyInput
                required
                label={lang.priceLabel}
                value={logic.price}
                onValueChanged={logic.setPrice}
                error={logic.priceEmpty}
                helperText={lang.priceHelper}
            />

            <MoneyInput
                required
                label={lang.freeCoinsLabel}
                value={logic.freeCoins}
                onValueChanged={logic.setFreeCoins}
                error={logic.freeCoinsEmpty}
                helperText={lang.freeCoinsHelper}
            />

            <Box display={'flex'} justifyContent={'flex-end'} columnGap={'14px'}>
                <Button color={'secondary'} variant={'contained'} onClick={onDone}
                        // disabled={state.isBonusCreatingLoading}
                >
                    {langCommon.cancelBTN}
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => logic.handleSaveClick(onDone)}
                    disabled={logic.isUpdatingCoinPackLoading || logic.isCreatingCoinPackLoading}
                    endIcon={(logic.isUpdatingCoinPackLoading || logic.isCreatingCoinPackLoading) && <CircularProgress size={16} sx={{ color: "inherit" }}
                    />}
                >
                    {lang.savePackBTN}
                </Button>
            </Box>
        </Stack>
    </Box>
}