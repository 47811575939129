import React, { FC } from "react";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { Box, Card, Stack } from "@mui/material";
import { Paginator } from "../../components/commons/paginator/Paginator";
import { PageSizeSwitcher } from "../../components/commons/pageSizeSwitcher/PageSizeSwitcher";
import { GameHistoryTable } from "./game_history_table/GameHistoryTable";
import { useGameHistory } from "../../features/game_history/useGameHistory";
import { TimeFilterPanel } from "../../components/commons/time_filter/TimeFilterPanel";
import { UsernameFilter } from "../../components/commons/username_filter/UsernameFilter";

export const GameHistoryPage: FC = () => {
    const { token } = useUser()
    const { totalPages, currentPage, pageSize, filter } = useGameHistory()

    return token ? <>
        <Card style={{ margin: 10 }}>
            <Stack direction={'column'}>
                <Box display={'flex'} alignItems={'center'}>
                <TimeFilterPanel fromTime={filter.fromTime} toTime={filter.toTime} pullParamsToAddressBar/>
                    <UsernameFilter username={filter.username} pullParamsToAddressBar/>
                </Box>
                <GameHistoryTable height={'calc(100vh - 234px)'}/>
                <Box padding={'12px'} display={'flex'} justifyContent={'flex-end'}>
                    <Paginator totalPages={totalPages} currentPage={currentPage}/>
                    <Box flexGrow={1}/>
                    <PageSizeSwitcher pageSize={pageSize}/>
                </Box>
            </Stack>
        </Card>
    </> : <MustBeLogged/>
}