import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
    convertCentsToCurrencyString,
    currencyConvert
} from "../../../features/common_funcs";
import { CurrencyRender } from "./CurrencyRender";
import { useUser } from "../../../features/user/useUser";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";

export const Balance: FC = () => {
    const { displayCurrency, exchangeRate, user } = useUser()
    const { currencies } = useManageUsers()
    const [ balance, setBalance ] = useState<number | undefined>()

    useEffect(() => {
        let convertedBalance: number | undefined
        if (user) {
            if (currencies !== null && displayCurrency) {
                const userCurrency = user.user_currency
                if (userCurrency) {
                    const foundUserCurrency = currencies.find(cur => cur.currency_name === userCurrency)
                    if (foundUserCurrency) {
                        convertedBalance = currencyConvert(user.user_balance, foundUserCurrency.exchange_rate, exchangeRate)
                    }
                }
            }
        }
        setBalance(convertedBalance)
    }, [ currencies, displayCurrency, exchangeRate, user ])

    return <Box display={'flex'} alignItems={'center'} gap={'15px'}>
        <Typography>
            <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                {balance !== undefined ? convertCentsToCurrencyString(balance) : '--.--'} {displayCurrency &&
                <CurrencyRender currency={displayCurrency}/>}
            </Box>
        </Typography>
    </Box>
}


