import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";
import { useMoneyTransfers } from "./useMoneyTransfers";
import { MoneyTransferFilter, stringToMoneyTransfersSort } from "./MoneyTransfersModels";

export const useMoneyTransfersWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const {
        moneyTransfers,
        getMoneyTransfers,
        isMoneyTransfersLoading,
        currentPage,
        pageSize,
        filter
    } = useMoneyTransfers()

    useEffect(() => {
        if (!isMoneyTransfersLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const qOrderBy = searchParams.get('orderBy')

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const usernameHasChanges = qUsername !== filter.username
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const moneyTransfersNotLoadedYet = moneyTransfers === null

            if (pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                || usernameHasChanges
                || orderByHasChanges 
                || moneyTransfersNotLoadedYet) {
                console.group('Money transfers changes')
                console.log('pageHasChanges', pageHasChanges)
                console.log('pageSizeHasChanges', pageSizeHasChanges)
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                console.log('usernameHasChanges', usernameHasChanges)
                console.log('orderByHasChanges', orderByHasChanges)
                console.log('moneyTransfersNotLoadedYet', moneyTransfersNotLoadedYet)
                console.groupEnd()

                const filterNew: MoneyTransferFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    username: qUsername,
                    orderBy: qOrderBy !== null ? stringToMoneyTransfersSort(qOrderBy) : null
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize

                getMoneyTransfers(pageNew, itemsPerPageNew, filterNew)
            }
        }
    }, [ currentPage, disabled, filter.fromTime, filter.orderBy, filter.toTime, filter.username, getMoneyTransfers, isMoneyTransfersLoading, location.search, moneyTransfers, pageSize ])
}