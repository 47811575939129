import { User } from "./user";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import axios from "axios";
import Config from "../../config";
import { ApiResponse } from "../apiResponseModels";
import {
    clearCurrency,
    clearToken,
    clearUser,
    setChangePasswordLoading, setDisplayCurrency,
    setLogInLoading,
    setToken,
    setUser,
    setUserInfoUpdateLoading
} from "./userSlice";
import {
    GetBalanceResponse,
    iUseLogin,
    LoginResponse,
    UserInfoResponse
} from "./useUserInterfaces";
import { useLanguage } from "../localisation/useLanguage";


export const useUser = (): iUseLogin => {
    const dispatch = useAppDispatch()
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const userState = useAppSelector(state => state.user)
    const { token, user} = userState

    const logIn = useCallback((username: string, password: string) => {
        axios.create({ ...Config.axiosConfig })

        const data = new FormData();
        data.append('action', 'Login')
        data.append('username', username)
        data.append('password', password)

        dispatch(setLogInLoading(true))

        axios.post<LoginResponse>(Config.apiHost, data)
            .then(response => {
                const { success, error, token, user } = response.data
                if (success) {
                    if (user) {
                        dispatch(setUser(user))
                        if (user.user_currency) {
                            dispatch(setDisplayCurrency(user.user_currency))
                            // TODO set exchange rate
                        }
                    }
                    if (token) {
                        dispatch(setToken(token))
                    }
                }
                if (error) {
                    setLocalizedError(error)
                }
            })
            .catch(handleNetworkErrors)
            .finally(() => {
                dispatch(setLogInLoading(false))
            })
    }, [ dispatch, handleNetworkErrors, setLocalizedError])

    const updateUserInfo = useCallback((newUserInfo: User) => {
        if (token && user) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateUserInformation')
            data.append('token', token)
            if ((newUserInfo.user_email && newUserInfo.user_email.trim() !== '') || user.user_email) data.append('useremail', newUserInfo.user_email ?? user.user_email ?? '')
            data.append('userphone', newUserInfo.user_phone ?? '')
            data.append('userfirstname', newUserInfo.user_firstname ?? '')
            data.append('userlastname', newUserInfo.user_lastname ?? '')

            dispatch(setUserInfoUpdateLoading(true))

            axios.post<UserInfoResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setUserInfoUpdateLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const logOut = useCallback(() => {
        if (token) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'logout')
            data.append('token', token)

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(clearUser())
                        dispatch(clearToken())
                        dispatch(clearCurrency())
                        window.location.reload()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
        }
    }, [ token, handleNetworkErrors, dispatch, setLocalizedError])

    const changePassword = useCallback((oldPassword: string, newPassword: string, onSuccess: () => void = () => {}) => {
        if (token) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ChangePassword')
            data.append('token', token)

            data.append('password', oldPassword)
            data.append('newpassword', newPassword)

            dispatch(setChangePasswordLoading(true))

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setChangePasswordLoading(false))
                })
        }
    }, [ token, dispatch, handleNetworkErrors, setLocalizedError])

    const refreshUserInfo = useCallback(() => {
        if (token && user) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowOneUser')
            data.append('token', token)
            data.append('userid', user.user_id.toString())

            axios.post<UserInfoResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const refreshUserBalance = useCallback(() => {
        if (token && user) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetBalance')
            data.append('token', token)

            axios.post<GetBalanceResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, balance } = response.data
                    if (success) {
                        if (balance) {
                            const updatedUser: User = {
                                ...user,
                                user_balance: balance
                            }
                            dispatch(setUser(updatedUser))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token, user])

    return {
        ...userState,
        logIn,
        updateUserInfo,
        refreshUserInfo,
        refreshUserBalance,
        logOut,
        changePassword
    }
}