import { useLocation } from "react-router-dom";
import { useManageUsers } from "./useManageUsers";
import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";
import { ManageUsersFilter, ShowUsersMode, stringToManageUsersSort } from "./manageUsersModels";

export const useUsersWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const {
        usersList,
        pageSize,
        currentPage,
        loadManageUsersList,
        isUsersListLoading,
        filter,
        mode
    } = useManageUsers()

    useEffect(() => {
        if (!isUsersListLoading && !disabled && mode === ShowUsersMode.List) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            // const qUsername = searchParams.get('username')
            const qOrderBy = searchParams.get('orderBy')

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            // const usernameHasChanges = qUsername !== filter.username
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const usersListNotLoadedYet = usersList === null

            if (pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                // || usernameHasChanges
                || orderByHasChanges
                || usersListNotLoadedYet) {
                console.group('Changes')
                console.log('pageHasChanges', pageHasChanges)
                console.log('pageSizeHasChanges', pageSizeHasChanges)
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                // console.log('usernameHasChanges', usernameHasChanges)
                console.log('orderByHasChanges', orderByHasChanges)
                console.log('usersListNotLoadedYet', usersListNotLoadedYet)
                console.groupEnd()

                const filterNew: ManageUsersFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    // username: qUsername,
                    orderBy: qOrderBy !== null ? stringToManageUsersSort(qOrderBy) : null
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize

                loadManageUsersList(pageNew, itemsPerPageNew, filterNew)
            }
        }
    }, [ mode, currentPage, disabled, filter.fromTime, filter.orderBy, filter.toTime, isUsersListLoading, loadManageUsersList, location.search, pageSize, usersList ])
}