import { FC, useCallback, useEffect, useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { validateEmail } from "../../../features/common_funcs";
import { useLanguage } from "../../../features/localisation/useLanguage";
import s from './CreateUser.module.css'
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import { User } from "../../../features/user/user";
import CurrencySwitcher from "../../../components/manage_users/CurrencySwitcher";
import { UserRolesSelector } from "../../../components/manage_users/UserRolesSelector";

interface CreateUserProps {
    onDone: () => void
}

export const CreateUser: FC<CreateUserProps> = ({onDone = () => {}}) => {
    const { languagePack: {pack: {createUser: createUserLang, userProfileForm: userProfileFormLang}}} = useLanguage()
    const { isUserCreating, createUser } = useManageUsers()
    const [ emailError, setEmailError ] = useState('');
    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ retypeNewPasswordError, setRetypeNewPasswordError ] = useState('');

    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ currency, setCurrency ] = useState<string>('')

    const [ newPassword, setNewPassword ] = useState<string | undefined>();
    const [ retypeNewPassword, setRetypeNewPassword ] = useState<string | undefined>();
    const [ userRole, setUserRole ] = useState<string>('')

    useEffect(() => {
        if ((newPassword !== undefined && newPassword.trim() === '') || (newPassword === undefined && retypeNewPassword)) {
            setNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
        } else {
            setNewPasswordError('')
        }

        if (((newPassword) && retypeNewPassword === undefined) || (retypeNewPassword !== undefined && retypeNewPassword.trim() === '')) {
            setRetypeNewPasswordError(userProfileFormLang.emptyFieldWarningMessage)
        } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
            setRetypeNewPasswordError(userProfileFormLang.passNotMatchWarningMessage)
        } else {
            setRetypeNewPasswordError('')
        }
    }, [ newPassword, retypeNewPassword, userProfileFormLang ])

    const isCreateButtonDisabled = useCallback(() => {
        let disabled = false
        if (!Boolean(username)) disabled = true
        if (!Boolean(email)) disabled = true
        if (!Boolean(newPassword)) disabled = true
        if (!Boolean(retypeNewPassword)) disabled = true
        if (emailError) disabled = true
        if (newPasswordError) disabled = true
        if (retypeNewPasswordError) disabled = true
        if (isUserCreating) disabled = true
        if (!Boolean(currency)) disabled = true
        if (!Boolean(userRole)) disabled = true
        return disabled
    }, [currency, email, emailError, isUserCreating, newPassword, newPasswordError, retypeNewPassword, retypeNewPasswordError, userRole, username])

    const handleCreateUser = () => {
        if (Boolean(username) && Boolean(email) && newPassword && Boolean(newPassword) && Boolean(userRole)) {
            const user: User = {
                banned: false,
                registration_time_unix: 0,
                user_balance: 0,
                user_currency: "EUR",
                user_email: email,
                user_id: -1,
                user_name: username,
                user_phone: phone,
                user_role: userRole
            }
            createUser(user, newPassword, onDone)
        }
    }

    return <div className={s.container}>
        <TextField
            required
            fullWidth
            disabled={isUserCreating}
            label={createUserLang.usernameLabel}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
            required
            fullWidth
            disabled={isUserCreating}
            label={userProfileFormLang.emailFieldLabel}
            type={'email'}
            value={email}
            onChange={(e) => {
                setEmail(e.target.value)
                const emailValidationResult = validateEmail(e.target.value, userProfileFormLang.emailIsRequiredMess, userProfileFormLang.notValidEmail);
                setEmailError(emailValidationResult);
            }}
            error={Boolean(emailError)}
            helperText={emailError}
        />
        <TextField
            fullWidth
            disabled={isUserCreating}
            label={userProfileFormLang.phoneFieldLabel}
            type={'tel'}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
        />

        <CurrencySwitcher required selectedCurrency={currency} onCurrencySelected={setCurrency}/>

        <UserRolesSelector required value={userRole} onSelect={setUserRole}/>

        <TextField
            required
            fullWidth
            disabled={isUserCreating}
            label={userProfileFormLang.newPassFieldLabel}
            type={'password'}
            value={Boolean(newPassword) ? newPassword : ''}
            error={Boolean(newPasswordError)}
            helperText={newPasswordError}
            onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
            required
            fullWidth
            disabled={isUserCreating}
            label={userProfileFormLang.retypePassFieldLabel}
            type={'password'}
            value={Boolean(retypeNewPassword) ? retypeNewPassword : ''}
            error={Boolean(retypeNewPasswordError)}
            helperText={retypeNewPasswordError}
            onChange={(e) => setRetypeNewPassword(e.target.value)}
        />

        <div className={s.controls}>
            <Button classes={{ contained: s.button, disabled: s.buttonDisabled }} variant={'contained'}
                    disabled={isCreateButtonDisabled()}
                    endIcon={isUserCreating ? <CircularProgress size={16}/> : <></>}
                    onClick={handleCreateUser}
            >
                {createUserLang.createBtn}
            </Button>
        </div>
    </div>
}