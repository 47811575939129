import { User } from "../user/user";
import { ApiResponse } from "../apiResponseModels";
import { ManageUsersState } from "./manageUsersSlice"

export interface UsersTreeNode extends User {
    childUsersIDs?: number[]
    parentId?: number|null
    childUsersCount?: number
}

export enum ShowUsersMode {
    List = 'list',
    Tree = 'tree'
}

export interface ManageUsersFilter {
    fromTime: number|null
    toTime: number|null
    orderBy: ManageUsersSort|null
}

export interface iUseManageUsers extends ManageUsersState {
    setShowUser: (user: User|null) => void
    switchUserListMode: (mode: ShowUsersMode) => void
    loadUserTreeNode: (parentID: number|null, onSuccess?: (hasChildren: boolean) => void) => void
    loadManageUsersList: (page: number, itemsPerPage?: number, filter?: ManageUsersFilter|null) => void
    banUser: (userID: number, ban: boolean) => void
    createUser: (user: User, password: string, onSuccess?: () => void) => void
    loadCurrencies: () => void
    sendDepositMoneyToUser: (amount: number, childID: number, currency: string, onSuccess?: () => void) => void
    withdrawMoneyFromUser: (amount: number, childID: number, onSuccess?: () => void) => void
    getNextAvailableUserLevels: () => void
}

export interface ShowUsersListResponse extends ApiResponse {
    data?: User[]
    total_users?: number
}

export interface ShowUsersTreeResponse extends ApiResponse {
    data?: UsersTreeNode[]
}

export interface BanUserSending {
    user_id: number
    banned: boolean
}

export interface BanUserResponse extends ApiResponse {
    user_id: number
    ban: boolean
}

export interface CreateUserResponse extends ApiResponse {
    user: User
}

export interface GetAvailableCurrenciesResponse extends ApiResponse {
    currencies?: CurrencyObj[]
}

export interface DepositMoneyResponse extends ApiResponse {
    sender_balance?: number
    child_balance?: number
}

export interface WithdrawMoneyResponse extends ApiResponse {
    child_balance?: number
}

export interface GetNextAvailableUserLevelsResponse extends  ApiResponse {
    levels?: string[]
}

export enum ManageUsersSort {
    Username = 'user_name',
    Active = 'banned',
    Firstname = 'user_firstname',
    Lastname = 'user_lastname',
    Balance = 'user_balance',
    Currency = 'user_currency',
    Role = 'user_role',
    Email = 'user_email',
    Phone = 'user_phone'
}

export const stringToManageUsersSort = (stringManageUsersSort: string): ManageUsersSort => {
    switch (stringManageUsersSort) {
        case 'banned':
            return ManageUsersSort.Active
        case 'user_firstname':
            return ManageUsersSort.Firstname
        case 'user_lastname':
            return ManageUsersSort.Lastname
        case 'user_balance':
            return ManageUsersSort.Balance
        case 'user_currency':
            return ManageUsersSort.Currency
        case 'user_role':
            return ManageUsersSort.Role
        case 'user_email':
            return ManageUsersSort.Email
        case 'user_phone':
            return ManageUsersSort.Phone
        case 'user_name':
        default:
            return ManageUsersSort.Username
    }
}

export interface CurrencyObj {
    currency_name: string
    exchange_rate: number
}

/*
*
* Принцип работы дерева юзеров
* -----------------------------
* Каждая нода этого дерева хранится в одномерном списке
* У каждой ноды есть список потомков и ID родителя
* таким образом отслеживается связь
* тот у кого parentId null - это начало дерева
*
* Для отображения дерева будет рекурсивный компонент UserNode
* Первый компонент ищет в сторе usersTreeList ноду, где parentId = null
* далее при отображении дочерних нод этот компонент фильтрует весь стор usersTreeList
* по user_id, который должен совпадать с юзером, переданным в эту ноду сверху
*
*
* */