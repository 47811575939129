import { FC, SyntheticEvent, useEffect, useState } from "react";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Avatar,
    Box, Button,
    Card, Divider,
    LinearProgress,
    Stack,
    Typography, useMediaQuery
} from "@mui/material";
import { useUser } from "../../features/user/useUser";
import { useLanguage } from "../../features/localisation/useLanguage";
import { usePaymentSystems } from "../../features/payment_system/usePaymentSystems";
import { useAppDispatch } from "../../store";
import { clearPaymentSystems } from "../../features/payment_system/paymentSystemsSlice";
import PaymentsIcon from '@mui/icons-material/Payments';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InfoWithLabel } from "../../components/commons/info_with_label/InfoWithLabel";
import { AddPaymentDialog } from "./add_payment/AddPaymentDialog";
import { PaymentAccount } from "../../features/payment_system/PaymentSystemModels";
import { EditPaymentDialog } from "./edit_payment/EditPaymentDialog";
import { UserRole } from "../../features/user/user";
import { DepositRateEditor } from "./deposite_rate/DepositRateEditor";

export const PaymentSystemsPage: FC = () => {
    const { token, userRole } = useUser()
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { paymentSystems: lang, common: langCommon } } } = useLanguage()
    const { isPaymentAccountsLoading, userPaymentAccounts, getUserPaymentAccounts } = usePaymentSystems()
    const [ expandedAccordion, setExpandedAccordion ] = useState<number | null>(null)
    const normalScreens = useMediaQuery('(min-width:700px)');
    const [ showAddPayment, setShowAddPayment ] = useState<boolean>(false)
    const [ showEditPayment, setShowEditPayment ] = useState<PaymentAccount|null>(null)

    useEffect(() => {
        return () => {
            dispatch(clearPaymentSystems())
        }
    }, [ dispatch ])

    useEffect(() => {
        getUserPaymentAccounts()
    }, [ getUserPaymentAccounts ])

    const handleChangeAccordion = (paymentId: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(!isExpanded ? null : paymentId)
    }

    return token ? <><Card style={{ margin: 10 }}>
        <Box padding={'10px'}>
            <Box display={'flex'} alignItems={'center'} padding={'10px'} flexDirection={'column'} rowGap={'16px'}>
                <Typography variant={'h4'} textTransform={'uppercase'}>
                    {lang.yourPaymentAccountsTitle}
                </Typography>
                <Stack direction={'column'} rowGap={'4px'} alignItems={'center'} width={normalScreens ? '640px' : '100%'}>
                    {isPaymentAccountsLoading && <LinearProgress sx={{width: '100%'}}/>}

                    {userPaymentAccounts && userPaymentAccounts.map(paymentAccount => <Accordion
                        key={`payment-account-accordion-id-${paymentAccount.id}`}
                        expanded={expandedAccordion === paymentAccount.id}
                        onChange={handleChangeAccordion(paymentAccount.id)}
                        sx={{ width: '100%' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Stack direction={'row'} alignItems={'center'} columnGap={'18px'}>
                                <Avatar
                                    alt={`${paymentAccount.method_name} payment method icon`}
                                    src={paymentAccount.pic_url}
                                    // src={'https://unidashboard.richbear.space' + paymentAccount.pic_url}
                                    sx={{ width: 52, height: 52 }}
                                >
                                    <PaymentsIcon fontSize={'small'}/>
                                </Avatar>
                                <Typography fontSize={18} fontWeight={'500'}>
                                    {paymentAccount.method_name}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack rowGap={'14px'}>
                                {paymentAccount.params.fields.map(field => <InfoWithLabel key={`payment-account-info-label-${field.label}`}
                                    label={field.label}>{field.value}</InfoWithLabel>)}
                            </Stack>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <Button color={'secondary'} onClick={() => setShowEditPayment(paymentAccount)}>
                                    {langCommon.editBTN}
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>)}

                    <Box paddingY={'10px'} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                        <Button variant={'contained'} onClick={() => setShowAddPayment(true)}>{lang.addPaymentMethodBTN}</Button>
                    </Box>


                    {userRole !== null && [UserRole.OWNER, UserRole.SUPERADMIN, UserRole.ADMIN].includes(userRole) && <>
                        <Divider sx={{width: '100%', marginY: '20px'}}/>
                        <Box width={normalScreens ? '350px' : '100%'}><DepositRateEditor/></Box>
                    </>}
                </Stack>
            </Box>
        </Box>
    </Card>

        {showAddPayment && <AddPaymentDialog close={() => setShowAddPayment(false)}/>}
        {showEditPayment && <EditPaymentDialog close={() => setShowEditPayment(null)} paymentAccount={showEditPayment}/>}
    </> : <MustBeLogged/>
}