import { FC, MouseEventHandler, useEffect, useState } from "react";
import { Chip } from "@mui/material";
import s from './UserRoleChip.module.css'
import { useLanguage } from "../../features/localisation/useLanguage";

interface UserRoleChipProps {
    userRole: string
    label?: string
    onClick?: MouseEventHandler<HTMLDivElement> | undefined
}

export const UserRoleChip: FC<UserRoleChipProps> = ({ userRole , label, onClick}) => {
    const { languagePack: {pack: {userRoles: lang}}} = useLanguage()
    const [ chipColor, setChipColor ] = useState<string>(s.default)
    const [ localisedUserRole, setLocalizedUserRole ] = useState<string>('')

    useEffect(() => {
        let color: string
        let lURole: string
        switch (userRole) {
            case 'platform':
                color = s.platform
                lURole = lang.platform
                break
            case 'owner':
                color = s.owner
                lURole = lang.owner
                break
            case 'superadmin':
                color = s.superadmin
                lURole = lang.superadmin
                break
            case 'admin':
                color = s.admin
                lURole = lang.admin
                break
            case 'cashier':
                color = s.cashier
                lURole = lang.cashier
                break
            case 'player':
                color = s.player
                lURole = lang.player
                break

            default:
                color = s.default
                lURole = userRole
        }

        setChipColor(color)
        setLocalizedUserRole(lURole)
    }, [ lang, userRole])

    return <Chip onClick={onClick} size={'small'} classes={{root: chipColor}} label={label ?? localisedUserRole}/>
}