import { UserRole } from "../user/user";
import { ApiResponse } from "../apiResponseModels";

// Provider's contractor interface
export interface Contractor {
    contractor_id: number
    contractor_name: string
    comission_rate: number
}

// Game provider interface
export interface Provider {
    id: number
    name: string
    title: string
    logo: string
    current_contractor_id: number
    contractor: Contractor[]
}

// Provider's slice state
export interface ProvidersState {
    providers: Provider[] | null
    isProvidersLoading: boolean
    isProviderContractorUpdateLoading: boolean
}

export enum ProviderLoader {
    isProvidersLoading,
    isProviderContractorUpdateLoading
}

export enum ProviderType {
    Slots = 'slots'
}

// Interface for useProviders hook
export interface iUseProviders extends ProvidersState {
    getProviders: (providerType: ProviderType) => void
    updateProviderContractor: (providerId: number, contractorId: number, onSuccess?: () => void) => void
}

// ShowProviders action response
export interface ShowProvidersResponse extends ApiResponse {
    providers?: Provider[]
}


// For access control in ProviderPage
export interface iUseProviderAccess {
    hasAccess: boolean
}

// For access control in ProviderPage
export const ProviderAccess = [
    UserRole.PLATFORM
]