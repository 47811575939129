import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { BonusConstructor } from "./BonusConstructor";
import { Bonus } from "../../features/bonus/bonusModels";

interface BonusConstructorDialogProps {
    close: () => void
    bonusEditing?: Bonus
}

export const BonusConstructorDialog: FC<BonusConstructorDialogProps> = ({close = () => {}, bonusEditing}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'md'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <BonusConstructor onDone={pendingClose} bonusEditing={bonusEditing}/>
        </DialogContent>
    </Dialog>
}