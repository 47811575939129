import { Box } from "@mui/material";
import { FC } from "react";
import ReactJson from 'react-json-view'

interface JSONViewerProps {
    jsonString: string
}

export const JSONViewer: FC<JSONViewerProps> = ({jsonString}) => {
    return <Box>
        <ReactJson
            src={JSON.parse(jsonString)}
            enableClipboard={false}
            displayObjectSize={false}
            displayDataTypes={false}
        />
    </Box>
}