import React, { FC, useEffect, useState } from "react";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { Box, Button, Card, FormControlLabel, Switch } from "@mui/material";
import { ManageUsersTable } from "./manage_users_table/ManageUsersTable";
import s from './UsersPage.module.css'
import { Paginator } from "../../components/commons/paginator/Paginator";
import { PageSizeSwitcher } from "../../components/commons/pageSizeSwitcher/PageSizeSwitcher";
import { useManageUsers } from "../../features/manage_users/useManageUsers";
import { ShowUsersMode } from "../../features/manage_users/manageUsersModels";
import { ManageUsersTree } from "./manage_users_tree/ManageUsersTree";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { CreateUserModal } from "./create_user/CreateUserModal";
import { TimeFilterPanel } from "../../components/commons/time_filter/TimeFilterPanel";
import { resetManageUsersSlice } from "../../features/manage_users/manageUsersSlice";
import { useAppDispatch } from "../../store";
import { useLanguage } from "../../features/localisation/useLanguage";

export const UsersPage: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {manageUsers: lang}}} = useLanguage()
    const { token } = useUser()
    const { totalPages, pageSize, currentPage, mode: userListMode, switchUserListMode, filter } = useManageUsers()
    const [ showCreateUser, setCreateUser ] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            dispatch(resetManageUsersSlice())
        }
    }, [ dispatch ])

    return token ? <>
        <div className={s.topPanel}>
            <Box display={'flex'} alignItems={'center'}>
                {userListMode === ShowUsersMode.List &&
                    <TimeFilterPanel fromTime={filter.fromTime} toTime={filter.toTime} pullParamsToAddressBar/>}
                <FormControlLabel
                    value="start"
                    control={<Switch checked={userListMode === ShowUsersMode.Tree} onChange={event => {
                        switchUserListMode(event.target.checked ? ShowUsersMode.Tree : ShowUsersMode.List)
                    }} color="primary"/>}
                    label={lang.usersTreeSwitcher}
                    labelPlacement="start"
                />
            </Box>
            <Button variant="outlined" startIcon={<PersonAddAltIcon />} onClick={() => {setCreateUser(true)}}>{lang.createUserBtn}</Button>
        </div>
        <Card style={{ margin: 10 }}>
            {userListMode === ShowUsersMode.Tree ? <div className={s.treeBox}>
                    <ManageUsersTree/>
                </div>

                :

                <div className={s.column}>
                    <ManageUsersTable height={'calc(100vh - 234px)'}/>
                    <div className={s.bottomBar}>
                        <Paginator totalPages={totalPages} currentPage={currentPage}/>
                        <span className={s.flexGrow}/>
                        <PageSizeSwitcher pageSize={pageSize}/>
                    </div>
                </div>}
        </Card>

        { showCreateUser && <CreateUserModal close={() => setCreateUser(false)}/>}
    </> : <MustBeLogged/>
}