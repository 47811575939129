import { FC, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { JSONViewer } from "./JSONViewer";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface JSONViewerDialogProps {
    close: () => void
    jsonString: string
}

export const JSONViewerDialog: FC<JSONViewerDialogProps> = ({close = () => {}, jsonString}) => {
    const [ opened, setOpened ] = useState(true)
    const { languagePack: {pack: {common: lang}}} = useLanguage()

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'sm'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <JSONViewer jsonString={jsonString}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => pendingClose()}>{lang.closeBTN}</Button>
        </DialogActions>
    </Dialog>
}