import React, { FC, useEffect, useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import s from './UserNode.module.css';
import { NodeChild } from "./NodeChild";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import { UsersTreeNode } from "../../../features/manage_users/manageUsersModels";
import { chooseName } from "../../../features/user/userFuncs";
import { CircularProgress, Tooltip } from "@mui/material";
import { UserRoleChip } from "../../../components/manage_users/UserRoleChip";

interface UserNodeProps {
    isRoot?: boolean;
    userNodeObj?: UsersTreeNode | null;
}

export const UserNode: FC<UserNodeProps> = ({ isRoot = false, userNodeObj = null }) => {
    const [initLoad, setInitLoad] = useState<boolean>(true);
    // const [childNodes, setChildNodes] = useState<UsersTreeNode[]>([]);
    const { loadUserTreeNode, usersTreeList, userNodeIdLoading, setShowUser } = useManageUsers();
    const [branchExpanded, setBranchExpanded] = useState<boolean>(false);
    const [hasChildren, setHasChildren] = useState<boolean>(true);
    const [ rootIsLoading, setRootIsLoading ] = useState<boolean>(false)

    useEffect(() => {
        // Only for the root node mode
        if (initLoad && isRoot) {
            // If the component is loaded for the first time and it's the root
            setBranchExpanded(true); // Expand the branch (always for the root)
            setInitLoad(false);
            loadUserTreeNode(null, () => setRootIsLoading(false)); // Load child nodes
            setRootIsLoading(true)
        }
    }, [initLoad, isRoot, loadUserTreeNode, usersTreeList]);

    const childNodesNew = isRoot ? usersTreeList.filter(node => !Boolean(node.parentId)) : userNodeObj ? usersTreeList.filter(node => node.parentId === userNodeObj.user_id) : []

    // useEffect(() => {
    //     // Here we load child nodes from the store for the current component
    //
    //     // If the local list is empty, and the store list is not empty, and it's the root node
    //     if (childNodes.length === 0 && usersTreeList.length && isRoot) {
    //         // Find child nodes in the store for the current node
    //         const nodes = usersTreeList.filter(node => !Boolean(node.parentId));
    //         // If found, load them locally
    //         if (nodes.length) {
    //             setChildNodes(nodes);
    //         }
    //     }
    //
    //     // If the local list is empty, and the store list is not empty, and a node was passed to this component,
    //     // and presumably this node may have child nodes
    //     if (childNodes.length === 0 && Boolean(usersTreeList.length) && userNodeObj && hasChildren) {
    //         // Find child nodes in the store for the current node
    //         const nodes = usersTreeList.filter(node => node.parentId === userNodeObj.user_id);
    //         // If found, load them
    //         if (nodes.length) {
    //             setChildNodes(nodes);
    //         }
    //     }
    // }, [childNodes, hasChildren, isRoot, userNodeObj, usersTreeList]);

    return (
        <div>
            {isRoot ? (
                <div className={s.titleRow}>{rootIsLoading ? <CircularProgress size={18} /> : <PanoramaFishEyeIcon/>}<PersonPinIcon /></div>
            ) : (
                <div className={s.titleRow}>
                    {userNodeObj && userNodeIdLoading === userNodeObj.user_id ? (
                        <CircularProgress size={18} />
                    ) : !branchExpanded ? (
                        <AddCircleOutlineIcon
                            onClick={() => {
                                if (userNodeObj) {
                                    // If child nodes exist, clicking just expands the branch
                                    if (childNodesNew.length) {
                                        setBranchExpanded(true);
                                    }
                                    // If there are no child nodes, load them from the server on click
                                    else {
                                        loadUserTreeNode(userNodeObj.user_id, (hasChildren) => {
                                            setBranchExpanded(true);
                                            setHasChildren(hasChildren);
                                        });
                                    }
                                }
                            }}
                        />
                    ) : hasChildren && (
                        <RemoveCircleOutlineIcon onClick={() => setBranchExpanded(false)} />
                    )}
                    {userNodeObj && <Tooltip title={userNodeObj.user_role} placement="right"><div><UserRoleChip onClick={() => {setShowUser(userNodeObj)}} userRole={userNodeObj.user_role} label={chooseName(userNodeObj)}/></div></Tooltip>}
                </div>
            )}
            <div>
                {branchExpanded &&
                    childNodesNew.map((node) => (
                        <NodeChild key={'node-' + node.user_id}>
                            <UserNode userNodeObj={node} />
                        </NodeChild>
                    ))}
            </div>
        </div>
    );
};
