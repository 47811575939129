import React, { FC, ReactNode, useState } from "react";
import s from './BaseLayout.module.css'
import { SideMenu } from "./side_menu/SideMenu";
import { Header } from "./header/Header";
import { Hidden, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

interface BaseLayoutProps {
    children?: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const [ isDrawerOpen, setDrawerOpen ] = useState(false);

    // Function to toggle the state of the drawer (open/close)
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return <div className={s.container}>
        {/* Side menu visible only on large screens and up */}
        <Hidden lgDown>
            <div className={s.menuZone}>
                <SideMenu/>
            </div>
        </Hidden>


        {/* Drawer for side menu visible only on screens smaller than large */}
        <Hidden lgUp>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <SideMenu close={() => {
                    setDrawerOpen(false)
                }}/>
            </Drawer>
        </Hidden>

        <div className={s.contentZone}>
            <main className={s.mainSection}>
                <div className={s.topBar}>
                    {/* Mobile zone with burger menu icon visible only on screens smaller than large */}
                    <Hidden lgUp>
                        <div className={s.mobileZone}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </div>
                    </Hidden>

                    <div className={s.headerZone}>
                        {/* Header component */}
                        <Header/>
                    </div>
                </div>

                <div className={s.content}>
                    {/* Content section where children components are rendered */}
                    {children}
                </div>
            </main>
        </div>
    </div>
}
