import { FC } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { MoneyInput } from "../commons/money_input/MoneyInput";
import { useValues } from "./useValues";
import { SelectDate } from "../commons/fields/SelectDate";
import { PromoConditionSelector } from "./PromoConditionSelector";
import Grid from '@mui/material/Unstable_Grid2'
import { useLanguage } from "../../features/localisation/useLanguage";
import { Bonus } from "../../features/bonus/bonusModels";

interface BonusConstructorProps {
    onDone?: () => void
    bonusEditing?: Bonus
}

export const BonusConstructor: FC<BonusConstructorProps> = ({ onDone = () => {}, bonusEditing}) => {
    const { languagePack: {pack: {bonusConstructor: lang, common: langCommon}}} = useLanguage()
    const state = useValues(bonusEditing)

    return <Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'14px'}
    >
        <Typography variant={'h2'} fontSize={'35px'}>{lang.bonusConstructorTitle}</Typography>
        <Grid container spacing={2}>
            <Grid xs={12} sm={12}>
                <TextField
                    fullWidth
                    required
                    size={'small'}
                    label={lang.promoCodeLabel}
                    value={state.promocode}
                    onChange={state.changePromocode}
                    error={state.promoEmpty}
                    helperText={lang.promoCodeHelper}
                />
            </Grid>


            <Grid xs={12} sm={6}>
                <MoneyInput
                    required
                    onValueChanged={state.setConditionDeposit}
                    value={state.conditionDeposit}
                    label={lang.conditionDepositLabel}
                    error={state.conditionDepositEmpty}
                    helperText={lang.conditionDepositHelper}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField
                    fullWidth
                    required
                    size={'small'}
                    label={lang.wagerLabel}
                    type={'number'}
                    error={state.wagerEmpty}
                    value={state.wager === null ? '' : state.wager.toString()}
                    onChange={state.changeWager}
                    helperText={lang.wagerHelper}
                />
            </Grid>


            <Grid xs={12} sm={6}>
                <TextField
                    fullWidth
                    required
                    size={'small'}
                    label={lang.daysValidLabel}
                    type={'number'}
                    error={state.daysValidEmpty}
                    value={state.daysValid === null ? '' : state.daysValid.toString()}
                    onChange={state.changeDaysValid}
                    helperText={lang.daysValidHelper}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <MoneyInput
                    onValueChanged={state.setBonusAmount}
                    value={state.bonusAmount}
                    label={lang.bonusAmountLabel}
                    helperText={lang.bonusAmountHelper}
                />
            </Grid>


            <Grid xs={12} sm={6}>
                <SelectDate
                    helper={lang.startPromoDateLabel}
                    value={state.startPromoDate}
                    onChange={state.setStartPromoDate}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <SelectDate
                    helper={lang.endPromoDateLabel}
                    value={state.endPromoDate}
                    onChange={state.setEndPromoDate}
                />
            </Grid>


            <Grid xs={12} sm={6}>
                <PromoConditionSelector
                    selectedCondition={state.additionalCondition}
                    onConditionChanged={state.setAdditionalCondition}
                    helperText={lang.additionalConditionHelper}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField
                    fullWidth
                    size={'small'}
                    label={lang.bonusPercentageLabel}
                    type={'number'}
                    helperText={lang.bonusPercentageHelper}
                    value={state.bonusPercentage || ''}
                    onChange={state.changeBonusPercentage}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField
                    fullWidth
                    size={'small'}
                    label={lang.freeSpinsLabel}
                    type={'number'}
                    helperText={lang.freeSpinsHelper}
                    value={state.freeSpins || ''}
                    onChange={state.changeFreeSpins}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <MoneyInput
                    onValueChanged={state.setBonusMax}
                    value={state.bonusMax}
                    label={lang.bonusMaxLabel}
                    helperText={lang.bonusMaxHelper}
                />
            </Grid>

        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'} columnGap={'14px'}>
            <Button color={'secondary'} variant={'contained'} onClick={onDone}
                    disabled={state.isBonusCreatingLoading}>{langCommon.cancelBTN}</Button>
            <Button
                variant={'contained'}
                onClick={() => state.handleSaveBonusClick(onDone)}
                disabled={state.isBonusCreatingLoading}
                endIcon={state.isBonusCreatingLoading && <CircularProgress size={16} sx={{ color: "inherit" }}/>}
            >
                {lang.saveBonusBTN}
            </Button>
        </Box>
    </Box>
}