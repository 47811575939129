import { User, UserRole } from "../user/user";

const parseUserRole = (stringRole: string|undefined) : UserRole|null => {
    if (stringRole) {
        switch (stringRole.toLowerCase()) {
            case 'platform':
                return UserRole.PLATFORM
            case 'owner':
                return UserRole.OWNER
            case 'superadmin':
                return UserRole.SUPERADMIN
            case 'admin':
                return UserRole.ADMIN
            case 'cashier':
                return UserRole.CASHIER
            case 'player':
                return UserRole.PLAYER
            default:
                return null
        }
    } else return null
}

export const isUserUnderYourRole = (currentUser: User, childUser: User): boolean => {
    const currentUserRole = parseUserRole(currentUser.user_role)
    const childUserRole = parseUserRole(childUser.user_role)
    if (currentUserRole !== null && childUserRole !== null) {
        return (childUserRole - 1) === currentUserRole
    }
    return false
}