import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserRole } from "./user";
import UserStorage from "../../localStorages/userStorage";
import { stringToUserRole } from "./userFuncs";


const getUserRoleFromStore = (): UserRole | null => {
    const user = UserStorage.getUser()
    if (user) {
        return  stringToUserRole(user.user_role)
    }
    return null
}

export interface UserState {
    token: string | null
    user: User | null
    userRole: UserRole | null
    displayCurrency: string
    exchangeRate: number
    isLogInLoading: boolean
    isUserInfoUpdateLoading: boolean
    isChangePasswordLoading: boolean
}

const initialState: UserState = {
    token: UserStorage.getToken(),
    user: UserStorage.getUser(),
    userRole: getUserRoleFromStore(),
    displayCurrency: UserStorage.getCurrency() ?? UserStorage.getUser()?.user_currency ?? '',
    exchangeRate: UserStorage.getExchangeRate() ?? 1,
    isLogInLoading: false,
    isUserInfoUpdateLoading: false,
    isChangePasswordLoading: false
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
            UserStorage.setToken(action.payload)
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
            UserStorage.setUser(action.payload)
            const role = stringToUserRole(action.payload.user_role)
            if (role !== null) {
                state.userRole = role
            }
        },
        updateUserBalance: (state, action: PayloadAction<number>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    user_balance: action.payload
                }
                UserStorage.setUser({
                    ...state.user,
                    user_balance: action.payload
                })
            }
        },
        clearToken: (state) => {
            state.token = null
            UserStorage.removeToken()
        },
        clearUser: (state) => {
            state.user = null
            state.userRole = null
            UserStorage.removeUser()
        },
        clearCurrency: (state) => {
            state.displayCurrency = ''
            state.exchangeRate = 1
            UserStorage.removeCurrency()
            UserStorage.removeExchangeRate()
        },
        setDisplayCurrency: (state, action: PayloadAction<string>) => {
            state.displayCurrency = action.payload
            UserStorage.setCurrency(action.payload)
        },
        setExchangeRate: (state, action: PayloadAction<number>) => {
            state.exchangeRate = action.payload
            UserStorage.setExchangeRate(action.payload)
        },
        setLogInLoading: (state, action: PayloadAction<boolean>) => {
            state.isLogInLoading = action.payload
        },
        setUserInfoUpdateLoading: (state, action: PayloadAction<boolean>) => {
            state.isUserInfoUpdateLoading = action.payload
        },
        setChangePasswordLoading: (state, action: PayloadAction<boolean>) => {
            state.isChangePasswordLoading = action.payload
        }
    }
})

export default UserSlice.reducer

export const { setToken, setUser, clearToken, clearUser, setLogInLoading, setUserInfoUpdateLoading, setChangePasswordLoading, updateUserBalance, setDisplayCurrency, clearCurrency, setExchangeRate } = UserSlice.actions