import { FC, useEffect, useState } from "react";
import { LinearProgress, Switch } from "@mui/material";
import s from './UserInfo.module.css'
import clsx from "clsx";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { User } from "../../../features/user/user";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import { BanUserSending } from "../../../features/manage_users/manageUsersModels";

interface BanUserProps {
    user: User
}

export const BanUser: FC<BanUserProps> = ({user}) => {
    const { languagePack: { pack: {userInfo: lang} }} = useLanguage()
    const { banUser, banUserSending } = useManageUsers()
    const [ banIntent, setBanIntent ] = useState<BanUserSending|null>(null)

    useEffect(() => {
        const banUser = banUserSending.find(ban => ban.user_id === user.user_id)
        if (banUser && !Boolean(banIntent)) {
            setBanIntent(banUser)
        } else if (!Boolean(banUser) && banIntent) {
            setBanIntent(null)
        }
    }, [banIntent, banUserSending, user.user_id])

    return <div className={s.banUserContainer}>
        <Switch checked={Boolean(banIntent) ? banIntent?.banned : user.banned} color={'error'} onChange={event => banUser(user.user_id, event.target.checked)} disabled={Boolean(banIntent)}/>
        <div className={s.banUserLabelBlock}>
            <div className={s.banUserLabel}>{lang.banUserLabel}</div>
            <div className={clsx(s.banUserState, user.banned ? s.bannedColor : s.notBannedColor)}>
                {Boolean(banIntent) ? <LinearProgress /> : (user.banned ? lang.banUserStateBanned : lang.banUserStateActive)}
            </div>
        </div>
    </div>
}