import React, { FC } from "react";
import { Box } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";

export interface SortedCellProps<T> {
    orderName: T
    currentOrderBy: T|null
    children: string
    onClick: (orderName: T) => void
}

export const GenericSortedCell: FC<SortedCellProps<any>> = ({ orderName, currentOrderBy, children, onClick }) => {
    return currentOrderBy === orderName ? (
        <Box fontWeight={800} display={'flex'} alignItems={'center'} columnGap={'6px'}>
            {children} <SortIcon fontSize={'small'} />
        </Box>
    ) : (
        <Box onClick={() => onClick(orderName)} sx={{ cursor: 'pointer' }}>
            {children}
        </Box>
    );
};