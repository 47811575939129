import React, { FC, useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useUser } from "../../features/user/useUser";

export const LoginForm: FC = () => {
    const { logIn, isLogInLoading } = useUser()
    const [ username, setUsername ] = useState<string>('')
    const [ password, setPassword ] = useState<string>('')

    const usernameFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const passwordFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const submitDisabled = (): boolean => username !== '' && password !== '' && isLogInLoading

    return <>
            <TextField value={username} onChange={usernameFieldHandler} size={'small'} label="username" variant="outlined"/>
            <TextField value={password} onChange={passwordFieldHandler} type={'password'} size={'small'} label="password" variant="outlined"/>
            <Button endIcon={isLogInLoading ? <CircularProgress size={16}/> : <></>} onClick={() => logIn(username, password)} disabled={submitDisabled()} variant={'contained'} size={'small'}>Login</Button>
        </>
}