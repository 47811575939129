import { MoneyTransfersState } from "./moneyTransfersSlice";
import { ApiResponse } from "../apiResponseModels";
import { PaymentAccountField, Proof } from "../payment_system/PaymentSystemModels";

// model for the money transfers table record
export interface MoneyTransfer {
    user_name: string;
    transfer_id: number;
    transfer_type: string;
    method_name: string;
    transfer_amount: number;
    transfer_status: string;
    transfer_currency: string;
    origin_user_id: number;
    request_time_unix: number; // Withdrawal request time in UNIX format
    request_time?: string; // Withdrawal request time in string format converted to the user's local time
    respond_time_unix: number|null; // Withdrawal response time in UNIX format
    respond_time?: string|null; // Withdrawal response time in string format converted to the user's local time
    user_account?: {
        payment_method: string;
        fields: PaymentAccountField[];
    } | null // Payment account of user who requested payout
    parent_account?: {
        payment_method: string;
        fields: PaymentAccountField[];
    } | null // Payment account of user who need to pay money
    proof?: Proof|null
}

// Filter model for loading money transfers
export interface MoneyTransferFilter {
    fromTime: number|null
    toTime: number|null
    username: string|null
    orderBy: MoneyTransfersSort|null
}

// Interface for the return values of the useMoneyTransfers hook
export interface iUseMoneyTransfers extends MoneyTransfersState {
    getMoneyTransfers: (page: number, itemsPerPage?: number, filter?: MoneyTransferFilter|null) => void
}

// Interface for the server response of getting the list of money transfers
export interface ShowMoneyTransfersResponse extends ApiResponse {
    data?: MoneyTransfer[]
    total_transfers?: number
}

// Interface for the server response of getting the list of money transfers
export interface ApprovePayoutResponse extends ApiResponse {
    data?: {
        transfer_id: number,
        user_id: number,
        transfer_status: string
    }
}

export enum MoneyTransfersSort {
    Username= 'user_name',
    Amount = 'transfer_amount',
    Method = 'method_name',
    Status = 'transfer_status',
    Type = 'transfer_type',
    DateTime = 'respond_time'
}

export const stringToMoneyTransfersSort = (stringMoneyTransfersSort: string): MoneyTransfersSort => {
    switch (stringMoneyTransfersSort) {
        case 'user_name':
            return MoneyTransfersSort.Username
        case 'transfer_amount':
            return MoneyTransfersSort.Amount
        case 'method_name':
            return MoneyTransfersSort.Method
        case 'transfer_status':
            return MoneyTransfersSort.Status
        case 'transfer_type':
            return MoneyTransfersSort.Type
        case 'respond_time':
        default:
            return MoneyTransfersSort.DateTime
    }
}