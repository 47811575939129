import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSnackbar, VariantType } from "notistack";
import { useBonus } from "../../features/bonus/useBonus";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../features/common_funcs";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Bonus } from "../../features/bonus/bonusModels";

interface iUseValues {
    promocode: string
    changePromocode: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    conditionDeposit: string
    setConditionDeposit: (value: string) => void
    wager: number | null
    changeWager: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    daysValid: number | null
    changeDaysValid: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    startPromoDate: Date | undefined
    setStartPromoDate: (date: Date) => void
    endPromoDate: Date | undefined
    setEndPromoDate: (date: Date) => void
    additionalCondition?: string
    setAdditionalCondition: (condition?: string | undefined) => void
    bonusAmount: string
    setBonusAmount: (value: string) => void
    bonusPercentage: number | null
    changeBonusPercentage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    freeSpins: number | null
    changeFreeSpins: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    bonusMax: string
    setBonusMax: (value: string) => void

    promoEmpty: boolean
    conditionDepositEmpty: boolean
    daysValidEmpty: boolean
    wagerEmpty: boolean

    handleSaveBonusClick: (onSuccess?: () => void) => void
    isBonusCreatingLoading: boolean
}

export const useValues = (bonusEditing?: Bonus): iUseValues => {
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const { createBonus, isBonusCreatingLoading, updateBonus } = useBonus()
    const { enqueueSnackbar } = useSnackbar();
    const { languagePack: {pack: {bonusConstructor: lang}}} = useLanguage()

    const [ promocode, setPromocode ] = useState<string>('')
    const [ conditionDeposit, setConditionDeposit ] = useState<string>('')
    const [ wager, setWager ] = useState<number | null>(1)
    const [ daysValid, setDaysValid ] = useState<number | null>(1)
    const [ startPromoDate, setStartPromoDate ] = useState<Date | undefined>()
    const [ endPromoDate, setEndPromoDate ] = useState<Date | undefined>()
    const [ additionalCondition, setAdditionalCondition ] = useState<string | undefined>()
    const [ bonusAmount, setBonusAmount ] = useState<string>('')
    const [ bonusPercentage, setBonusPercentage ] = useState<number | null>(null)
    const [ freeSpins, setFreeSpins ] = useState<number | null>(null)
    const [ bonusMax, setBonusMax ] = useState<string>('')

    const [ promoEmpty, setPromoEmpty ] = useState<boolean>(false)
    const [ conditionDepositEmpty, setConditionDepositEmpty ] = useState<boolean>(false)
    const [ daysValidEmpty, setDaysValidEmpty ] = useState<boolean>(false)
    const [ wagerEmpty, setWagerEmpty ] = useState<boolean>(false)

    useEffect(() => {
        if (bonusEditing && initLoad) {
            setInitLoad(false)
            setPromocode(bonusEditing.promo_code)
            setConditionDeposit(convertCentsToCurrencyString(bonusEditing.condition_deposit))
            setWager(bonusEditing.wager)
            setDaysValid(bonusEditing.days_valid)
            if (bonusEditing.condition_fromtime) setStartPromoDate(bonusEditing.condition_fromtime)
            if (bonusEditing.condition_totime) setEndPromoDate(bonusEditing.condition_totime)
            if (bonusEditing.condition_additional) setAdditionalCondition(bonusEditing.condition_additional)
            if (bonusEditing.bonus_amount) setBonusAmount(convertCentsToCurrencyString(bonusEditing.bonus_amount))
            if (bonusEditing.bonus_percentage) setBonusPercentage(bonusEditing.bonus_percentage)
            if (bonusEditing.bonus_freespins) setFreeSpins(bonusEditing.bonus_freespins)
            if (bonusEditing.bonus_max) setBonusMax(convertCentsToCurrencyString(bonusEditing.bonus_max))
        }
    }, [bonusEditing, initLoad])

    useEffect(() => {
        const promoErr = promocode.trim() === ''
        if (promoErr !== promoEmpty) setPromoEmpty(promoErr)

        const condErr = conditionDeposit.trim() === ''
        if (condErr !== conditionDepositEmpty) setConditionDepositEmpty(condErr)

        const daysValErr = daysValid === null || daysValid === 0
        if (daysValErr !== daysValidEmpty) setDaysValidEmpty(daysValErr)

        const wagerErr = wager === null || daysValid === 0
        if (wagerErr !== wagerEmpty) setWagerEmpty(wagerErr)
    }, [ promocode, conditionDeposit, wager, daysValid, promoEmpty, conditionDepositEmpty, daysValidEmpty, wagerEmpty ])

    const changePromocode = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPromocode(event.target.value)
    }

    const changeWager = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const res = stringToPositiveInt(event.target.value)
        if (res !== undefined) {
            setWager(res)
        }
    }

    const changeDaysValid = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const res = stringToPositiveInt(event.target.value)
        if (res !== undefined) {
            setDaysValid(res)
        }
    }

    const changeBonusPercentage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const res = stringToPositiveInt(event.target.value)
        if (res !== undefined) {
            setBonusPercentage(res)
        }
    }

    const changeFreeSpins = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const res = stringToPositiveInt(event.target.value)
        if (res !== undefined) {
            setFreeSpins(res)
        }
    }

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    const handleSaveBonusClick = useCallback((onSuccess = () => {
    }) => {
        if (bonusEditing === undefined) {
            if (promocode.trim() !== '' && wager && daysValid) {
                createBonus(
                    promocode,
                    convertStringCurrencyToCents(conditionDeposit),
                    wager,
                    daysValid,
                    startPromoDate,
                    endPromoDate,
                    additionalCondition,
                    bonusAmount !== '' ? convertStringCurrencyToCents(bonusAmount) : undefined,
                    bonusPercentage ?? undefined,
                    freeSpins ?? undefined,
                    bonusMax !== '' ? convertStringCurrencyToCents(bonusMax) : undefined,
                    () => {
                        sendSnackBar('success', lang.bonusCreateSuccessMessage)
                        onSuccess()
                    }
                )
            }
        } else {
            if (promocode.trim() !== '' && wager && daysValid) {
                const bonusToSave: Bonus = {
                    id: bonusEditing.id,
                    promo_code: promocode,
                    condition_deposit: convertStringCurrencyToCents(conditionDeposit),
                    wager,
                    days_valid: daysValid,
                    condition_fromtime: startPromoDate,
                    condition_totime: endPromoDate,
                    bonus_amount: bonusAmount !== '' ? convertStringCurrencyToCents(bonusAmount) : undefined,
                    bonus_percentage: bonusPercentage ?? undefined,
                    bonus_freespins: freeSpins ?? undefined,
                    bonus_max: bonusMax !== '' ? convertStringCurrencyToCents(bonusMax) : undefined
                }
                updateBonus(bonusToSave, () => {
                    sendSnackBar('success', lang.bonusCreateSuccessMessage)
                    onSuccess()
                })
            }
        }
    }, [additionalCondition, bonusAmount, bonusEditing, bonusMax, bonusPercentage, conditionDeposit, createBonus, daysValid, endPromoDate, freeSpins, lang.bonusCreateSuccessMessage, promocode, sendSnackBar, startPromoDate, updateBonus, wager])

    return {
        conditionDeposit,
        setConditionDeposit,
        bonusAmount,
        setBonusAmount,
        bonusMax,
        setBonusMax,
        promocode,
        changePromocode,
        conditionDepositEmpty,
        wagerEmpty,
        daysValidEmpty,
        promoEmpty,
        wager,
        changeWager,
        daysValid,
        changeDaysValid,
        startPromoDate,
        endPromoDate,
        setEndPromoDate,
        setStartPromoDate,
        additionalCondition,
        setAdditionalCondition,
        changeBonusPercentage,
        bonusPercentage,
        changeFreeSpins,
        freeSpins,
        handleSaveBonusClick,
        isBonusCreatingLoading
    }
}

const isPositiveNumbersOnly = (value: string): boolean => {
    return value.match(/^\d*$/) !== null
}

const stringToPositiveInt = (value: string): number | null | undefined => {
    if (isPositiveNumbersOnly(value)) {
        if (value === '') return null
        const v = Number.parseInt(value, 10)
        if (v > 0) {
            return v
        }
    } else {
        return undefined
    }
}