import React, { FC, useEffect, useState } from "react";
import {
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { useBonus } from "../../features/bonus/useBonus";
import { convertCentsToCurrencyString, convertDateToStringDate } from "../../features/common_funcs";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Bonus } from "../../features/bonus/bonusModels";
import { BonusConstructorDialog } from "./BonusConstructorDialog";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ConfirmationDialog } from "../commons/ConfirmationDialog";

interface BonusListProps {
    height: string
}

export const BonusList: FC<BonusListProps> = ({height}) => {
    const { languagePack: {pack: {bonusList: lang}}} = useLanguage()
    const { bonuses, isBonusesLoading, getBonuses, isBonusDeletingLoading, deleteBonus } = useBonus()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const [ bonusEditing, setBonusEditing ] = useState<Bonus|null>(null)
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<number | null>(null)

    useEffect(() => {
        if (initLoad && bonuses === null && !isBonusesLoading) {
            getBonuses()
            setInitLoad(false)
        }
    }, [ bonuses, getBonuses, initLoad, isBonusesLoading ])

    const handleIconButtonClick = (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setShowConfirmDelete(true)
        setDeleteId(id)
    }

    return <><TableContainer sx={{ maxHeight: height }}>
        <Table stickyHeader aria-label="bonus table">
            <TableHead>
                <TableRow>
                    <TableCell>{lang.promoLabel}</TableCell>
                    <TableCell>{lang.fromTimeLabel}</TableCell>
                    <TableCell>{lang.toTimeLabel}</TableCell>
                    <TableCell>{lang.conditionDepositLabel}</TableCell>
                    <TableCell>{lang.additionConditionLabel}</TableCell>
                    <TableCell>{lang.wagerLabel}</TableCell>
                    <TableCell>{lang.bonusAmountLabel}</TableCell>
                    <TableCell>{lang.bonusPercentageLabel}</TableCell>
                    <TableCell>{lang.bonusFreeSpinsLabel}</TableCell>
                    <TableCell>{lang.bonusMaxLabel}</TableCell>
                    <TableCell>{lang.daysValidLabel}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {bonuses && bonuses.map(bonus => <TableRow
                    key={`bonus-id-${bonus.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => setBonusEditing(bonus)}
                    className={'cursor-pointer'}
                >
                    <TableCell component="th" scope="row">{bonus.promo_code}</TableCell>
                    <TableCell>{bonus.condition_fromtime && convertDateToStringDate(bonus.condition_fromtime)}</TableCell>
                    <TableCell>{bonus.condition_totime && convertDateToStringDate(bonus.condition_totime)}</TableCell>
                    <TableCell>{bonus.condition_deposit && convertCentsToCurrencyString(bonus.condition_deposit)}</TableCell>
                    <TableCell>{bonus.condition_additional}</TableCell>
                    <TableCell>{bonus.wager}</TableCell>
                    <TableCell>{bonus.bonus_amount && convertCentsToCurrencyString(bonus.bonus_amount)}</TableCell>
                    <TableCell>{bonus.bonus_percentage}</TableCell>
                    <TableCell>{bonus.bonus_freespins}</TableCell>
                    <TableCell>{bonus.bonus_max && convertCentsToCurrencyString(bonus.bonus_max)}</TableCell>
                    <TableCell>{bonus.days_valid}</TableCell>
                    <TableCell>
                        <IconButton disabled={bonus.id === deleteId && isBonusDeletingLoading}
                                    onClick={handleIconButtonClick(bonus.id)}>
                            {bonus.id === deleteId ? <CircularProgress color={'error'} size={'20px'}/> :
                                <DeleteForeverOutlinedIcon/>}
                        </IconButton>
                    </TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>

        {bonusEditing && <BonusConstructorDialog close={() => setBonusEditing(null)} bonusEditing={bonusEditing}/>}

        {showConfirmDelete && <ConfirmationDialog onConfirm={() => {
            setShowConfirmDelete(false)
            if (deleteId) {
                deleteBonus(deleteId, undefined, () => setDeleteId(null))
            }
        }} close={() => {
            setShowConfirmDelete(false)
            setDeleteId(null)
        }} message={lang.deleteBonusConfirmationMessage}/>}
    </>
}