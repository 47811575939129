import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MoneyTransfer, MoneyTransferFilter } from "./MoneyTransfersModels";

export interface MoneyTransfersState {
    moneyTransfers: MoneyTransfer[] | null // Array of transfers loaded for the current page.
    isMoneyTransfersLoading: boolean // Loading status of the transfers page.
    pageSize: number // Number of records per transfers page.
    currentPage: number // Current transfers page.
    totalRecords: number // Total transfer records (usually not used anywhere).
    totalPages: number // Total number of pages.
    filter: MoneyTransferFilter // Filter for loading the transfers page (time, username, etc.).
}

const initialState: MoneyTransfersState = {
    // Null in case the data has not been received yet.
    moneyTransfers: null,
    isMoneyTransfersLoading: false,
    pageSize: 30,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    filter: {
        fromTime: null,
        toTime: null,
        username: null,
        orderBy: null
    }
}

export const MoneyTransfersSlice = createSlice(({
    name: 'moneyTransfers',
    initialState,
    reducers: {
        // Resetting current slice (e.g., when the table component is closed).
        clearMoneyTransfers: (state) => {
            state.moneyTransfers = null
            state.pageSize = 30
            state.currentPage = 1
            state.totalRecords = 0
            state.totalPages = 0
            state.filter = {
                fromTime: null,
                toTime: null,
                username: null,
                orderBy: null
            }
        },
        // Saving a new list of money transfer records to the store.
        setMoneyTransfers: (state, action: PayloadAction<MoneyTransfer[]>) => {
            state.moneyTransfers = action.payload
        },
        // Enabling/Disabling the loading status of the transfers page.
        setMoneyTransfersLoading: (state, action: PayloadAction<boolean>) => {
            state.isMoneyTransfersLoading = action.payload
        },
        // Setting the current page.
        setCurrentMoneyTransfersPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        // Setting the total number of transfer records.
        setTotalMoneyTransfersRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        // Setting the number of transfer records per page.
        setMoneyTransfersPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        // Setting the filter for searching transfers.
        setMoneyTransfersFilter: (state, action: PayloadAction<MoneyTransferFilter>) => {
            state.filter = action.payload
        },
        updateMoneyTransferStatusByID: (state, action: PayloadAction<{transferID: number, transfer_status: string}>) => {
            if (state.moneyTransfers !== null) {
                const index = state.moneyTransfers.findIndex(t => t.transfer_id === action.payload.transferID)
                const transfer = state.moneyTransfers.find(t => t.transfer_id === action.payload.transferID)
                if (index !== -1 && transfer) {
                    state.moneyTransfers = [
                        ...state.moneyTransfers.slice(0, index),
                        {...transfer, transfer_status: action.payload.transfer_status},
                        ...state.moneyTransfers.slice(index + 1)
                    ]
                }
            }
        }
    }
}))

export default MoneyTransfersSlice.reducer

export const {
    setMoneyTransfers,
    clearMoneyTransfers,
    setMoneyTransfersLoading,
    setCurrentMoneyTransfersPage,
    setTotalMoneyTransfersRecords,
    setMoneyTransfersPageSize,
    setMoneyTransfersFilter,
    updateMoneyTransferStatusByID
} = MoneyTransfersSlice.actions
