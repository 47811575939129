import { MoneyPack } from "../../features/finances/FinancesModels";
import { useCallback, useEffect, useState } from "react";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../features/common_funcs";
import { useFinances } from "../../features/finances/useFinances";
import { useSnackbar, VariantType } from "notistack";
import { useLanguage } from "../../features/localisation/useLanguage";

interface iUseLogic {
    freeCoins: string
    setFreeCoins: (value: string) => void
    coins: string
    setCoins: (value: string) => void
    price: string
    setPrice: (value: string) => void
    
    freeCoinsEmpty: boolean
    coinsEmpty: boolean
    priceEmpty: boolean

    handleSaveClick: (onSuccess?: () => void) => void
    isUpdatingCoinPackLoading: boolean
    isCreatingCoinPackLoading: boolean
}

export const useLogic = (coinsPackForEditing?: MoneyPack): iUseLogic => {
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const { enqueueSnackbar } = useSnackbar()
    const { languagePack: {pack: {coinsPackCreator: lang}}} = useLanguage()
    const { isUpdatingCoinPackLoading, isCreatingCoinPackLoading, updateCoinPack, createCoinPack} = useFinances()
    
    const [ freeCoins, setFreeCoins ] = useState<string>('')
    const [ coins, setCoins ] = useState<string>('')
    const [ price, setPrice ] = useState<string>('')
    
    const [ freeCoinsEmpty, setFreeCoinsEmpty ] = useState<boolean>(false)
    const [ coinsEmpty, setCoinsEmpty ] = useState<boolean>(false)
    const [ priceEmpty, setPriceEmpty ] = useState<boolean>(false)
    
    useEffect(() => {
        if (coinsPackForEditing && initLoad) {
            setInitLoad(false)
            setFreeCoins(convertCentsToCurrencyString(coinsPackForEditing.freecoins))
            setCoins(convertCentsToCurrencyString(coinsPackForEditing.coins))
            setPrice(convertCentsToCurrencyString(coinsPackForEditing.price))
        }
    }, [coinsPackForEditing, initLoad])
    
    useEffect(() => {
        const freeCoinsErr = freeCoins.trim() === ''
        if (freeCoinsErr !== freeCoinsEmpty) setFreeCoinsEmpty(freeCoinsErr)
        const coinsErr = coins.trim() === ''
        if (coinsErr !== coinsEmpty) setCoinsEmpty(coinsErr)
        const priceErr = price.trim() === ''
        if (priceErr !== priceEmpty) setPriceEmpty(priceErr)
        
    }, [coins, coinsEmpty, freeCoins, freeCoinsEmpty, price, priceEmpty])

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    const handleSaveClick = (onSuccess = () => {}) => {
        if (!freeCoinsEmpty && !coinsEmpty && !priceEmpty) {
            if (coinsPackForEditing) {
                updateCoinPack({
                    ...coinsPackForEditing,
                    freecoins: convertStringCurrencyToCents(freeCoins),
                    coins: convertStringCurrencyToCents(coins),
                    price: convertStringCurrencyToCents(price),
                },
                    () => {
                        sendSnackBar('success', lang.coinsPackCreateSuccessMessage)
                        onSuccess()
                    })
            } else {
                createCoinPack({
                    id: -1,
                    freecoins: convertStringCurrencyToCents(freeCoins),
                    coins: convertStringCurrencyToCents(coins),
                    price: convertStringCurrencyToCents(price),
                },
                    () => {
                        sendSnackBar('success', lang.coinsPackCreateSuccessMessage)
                        onSuccess()
                    })
            }
        }
    }
    
    return { freeCoins, coins, price, freeCoinsEmpty, coinsEmpty, priceEmpty, setFreeCoins, setCoins, setPrice, handleSaveClick, isUpdatingCoinPackLoading, isCreatingCoinPackLoading }
}