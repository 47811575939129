import {
    CreateCoinPackResponse,
    FinancesLoader,
    iUseFinances,
    MoneyPack,
    ShowCoinPacksResponse
} from "./FinancesModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useUser } from "../user/useUser";
import { useLanguage } from "../localisation/useLanguage";
import { useCallback } from "react";
import Config from "../../config";
import {
    addCoinPackToEndOfList, removeCoinPackFromList,
    setCoinPacks,
    setFinancesLoader,
    updateCoinPack as updateCoinPackReducer
} from "./FinancesSlice";
import axios from "axios";
import { ApiResponse } from "../apiResponseModels";

export const useFinances = (): iUseFinances => {
    const dispatch = useAppDispatch()
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const state = useAppSelector(state => state.finances)
    const { token } = useUser()

    const getCoinPacks = useCallback(() => {
        if (token) {
            dispatch(setFinancesLoader({
                loader: FinancesLoader.isCoinPacksLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowCoinPacks')
            data.append('token', token)

            axios.post<ShowCoinPacksResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, coinpacks } = response.data
                    if (success) {
                        if (coinpacks) {
                            dispatch(setCoinPacks(coinpacks))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setCoinPacks([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch(error => {
                    handleNetworkErrors(error)
                    dispatch(setCoinPacks([]))
                })
                .finally(() => {
                    dispatch(setFinancesLoader({
                        loader: FinancesLoader.isCoinPacksLoading,
                        isLoading: false
                    }))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, token ])

    const updateCoinPack = useCallback((coinPack: MoneyPack, onSuccess = () => {}) => {
        if (token) {
            dispatch(setFinancesLoader({
                loader: FinancesLoader.isUpdatingCoinPackLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateCoinPack')
            data.append('token', token)
            data.append('id', coinPack.id.toString())
            data.append('coins', coinPack.coins.toString())
            data.append('price', coinPack.price.toString())
            data.append('freecoins', coinPack.freecoins.toString())

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                        dispatch(updateCoinPackReducer(coinPack))
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setFinancesLoader({
                        loader: FinancesLoader.isUpdatingCoinPackLoading,
                        isLoading: false
                    }))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const createCoinPack = useCallback((coinPack: MoneyPack, onSuccess = () => {}) => {
        if (token) {
            dispatch(setFinancesLoader({
                loader: FinancesLoader.isCreatingCoinPackLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'CreateCoinPack')
            data.append('token', token)
            data.append('coins', coinPack.coins.toString())
            data.append('price', coinPack.price.toString())
            data.append('freecoins', coinPack.freecoins.toString())

            axios.post<CreateCoinPackResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success && data) {
                        onSuccess()
                        dispatch(addCoinPackToEndOfList(data))
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setFinancesLoader({
                        loader: FinancesLoader.isCreatingCoinPackLoading,
                        isLoading: false
                    }))
                })
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    const deleteCoinPack =useCallback((id: number, onSuccess = () => {}, onFinish = () => {}) => {
        if(token) {
            dispatch(setFinancesLoader({
                loader: FinancesLoader.isDeletingCoinPackLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'DeleteCoinPack')
            data.append('token', token)
            data.append('id', id.toString())

            axios.post<ApiResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(removeCoinPackFromList(id))
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setFinancesLoader({
                        loader: FinancesLoader.isDeletingCoinPackLoading,
                        isLoading: false
                    }))
                    onFinish()
                })  
        }
    }, [dispatch, handleNetworkErrors, setLocalizedError, token])

    return {
        ...state,
        getCoinPacks,
        updateCoinPack,
        createCoinPack,
        deleteCoinPack
    }
}