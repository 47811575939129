import Config from "../config";
import { User } from "../features/user/user";

const TOKEN = Config.localStoragePrefix + 'token'
const USER = Config.localStoragePrefix + 'user'
const CURRENCY = Config.localStoragePrefix + 'currency'
const EXCHANGE_RATE = Config.localStoragePrefix + 'exchange_rate'

class UserStorage {
    static getUser () : User | null {
        const s = localStorage.getItem(USER)
        if (s !== null) return JSON.parse(s) as User
        return null
    }

    static setUser (user: User) {
        localStorage.setItem(USER, JSON.stringify(user))
    }

    static removeUser () {
        localStorage.removeItem(USER)
    }

    static getToken () : string | null {
        return localStorage.getItem(TOKEN)
    }

    static setToken (token: string) {
        localStorage.setItem(TOKEN, token)
    }

    static removeToken () {
        localStorage.removeItem(TOKEN)
    }

    static getCurrency () : string | null {
        return localStorage.getItem(CURRENCY)
    }

    static setCurrency (currency: string) {
        localStorage.setItem(CURRENCY, currency)
    }

    static removeCurrency () {
        localStorage.removeItem(CURRENCY)
    }

    static getExchangeRate () : number | null {
        const str_rate = localStorage.getItem(EXCHANGE_RATE)
        return str_rate !== null ? Number.parseFloat(str_rate) : null
    }

    static setExchangeRate (exchangeRate: number) {
        localStorage.setItem(EXCHANGE_RATE, exchangeRate.toString())
    }

    static removeExchangeRate () {
        localStorage.removeItem(EXCHANGE_RATE)
    }
}

export default UserStorage