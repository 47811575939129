import { SecurityLogState } from "./securityLogSlice";
import { ApiResponse } from "../apiResponseModels";

export interface SecurityLogObj {
    log_id: number
    user_id: number
    user_name: string
    log_action: string
    log_ip: string
    request_json: string | null
    response_json: string | null
    timestamp_unix: number
    timestamp: string
}

export enum SecurityLogSort {
    log_id = 'log_id',
    log_user_id = 'log_user_id',
    log_action = 'log_action',
    log_timestamp = 'log_timestamp',
    log_ip = 'log_ip',
    user_name = 'user_name'
}

export const stringToSecurityLogSort = (stringSecurityLogSort: string): SecurityLogSort => {
    switch (stringSecurityLogSort) {
        case 'log_user_id':
            return SecurityLogSort.log_user_id
        case 'log_action':
            return SecurityLogSort.log_action
        case 'log_timestamp':
            return SecurityLogSort.log_timestamp
        case 'log_ip':
            return SecurityLogSort.log_ip
        case 'user_name':
            return SecurityLogSort.user_name
        case 'log_id':
        default:
            return SecurityLogSort.log_id
    }
}

export interface SecurityLogFilter {
    fromTime: number|null
    toTime: number|null
    username: string|null
    event: string|null
    orderBy: SecurityLogSort|null
}

export interface iUseSecurityLog extends SecurityLogState {
    getSecurityLog: (page: number, itemsPerPage?: number, filter?: SecurityLogFilter|null) => void
}

export interface ShowSecurityLogResponse extends ApiResponse {
    data?: SecurityLogObj[]
    total_logs?: number
}