import { FC, } from "react";
import Flatpickr from "react-flatpickr";
import { CustomInput } from "./CustomInput";

interface SelectDateProps {
    helper?: string
    placeholder?: string
    value?: Date
    onChange?: (date: Date) => void
}

export const SelectDate: FC<SelectDateProps> = ({helper, onChange = () => {}, placeholder, value}) => {
    return <Flatpickr
        options={{
            enableTime: false,
            dateFormat: "d-m-Y",
            appendTo: document.body,
        }}
        value={value}
        onChange={([date]) => {
            onChange( date );
        }}
        render={
            ({value, ...props}, ref) => {
                return <CustomInput placeholder={placeholder} helper={helper} {...props} value={value} inputRef={ref} />
            }
        }
    />
};
