import React, { FC, useEffect, useState } from "react";
import { Alert, Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSecurityLog } from "../../../features/security_log/useSecurityLog";
import { useLanguage } from "../../../features/localisation/useLanguage";
import s from './SecurityLogTable.module.css'
import { JSONViewerDialog } from "../../../components/commons/json_viewer/JSONViewerDialog";
import { generateColorHex } from "../../../features/common_funcs";
import { useSecurityLogWatcher } from "../../../features/security_log/useSecurityLogWatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { SecurityLogSort } from "../../../features/security_log/SecurityLogModels";
import { useAppDispatch } from "../../../store";
import { clearSecurityLog } from "../../../features/security_log/securityLogSlice";
import SortIcon from '@mui/icons-material/Sort';

interface SecurityLogTableProps {
    height: string
}

export const SecurityLogTable: FC<SecurityLogTableProps> = ({height}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    useSecurityLogWatcher()
    const { languagePack: {pack: {securityLog: lang}}} = useLanguage()
    const { securityLog, isSecurityLogLoading, filter: {orderBy} } = useSecurityLog()
    const [ displayJSON, setDisplayJSON ] = useState<string|null>(null)
    
    useEffect(() => {
        return () => {
            dispatch(clearSecurityLog())
        }
    }, [dispatch])

    const handleClickSorting = (sort: SecurityLogSort) => {
        searchParams.set('orderBy', sort);

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return <>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="manage users table">
                <TableHead>
                    <TableRow >
                        <TableCell>
                            <SortedCell currentOrderBy={orderBy} orderName={SecurityLogSort.log_id} onClick={handleClickSorting}>
                                {lang.logID}
                            </SortedCell>
                        </TableCell>
                        <TableCell>
                            <SortedCell currentOrderBy={orderBy} orderName={SecurityLogSort.user_name} onClick={handleClickSorting}>
                                {lang.userName}
                            </SortedCell>
                        </TableCell>
                        <TableCell>
                            <SortedCell currentOrderBy={orderBy} orderName={SecurityLogSort.log_ip} onClick={handleClickSorting}>
                                {lang.userIP}
                            </SortedCell>
                        </TableCell>
                        <TableCell>
                            <SortedCell currentOrderBy={orderBy} orderName={SecurityLogSort.log_action} onClick={handleClickSorting}>
                                {lang.logAction}
                            </SortedCell>
                        </TableCell>
                        <TableCell>{lang.requestJSON}</TableCell>
                        <TableCell>{lang.responseJSON}</TableCell>
                        <TableCell>
                            <SortedCell currentOrderBy={orderBy} orderName={SecurityLogSort.log_timestamp} onClick={handleClickSorting}>
                                {lang.timestamp}
                            </SortedCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {securityLog && securityLog.map((log) => <TableRow key={`security-log-id-${log.log_id}`}>
                        <TableCell>{log.log_id}</TableCell>
                        <TableCell>{log.user_name}</TableCell>
                        <TableCell>{log.log_ip}</TableCell>
                        <TableCell style={{color: generateColorHex(log.log_action), fontWeight: 500}}>{log.log_action}</TableCell>
                        <TableCell>
                            {log.request_json !== null ? <span onClick={() => setDisplayJSON(log.request_json)} className={s.pseudoLink}>JSON</span> : 'None'}
                        </TableCell>
                        <TableCell>
                            {log.response_json !== null ? <span onClick={() => setDisplayJSON(log.response_json)} className={s.pseudoLink}>JSON</span> : 'None'}
                        </TableCell>
                        <TableCell>{log.timestamp}</TableCell>
                    </TableRow>)}
                    { securityLog === null && isSecurityLogLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>

        { securityLog?.length === 0 && <Alert variant="filled" severity="warning">
            {lang.securityLogNotFoundMessage}
        </Alert>}

        {displayJSON && <JSONViewerDialog close={() => setDisplayJSON(null)} jsonString={displayJSON}/>}
    </>
}

interface SortedCellProps {
    orderName: SecurityLogSort
    currentOrderBy: SecurityLogSort|null
    children: string
    onClick: (orderName: SecurityLogSort) => void
}

const SortedCell: FC<SortedCellProps> = ({orderName, currentOrderBy, children, onClick }) => {
    return currentOrderBy === orderName ?
        <Box fontWeight={800} display={'flex'} alignItems={'center'} columnGap={'6px'}>
            {children} <SortIcon fontSize={'small'}/>
        </Box> :
        <Box onClick={() => onClick(orderName)} sx={{cursor: 'pointer'}}>{children}</Box>
}