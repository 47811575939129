import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { User } from "../../../features/user/user";
import { WithdrawMoney } from "./WithdrawMoney";

interface WithdrawMoneyDialogProps {
    close: () => void
    childUser: User
}

export const WithdrawMoneyDialog: FC<WithdrawMoneyDialogProps> = ({close = () => {}, childUser}) => {
    const [ opened, setOpened ] = useState(true)
    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <WithdrawMoney onDone={pendingClose} childUser={childUser}/>
        </DialogContent>
    </Dialog>
}