import { FC } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";

const promoConditions = [ 'FIRST', 'SECOND', 'THIRD', 'FOURTH', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY' ]

interface PromoConditionSelectorProps {
    selectedCondition?: string
    onConditionChanged?: (condition?: string) => void
    helperText?: string
}

export const PromoConditionSelector: FC<PromoConditionSelectorProps> = ({selectedCondition, onConditionChanged = () => {}, helperText}) => {
    const { languagePack: {pack: {bonusConstructor: lang}}} = useLanguage()
    const handleChange = (event: SelectChangeEvent) => {
        onConditionChanged(event.target.value);
    };

    return <><FormControl size={'small'} fullWidth>
        <InputLabel id="promo-condition-selector-label">{lang.additionalConditionLabel}</InputLabel>
        <Select
            labelId="promo-condition-selector-label"
            id="promo-condition-selector-helper"
            value={selectedCondition || ''}
            label={lang.additionalConditionLabel}
            onChange={handleChange}
        >
            <MenuItem value={undefined}>
                <em>None</em>
            </MenuItem>
            {promoConditions.map((condition) => <MenuItem value={condition} key={condition}>{condition}</MenuItem>)}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
    </>
}