import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const enLang: LangPack = {
    lang: Lang.EN,
    langName: "English",
    langShort: "en",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Yes",
            closeBTN: "Close",
            cancelBTN: "Cancel",
            addBTN: "Add",
            editBTN: "Edit",
            saveBTN: "Save",
            errorCodeTitle: "Error code",
            pageSizeSwitcherLabel: "Rows on page",
            logOutConfirmationMessage: "Are you sure you want to log out?",
            youMustBeLoggedMessage: 'You must be logged in to view this section!'
        },
        internalErrorMessages: {
            networkError: "A network error occurred while executing the request.",
            error502: "A 502 server error occurred while executing the request."
        },
        errorMessages: {
            2: "No entries",
            3: "User email address already existing",
            4: "User phone number already existing",
            5: "User already logged in",
            6: "Wrong password",
            7: "Empty games result",
            8: "Result ID does not match the request ID",
            9: "Outcome request error",
            10: "Outcome no session ID in the response",
            11: "User already existing",
            12: "Your token is not valid. Login again.",
            13: "You have not enough rights to perform this action",
            14: "User ID for this action missing",
            15: "Money amount missing",
            16: "Action does not exist",
            17: "Token missing",
            18: "Not enough money",
            19: "You must be the parent of this user to perform this action",
            20: "User does not exist",
            21: "The value type for this field is wrong",
            22: "Password missing",
            23: "User name missing",
            24: "Game ID missing",
            25: "New password missing",
            26: "Token could not be set up",
            27: "Token could not be deleted",
            28: "User ID missing",
            29: "Wrong datetime format",
            30: "Could not update user ban",
            31: "This user must be in your tree to perform this action",
            32: "This user is banned and cannot perform any actions",
            33: "Currency missing",
            34: "Maximum value missing",
            35: "Limit type must be once, daily, weekly, or monthly",
            36: "Limit type missing",
            37: "Provider does not exist",
            38: "Tier condition amount missing",
            39: "Tier condition type missing",
            40: "Tier title missing",
            41: "Cashback for this tier is already existing",
            42: "Cashback for this tier not existing",
            43: "Bonus for this promocode is already existing",
            44: "Bonus does not exist",
            45: "Promocode missing",
            46: "Start time missing",
            47: "Deposit amount condition missing",
            48: "Wager missing",
            49: "Amount validity days missing",
            50: "ID missing",
            51: "Cashback type missing, must be 'wager' or 'deposit'",
            52: "Cashback rate missing",
            53: "This currency is not available",
            54: "Ban parameter missing - true or false",
            55: "The bank group for this currency is not valid",
            56: "Payment method name missing",
            57: "Mandatory parameters for this payment method missing",
            58: "This payment method does not exist",
            59: "This payment method does not support the currency of this user",
            60: "This user does not have an existing account for this payment system",
            61: "The parent of this user does not have an existing account for this payment system",
            62: "Transfer ID missing",
            63: "Transfer with this ID does not exist",
            64: "This transfer is requested by a different parent",
            65: "You still have a bonus which is not expired",
            66: "This bonus promo action has not started yet",
            67: "This bonus promo action is already over",
            68: "This deposit does not fit the bonus requirements",
            69: "This transfer is not pending and cannot be approved or declined",
            70: "Approve flag (true/false) missing",
            71: "Wrong week day for this bonus",
            72: "The number of your deposits does not fit the bonus requirements",
            73: "Mandatory parameters missing for this payment method",
            74: "Deposit rate missing",
            75: "User role missing",
            76: "You cannot create a user with this user role",
            77: "Fun active parameter missing",
            78: "This function is not available for this website",
            79: "Coins amount missing",
            80: "Price for coins pack missing",
            81: "Provider type missing",
            82: "Outcome request error",
            83: "Provider ID missing",
            84: "Contractor ID missing",
            85: "Contractor does not exist"
        },
        sideMenu: {
            logOutBtn: "Log Out",
            dashboard: 'Dashboard',
            users: 'Users',
            gameHistory: 'Game history',
            moneyTransfers: 'Money transfers',
            limitsDivider: 'Limits',
            limitsGeneral: 'General',
            limitsOutcome: 'Outcome',
            bonus: 'Bonus',
            paymentSystems: 'My payments',
            buyBalance: 'Buy balance',
            affiliateSystem: 'Affiliate system',
            crmSettings: 'CRM settings',
            triggerEvents: 'Trigger events',
            websiteSettings: 'Website settings',
            securityLog: 'Security log',
            royaltyStatistics: 'Royalty statistics',
            finances: 'Finances',
            providers: 'Providers'
        },
        userProfileForm: {
            userProfileTabLabel: 'User info',
            passwordTabLabel: 'Password',
            emailIsRequiredMess: 'Email is required',
            notValidEmail: 'Enter a valid email address',
            currentPassFieldLabel: "Current password",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "This field must not be empty",
            firstnameFieldLabel: "Firstname",
            lastnameFieldLabel: "Lastname",
            newPassFieldLabel: "New password",
            passNotMatchWarningMessage: "New password and retype password do not match",
            phoneFieldLabel: "Phone",
            retypePassFieldLabel: "Retype new password",
            saveInfoBTN: "Save info",
            updatePassBTN: "Update password"
        },
        userInfo: {
            registeredLabel: 'Registered',
            roleLabel: 'Role',
            balanceLabel: 'Balance',
            firstNameLabel: 'Firstname',
            lastnameLabel: 'Lastname',
            phoneLabel: 'Phone',
            emailLabel: 'Email',
            depositMoneyBtn: 'Deposit money to user',
            withdrawMoneyBtn: 'Withdraw money from user',
            moneyTransfersBtn: 'Money transfers',
            gamesHistoryBtn: 'Games history',
            banUserLabel: 'Ban user',
            banUserStateBanned: 'Banned',
            banUserStateActive: 'Active'
        },
        createUser: {
            title: "Create new user",
            usernameLabel: "Username",
            createBtn: "Create"
        },
        moneyInput: {
            maxAmountMessage: "Maximum value",
            exceedAmountError: "Maximum value exceeded.",
            moreThanOneDotError: "You made an extra point.",
            moreThanTwoDecimals: "You entered more than two decimal places."
        },
        depositMoneyToUser: {
            depositLabel: "Deposit",
            confirmSendingMessage: "Are you sure you want to send money to the user %username%?",
            moneySuccessfulSentMessage: "You have successfully sent %username% %money%.",
            sendBtn: "Send"
        },
        withdrawMoneyFromUser: {
            withdrawLabel: "Withdraw from user",
            confirmWithdrawMessage: "Are you sure you want to withdraw money from user %username%?",
            moneySuccessfulWithdrawedMessage: "You have successfully withdraw %money% from %username%.",
            withdrawBtn: "Withdraw"
        },
        moneyTransfersTable: {
            usernameHeader: "Username",
            amountTableHeader: "Amount",
            methodTableHeader: "Method",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Deposit/Withdraw",
            dateTimeTableHeader: "Date/Time",
            moneyTransfersNotFoundMessage: "No money transfers were found"
        },
        gamesHistory: {
            userNameTableHeader: 'Username',
            providerTableHeader: 'Provider',
            gameTableHeader: 'Game',
            betAmountTableHeader: 'Bet amount',
            winAmountTableHeader: 'Win amount',
            dateTimeTableHeader: 'Date/Time',
            gamesHistoryNotFoundMessage: 'No game history records were found'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "There are no more payment methods available",
            paymentMethodsDropdownLabel: "Payment methods",
            yourPaymentAccountsTitle: "Your payment accounts",
            addPaymentMethodBTN: "Add payment method",
            paymentAccountSuccessfullyUpdated: "You have successfully updated your payment account.",
            depositRateLabel: 'Deposit rate',
            depositRateSavedMessage: 'Deposit rate successfully saved.'
        },
        approveTransaction: {
            userRequestPayoutTitle: "User %username% requested a withdrawal %money% to %payment_system%",
            userRequestPaymentTitle: "User %username% transfer payment %money% to %payment_system% for top up his/her balance.",
            confirmPayoutMessage: "Do you approve the transaction?",
            declineBTN: "Decline",
            approveBTN: "Approve"
        },
        requestPayment: {
            choosePaymentAccountTitle: "Choose payment account\n to buy balance",
            paymentAmountLabel: "Payment amount",
            youllReceiveOnBalanceLabel: "You'll receive on balance",
            depositRateHelper: "Deposit rate %rate%",
            backBtn: "Back",
            nextBtn: "Next",
            iTransferMoneyBtn: "I transfer money",
            transferMoneyToThisAccountMessage: "1. Transfer money to this account",
            enterYourProofMessage: "2. Enter your proof for checking your payment"
        },
        securityLog: {
            logID: "ID",
            userName: "User name",
            userIP: "User IP",
            logAction: "Log action",
            requestJSON: "Request JSON",
            responseJSON: "Response JSON",
            timestamp: "Date/Time",
            securityLogNotFoundMessage: "No security log records were found"
        },
        royaltyStatistics:  {
            royaltyStatisticsTitle: 'Royalty statistics',
            providerTableHeader: "Provider",
            sectionTableHeader: "Section",
            currencyTableHeader: "Currency",
            userLossesTableHeader: "User Losses",
            userWinsTableHeader: "User Wins",
            ggrTableHeader: "GGR",
            royaltyStatisticsNotFoundMessage: "No royalty statistics records were found"
        },
        manageUsers: {
            usersTreeSwitcher: "Users tree",
            createUserBtn: "Create user",
            activeTableHeader: "Active",
            usernameHeader: "Username",
            firstNameTableHeader: "Firstname",
            lastnameTableHeader: "Lastname",
            balanceTableHeader: "Balance",
            currencyTableHeader: "Currency",
            roleTableHeader: "Role",
            emailTableHeader: "Email",
            phoneTableHeader: "Phone",
            usersNotFoundMessage: "No users were found"
        },
        timeFilter: {
            fromTimeDefaultLabel: "From time",
            toTimeDefaultLabel: "To time",
            fromTimeLabel: "From %time%",
            toTimeLabel: "To %time%"
        },
        usernameFilter: {
            usernameFilterLabel: "Username"
        },
        userRoles: {
            platform: "platform",
            owner:  "owner",
            superadmin:  "superadmin",
            admin:  "admin",
            cashier:  "cashier",
            player:  "player",
        },
        currencySwitcher: {
            currencyLabel: 'Currency'
        },
        bonusConstructor: {
            createBonusBTN: 'Create bonus',
            bonusConstructorTitle: 'Bonus constructor',
            promoCodeLabel: 'Promocode name',
            promoCodeHelper: 'name of the promotional code that will be entered when requesting a bonus',
            conditionDepositLabel: 'Condition deposit',
            conditionDepositHelper: 'minimum deposit required to receive the bonus',
            wagerLabel: 'Wager',
            wagerHelper: 'wagering factor',
            daysValidLabel: 'Days valid',
            daysValidHelper: 'how long the bonus is valid after request in days',
            bonusAmountLabel: 'Bonus amount',
            bonusAmountHelper: 'cash bonus prize',
            startPromoDateLabel: 'Start promo date',
            endPromoDateLabel: 'End promo date',
            additionalConditionLabel: "Additional condition",
            additionalConditionHelper: 'additional condition for receiving a bonus',
            bonusPercentageLabel: 'Bonus percentage, %',
            bonusPercentageHelper: 'bonus prize as a percentage of the deposit',
            freeSpinsLabel: 'Free spins',
            freeSpinsHelper: 'bonus free spins',
            bonusMaxLabel: 'Bonus max',
            bonusMaxHelper: 'upper limit of the bonus',
            saveBonusBTN: "Save bonus",
            bonusCreateSuccessMessage: 'Bonus successfully saved!'
        },
        bonusList: {
            promoLabel: 'Promo code',
            fromTimeLabel: 'From time',
            toTimeLabel: 'To time',
            conditionDepositLabel: 'Condition deposit',
            additionConditionLabel: 'Additional condition',
            wagerLabel: 'Wager',
            bonusAmountLabel: 'Bonus amount',
            bonusPercentageLabel: 'Bonus percentage',
            bonusFreeSpinsLabel: 'Bonus free spins',
            bonusMaxLabel: 'Bonus max',
            daysValidLabel: 'Days valid',
            deleteBonusConfirmationMessage: 'Are you sure you want to delete this bonus?'
        },
        coinsPackCreator: {
            coinsPackCreatorTitle: 'Coins pack creator',
            savePackBTN: 'Save pack',
            coinsLabel: 'Coins',
            coinsHelper: 'coins in the pack',
            freeCoinsLabel: 'Free coins',
            freeCoinsHelper: 'free coins in the pack',
            priceLabel: 'Price',
            priceHelper: 'price of the pack',
            coinsPackCreateSuccessMessage: 'Coins pack successfully saved!'
        },
        coinsList: {
            idHeader: 'ID',
            coinsHeader: 'Coins',
            priceHeader: 'Price',
            freeCoinsHeader: 'Free coins',
            deleteCoinsPackConfirmationMessage: 'Are you sure you want to delete this coins pack?',
            createCoinPackBTN: 'Create coins pack',
        }
    }
}