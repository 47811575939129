import { FC, ReactNode } from "react";
import s from "./UserNode.module.css";

interface NodeChildProps {
    children?: ReactNode
}

export const NodeChild: FC<NodeChildProps> = ({children}) => {
    return <div className={s.child}>
        <div className={s.branchColumn}>
            <div className={s.branchTop}></div>
            <div className={s.branchBottom}></div>
        </div>
        <div className={s.nodesColumn}>{children}</div>
    </div>
}