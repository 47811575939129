import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import CheckIcon from '@mui/icons-material/Check';
import { ContainedIconButton } from "../controls/ContainedIconButton";
import BlockIcon from '@mui/icons-material/Block';
import { convertDateToUnixSeconds } from "../../../features/common_funcs";

interface TimeFilterProps {
    dateTime: number|null
    close?: () => void
    setTime: (time: number) => void
}

export const TimeFilter: FC<TimeFilterProps> = ({dateTime, setTime, close = () => {}}) => {
    const [ date, setDate ] = useState<Date>(new Date())

    useEffect(() => {
        if (dateTime !== null) {
            setDate(new Date(dateTime * 1000))
        }
    }, [dateTime])

    const handleApplyClick = () => {
        setTime(convertDateToUnixSeconds(date))
        close()
    }

    return <Box display={'flex'} columnGap={'6px'}>
        <Flatpickr
            options={{
                enableTime: false,
                dateFormat: "d-m-Y",
                appendTo: document.body,
            }}
            value={date}
            onChange={([date]) => {
                setDate( date );
            }}
        />
        <ContainedIconButton color="secondary" onClick={close}>
            <BlockIcon fontSize={'small'}/>
        </ContainedIconButton>
        <ContainedIconButton color="primary" onClick={handleApplyClick}>
            <CheckIcon fontSize={'small'}/>
        </ContainedIconButton>
    </Box>
}