import { iUseProviderAccess, ProviderAccess } from "./ProviderModels";
import { useUser } from "../user/useUser";

/**
 * Returns an object indicating whether the user has provider access.
 *
 * @return {iUseProviderAccess} Object with a boolean property indicating whether the user has provider access.
 */
export const useProviderAccess = (): iUseProviderAccess => {
    const { userRole } = useUser();
    const hasAccess = userRole !== null && ProviderAccess.includes(userRole);

    return { hasAccess };
}