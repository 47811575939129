import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useGameHistory } from "./useGameHistory";
import { getNumberParam } from "../common_funcs";
import { GameHistoryFilter, stringToGameHistorySort } from "./GameHistoryModels";

export const useGameHistoryWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { isGameHistoryLoading, filter, gameHistory, pageSize, currentPage, getGameHistory } = useGameHistory()

    useEffect(() => {
        if (!isGameHistoryLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const qOrderBy = searchParams.get('orderBy')

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const usernameHasChanges = qUsername !== filter.username
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const gameHistoryNotLoadedYet = gameHistory === null

            if (pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                || usernameHasChanges
                || orderByHasChanges 
                || gameHistoryNotLoadedYet) {
                console.group('Game history changes')
                console.log('pageHasChanges', pageHasChanges)
                console.log('pageSizeHasChanges', pageSizeHasChanges)
                console.log('fromTimeHasChanges', fromTimeHasChanges)
                console.log('toTimeHasChanges', toTimeHasChanges)
                console.log('usernameHasChanges', usernameHasChanges)
                console.log('orderByHasChanges', orderByHasChanges)
                console.log('gameHistoryNotLoadedYet', gameHistoryNotLoadedYet)
                console.groupEnd()

                const filterNew: GameHistoryFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    username: qUsername,
                    orderBy: qOrderBy !== null ? stringToGameHistorySort(qOrderBy) : null
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize

                getGameHistory(pageNew, itemsPerPageNew, filterNew)
            }
        }
    }, [ currentPage, disabled, filter.fromTime, filter.orderBy, filter.toTime, filter.username, gameHistory, getGameHistory, isGameHistoryLoading, location.search, pageSize ])
}