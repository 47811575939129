import { UserRole } from "../user/user";
import { ApiResponse } from "../apiResponseModels";

export interface Bonus {
    id: number;
    promo_code: string;
    condition_fromtime?: Date;
    condition_totime?: Date;
    condition_fromtime_unix?: number;
    condition_totime_unix?: number;
    condition_deposit: number;
    condition_additional?: string;
    wager: number;
    bonus_amount?: number;
    bonus_percentage?: number;
    bonus_freespins?: number;
    bonus_max?: number;
    days_valid: number;
}

export const BonusAccess = [
    UserRole.PLATFORM,
    UserRole.OWNER
]

export interface iUseBonusAccess {
    hasAccess: boolean
}

export enum BonusLoader {
    isBonusCreatingLoading,
    isBonusesLoading,
    isBonusDeletingLoading
}

export interface BonusState {
    bonuses: Bonus[]|null
    isBonusCreatingLoading: boolean
    isBonusesLoading: boolean
    isBonusDeletingLoading: boolean
}

export interface iUseBonus extends BonusState {
    createBonus: (
        promocode: string,
        condition_deposit: number,
        wager: number,
        days_valid: number,
        from_time?: Date,
        to_time?: Date,
        condition_additional?: string,
        bonus_amount?: number,
        bonus_percentage?: number,
        bonus_freespins?: number,
        bonus_max?: number,
        onSuccess?: () => void
    ) => void
    getBonuses: () => void
    updateBonus: (bonus: Bonus, onSuccess?: () => void) => void
    deleteBonus: (id: number, onSuccess?: () => void, onFinish?: () => void) => void
}

export interface ShowBonusesResponse extends ApiResponse {
    bonuses?: Bonus[]
}