import { iUseRoyalty, RoyaltyFilter, ShowRoyaltyStatisticsResponse } from "./RoyaltyModels";
import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import { setRoyaltyStatistics, setRoyaltyStatisticsFilter, setRoyaltyStatisticsLoading } from "./royaltySlice";
import axios, { AxiosError } from "axios";
import Config from "../../config";
import { v4 as uuidv4 } from 'uuid';

export const useRoyalty = (): iUseRoyalty => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()

    const state = useAppSelector(state => state.royalty)

    const getRoyaltyStatistics = useCallback((filter: RoyaltyFilter | null = null) => {
        let filterForSending = state.filter
        if (filter) {
            if (filter.fromTime !== state.filter.fromTime ||
                filter.toTime !== state.filter.toTime ||
                filter.orderBy !== state.filter.orderBy) {
                dispatch(setRoyaltyStatisticsFilter(filter))
                filterForSending = filter
            }
        }
        if (token) {
            dispatch(setRoyaltyStatisticsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowRoyaltyStatistics')
            data.append('token', token)

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.orderBy !== null) {
                data.append('orderby', filterForSending.orderBy)
            }

            axios.post<ShowRoyaltyStatisticsResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: royaltyStatisticsList } = response.data

                    if (success) {
                        if (royaltyStatisticsList) {
                            royaltyStatisticsList.forEach(royaltyItem => {
                                royaltyItem.uniqueLocalID = uuidv4()
                            })
                            dispatch(setRoyaltyStatistics(royaltyStatisticsList))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setRoyaltyStatistics([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setRoyaltyStatistics([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setRoyaltyStatisticsLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, state.filter, token ])

    return {
        ...state,
        getRoyaltyStatistics
    }
}