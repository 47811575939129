import { FC, useEffect, useState } from "react";
import { User, UserRole } from "../../../features/user/user";
import { Box, Button, CircularProgress } from "@mui/material";
import s from './DepositMoney.module.css'
import { useUser } from "../../../features/user/useUser";
import {
    convertCentsToCurrencyString,
    convertStringCurrencyToCents, currencyConvert
} from "../../../features/common_funcs";
import { MoneyInput } from "../../../components/commons/money_input/MoneyInput";
import { ConfirmationDialog } from "../../../components/commons/ConfirmationDialog";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { chooseName } from "../../../features/user/userFuncs";
import { useSnackbar, VariantType } from "notistack";
import { useManageUsers } from "../../../features/manage_users/useManageUsers";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CurrencySwitcher from "../../../components/manage_users/CurrencySwitcher";

interface DepositMoneyProps {
    onDone: () => void
    childUser: User
}

export const DepositMoney: FC<DepositMoneyProps> = ({onDone = () => {}, childUser}) => {
    const { languagePack: {pack: {depositMoneyToUser: lang}}} = useLanguage()
    const [value, setValue] = useState<string>('');
    const { user, userRole } = useUser()
    const [ maxAmount, setMaxAmount ] = useState<string>('')
    const [ hasError, setHasError ] = useState<boolean>(false)
    const [ confirmation, setConfirmation ] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const { isDepositToUserSending, sendDepositMoneyToUser } = useManageUsers()
    const [ selectedCurrency, setSelectedCurrency ] = useState<string|undefined>()
    const [ selectedExchangeRate, setSelectedExchangeRate ] = useState<number|undefined>()
    const { currencies } = useManageUsers()

    const maxAmountDisabled = userRole !== null && [UserRole.PLATFORM, UserRole.OWNER].includes(userRole)
    
    useEffect(() => {
        if (user) {
            let convertedBalance: number|undefined
            if (currencies !== null && selectedExchangeRate) {
                const userCurrency = user.user_currency
                if (userCurrency) {
                    const foundUserCurrency = currencies.find(cur => cur.currency_name === userCurrency)
                    if (foundUserCurrency) {
                        convertedBalance = currencyConvert(user.user_balance, foundUserCurrency.exchange_rate, selectedExchangeRate)
                    }
                }
            }
            const balance = convertCentsToCurrencyString(convertedBalance !== undefined ? convertedBalance : user.user_balance)
            if (maxAmount !== balance) {
                setMaxAmount(balance)
            }
        }
    }, [currencies, maxAmount, selectedExchangeRate, user])

    const handleSendMoney = () => {
        if (selectedCurrency) {
            sendDepositMoneyToUser(convertStringCurrencyToCents(value), childUser.user_id, selectedCurrency, () => {
                const money = value + (selectedCurrency ? ` ${selectedCurrency}` : '')
                const message = lang.moneySuccessfulSentMessage.replace('%username%', chooseName(childUser)).replace('%money%', money)
                sendSnackBar('success', message)
                onDone()
            })
        }
    }

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    return <><Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'16px'}
    >
        <CurrencySwitcher selectedCurrency={selectedCurrency} onCurrencySelected={(currency, exchange) => {
            setSelectedCurrency(currency)
            setSelectedExchangeRate(exchange)
        }}/>
        <MoneyInput
            label={lang.depositLabel}
            value={value}
            onValueChanged={setValue}
            maxAmount={!maxAmountDisabled ? maxAmount : undefined}
            currency={selectedCurrency}
            hasError={(has) => setHasError(has)}
            disabled={!Boolean(selectedCurrency)}
        />
        <Button classes={{ contained: s.button, disabled: s.buttonDisabled }}
                disabled={hasError || value === '' || !Boolean(selectedCurrency)}
                endIcon={isDepositToUserSending ? <CircularProgress size={16}/> : <MonetizationOnOutlinedIcon/>}
                variant={'contained'}
                onClick={() => setConfirmation(true)}
        >{lang.sendBtn}</Button>
    </Box>
        {confirmation && <ConfirmationDialog close={() => setConfirmation(false)} message={lang.confirmSendingMessage.replace('%username%', chooseName(childUser))} onConfirm={() => {
            handleSendMoney()
        }}/>}
    </>
}