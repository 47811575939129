import { FC } from "react";
import { Card } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";

export const MustBeLogged: FC = () => {
    const { languagePack: { pack: { common: lang } } } = useLanguage()

    return <Card style={{ margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
        {lang.youMustBeLoggedMessage}
    </Card>
}