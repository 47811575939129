import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif'
    },
    palette: {
        mode: 'light',
        primary: {
            light: '#24eae3',
            main: '#00c0c7', // Cyan
            dark: '#0096b0',
            contrastText: '#fff'
        },
    },
});