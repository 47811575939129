import { FC } from "react";
import s from './UserInfo.module.css'
import { useLanguage } from "../../../features/localisation/useLanguage";

interface UserRegistrationProps {
    date: string
}

export const UserRegistration: FC<UserRegistrationProps> = ({date}) => {
    const { languagePack: { pack: {userInfo: lang} }} = useLanguage()

    return <div className={s.registrationContainer}>
        <div className={s.registrationLabel}>{lang.registeredLabel}:</div>
        <div className={s.registrationDate}>{date.split(' ')[0]}</div>
        <div className={s.registrationTime}>{date.split(' ')[1]}</div>
    </div>
}