import React, { FC, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import { useManageUsers } from "../../features/manage_users/useManageUsers";
import { useLanguage } from "../../features/localisation/useLanguage";

interface CurrencySwitcherProps {
    selectedCurrency?: string;
    onCurrencySelected: (currency: string, exchangeRate?: number) => void;
    required?: boolean;
}

const CurrencySwitcher: FC<CurrencySwitcherProps> = ({ selectedCurrency, onCurrencySelected, required = false }) => {
    const { currencies, isCurrenciesLoading, loadCurrencies } = useManageUsers();
    const { languagePack: {pack: {currencySwitcher: lang}}} = useLanguage()

    useEffect(() => {
        if (currencies === null && !isCurrenciesLoading) {
            loadCurrencies()
        }
    }, [currencies, isCurrenciesLoading, loadCurrencies]);

    const handleCurrencyChange = (event: SelectChangeEvent<string>) => {
        const currencyName = event.target.value as string
        const currencyObj = currencies?.find(curr => curr.currency_name === currencyName)
        onCurrencySelected(currencyName, currencyObj?.exchange_rate);
    };

    return (
        <>
            {isCurrenciesLoading ? (
                <Skeleton animation={'wave'} height={56}/>
            ) : (
                <FormControl fullWidth size={'small'} required={required}>
                    <InputLabel size={'small'} id="currency-label">{lang.currencyLabel}</InputLabel>
                <Select
                    required={required}
                    labelId="currency-label"
                    label={lang.currencyLabel}
                    value={selectedCurrency}
                    onChange={handleCurrencyChange}
                    disabled={isCurrenciesLoading || currencies?.length === 0 || currencies === null}
                >
                    {currencies && currencies.map((currency) => (
                        <MenuItem key={`currency-list-${currency.currency_name}`} value={currency.currency_name}>
                            {currency.currency_name}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
            )}
        </>
    );
};

export default CurrencySwitcher;
