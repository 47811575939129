export interface User {
    user_id: number;
    user_name: string;
    user_email?: string;
    user_phone?: string;
    registration_time?: string;
    registration_time_unix: number
    user_balance: number;
    bonus_balance?: number
    user_currency?: string;
    user_role: string;
    user_firstname?: string;
    user_lastname?: string;
    banned: boolean
}

export enum UserRole {
    PLATFORM,
    OWNER,
    SUPERADMIN,
    ADMIN,
    CASHIER,
    PLAYER
}