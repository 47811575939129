import { FC, Fragment, useEffect, useState } from "react";
import { Avatar, Box, Card, LinearProgress, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { MustBeLogged } from "../../components/commons/MustBeLogged";
import { useUser } from "../../features/user/useUser";
import { usePaymentSystems } from "../../features/payment_system/usePaymentSystems";
import PaymentsIcon from "@mui/icons-material/Payments";
import { clearPaymentSystems } from "../../features/payment_system/paymentSystemsSlice";
import { useAppDispatch } from "../../store";
import config from "../../config";
import { PaymentAccount } from "../../features/payment_system/PaymentSystemModels";
import { RequestPaymentDialog } from "./request_payment/RequestPaymentDialog";
import { useLanguage } from "../../features/localisation/useLanguage";
import { UserRole } from "../../features/user/user";
import { SecurityLogAccess } from "../security_logs/SecurityLogPage";

export const BuyBalanceAccess = [
    UserRole.SUPERADMIN,
    UserRole.ADMIN,
    UserRole.CASHIER
]

export const BuyBalancePage: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {requestPayment: lang}}} = useLanguage()
    const { token, userRole } = useUser()
    const hasAccess = userRole !== null && BuyBalanceAccess.includes(userRole)
    const { isDepositPaymentAccountsLoading, depositPaymentAccounts, getDepositAccounts } = usePaymentSystems()
    const normalScreens = useMediaQuery('(min-width:700px)');
    const [showRequestPayment, setShowRequestPayment] = useState<PaymentAccount|null>(null)

    useEffect(() => {
        return () => {
            dispatch(clearPaymentSystems())
        }
    }, [ dispatch ])

    useEffect(() => {
        if (hasAccess) {
            getDepositAccounts()
        }
    }, [getDepositAccounts, hasAccess])

    return token ? <>{hasAccess &&
        <Card style={{ margin: 10 }}>
            <Box padding={'10px'}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    padding={'10px'}
                    flexDirection={'column'}
                    rowGap={'16px'}>
                    <Typography variant={'h4'} textTransform={'uppercase'} textAlign={'center'}>
                        {lang.choosePaymentAccountTitle.split('\n').map((line, index) => <Fragment key={`split-lines-${index}`}>{line}{index === 0 && <br/>}</Fragment>)}
                    </Typography>

                    <Stack direction={'column'} rowGap={'4px'} alignItems={'center'}  width={normalScreens ? '400px' : '100%'}>
                        {isDepositPaymentAccountsLoading && <LinearProgress sx={{width: '100%'}}/>}

                        {depositPaymentAccounts?.map(depositAccount => <Paper
                            onClick={() => setShowRequestPayment(depositAccount)}
                            key={`payout-method-id-${depositAccount.id}`} sx={{ width: '100%' }}>
                            <Box padding={'10px'} sx={{ cursor: 'pointer' }}>
                                <Stack direction={'row'} alignItems={'center'} columnGap={'18px'}>
                                    <Avatar
                                        alt={`${depositAccount.method_name} payment method icon`}
                                        src={depositAccount.pic_url}
                                        // src={config.hostDomain + depositAccount.pic_url}
                                        sx={{ width: 52, height: 52 }}
                                    >
                                        <PaymentsIcon fontSize={'small'}/>
                                    </Avatar>
                                    <Typography fontSize={18} fontWeight={'500'}>
                                        {depositAccount.method_name}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Paper>)}
                    </Stack>
                </Box>
            </Box>
        </Card>}


        {showRequestPayment &&
            <RequestPaymentDialog parentPaymentAccount={showRequestPayment} close={() => setShowRequestPayment(null)}/>}

    </> : <MustBeLogged/>
}